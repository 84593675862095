/* eslint-disable import/no-named-as-default */
import React, { useMemo, useState, useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { showError } from 'utils';
import { Page, Card, IconButton, Loader } from 'components/common';
import Service from 'service';
import { useFintech } from 'components/context/fintech-context'
import isBrowser from 'components/lib/isbrowser'
import { Row, Col } from 'reactstrap';
import './client-details-page.scss';

const LineData = ({ label, value, collapsable, className }) => {
  const [expanded, setExpanded] = useState(false)


  return (
    <div className="line">
      <div className="top">
        <div className="label">{label}</div>
        <div className="value">
          {collapsable ? (
            <IconButton
              icon={expanded ? 'fa fa-chevron-down' : 'fa fa-chevron-right'}
              onClick={() => setExpanded(!expanded)}
            />
          ) : (
            value
          )}
        </div>
      </div>
      {expanded && <div className={`expandedValue ${className || ''}`}>{value}</div>}
    </div>
  )
}

const ClientDetailsPage = () => {
  const locationSearch = isBrowser ? window?.location?.search : ''
  const { selectedFintech } = useFintech()
  const [jobLoading, setJobLoading] = useState(true)
  const [currentJobDetails, setCurrentJobDetails] = useState({});
  const { t } = useTranslation()

  const [jobId, product, clientId] = useMemo(() => {
    const urlSearchParams = locationSearch ? new URLSearchParams(window.location.search) : ''
    const params = Object.fromEntries(urlSearchParams.entries())

    return [params.jobId, params.product, params.clientId]
  }, [locationSearch])

  const loadJob = useCallback(async () => {
    if (!selectedFintech?.id) {
      return
    }

    setJobLoading(true)

    try {
      let jobDetails = null;
      if (product === 'AISP') {
        const { data: jobData } = await Service.providers.getDcrJob(
          selectedFintech.id,
          jobId
        )
        jobDetails = jobData?.dcr
      } else {
        const { data: jobData } = await Service.providers.getPaymentsDcrJob(
          selectedFintech.id,
          jobId
        )

        jobDetails = jobData?.dcr
      }
      const filterCurrentDetails = jobDetails?.filter((eachdata) => eachdata.aspspId === clientId);
      filterCurrentDetails?.length ? filterCurrentDetails[0].product = product : ''
      setCurrentJobDetails(filterCurrentDetails);
    } catch (err) {
      console.log(err)
      showError(err);
    }

    setJobLoading(false)
  }, [jobId, selectedFintech, product, clientId])

  useEffect(() => {
    loadJob()
  }, [loadJob])


  return (
    <Page
      title={t('pages.adminClientManagement.clientDetails')}
      breadcrumb={[
        {
          label: t('pages.adminClientManagement.aspspClientManagement'),
          url: '/user/aspsp-client-management'
        },
        {
          label: t('pages.adminClientManagement.dcrManagement'),
          url: '/user/aspsp-client-management/dcr-management'
        },
        {
          label: t('pages.adminClientManagement.jobId') + jobId,
          url:
            '/user/aspsp-client-management/dcr-management/job?jobId=' + jobId
        },
        { label: t('pages.adminClientManagement.clientDetails') }
      ]}
      wrapperClassName="client-details-page"
    >
      <Row>
        <Col xs={12} sm={12} md={12} lg={8}>
          <Card title={t('pages.adminClientManagement.clientOverview')}>
            <LineData
              label={`${t('pages.adminClientManagement.aspsp')} ${t(
                'common.name'
              )} `}
              value={currentJobDetails?.[0]?.aspspLabel}
            />
            <LineData
              label={t('pages.adminClientManagement.aspspId')}
              value={currentJobDetails?.[0]?.aspspId}
            />
            <LineData label={t('common.product')} value={product || ''} />
            <LineData
              label={t('common.created')}
              value={new Date(currentJobDetails?.[0]?.date).toDateString?.() || ''}
            />
            {/* <LineData label="Last Modified" value="Jan 17, 2020" /> */}
          </Card>
        </Col>
        <Col xs={12} sm={12} md={12} lg={4}>
          <Card className="dcrDetailsCard" title={t('pages.adminClientManagement.dcrDetails')}>
            {t('pages.adminClientManagement.manualFintechNoData')}
          </Card>
        </Col>
      </Row>

      {currentJobDetails?.[0]?.status === 'FAILURE' && (
        <Card className="dcrErrorCard" title={t('pages.adminClientManagement.error')}>
          {currentJobDetails?.[0]?.errorDetails}
        </Card>
      )}

      {!jobLoading ? (
        <Card title={t('common.settings')} className="settingsCard">
          <LineData
            label={t('pages.adminClientManagement.registrationType')}
            value="DCR"
          />
          <LineData
            label={t('pages.adminClientManagement.mtlsPrivateKey')}
            value={currentJobDetails?.[0]?.registrationForm?.mTLSPrivateKey}
            collapsable
            className="key"
          />
          <LineData
            label={t('pages.adminClientManagement.mtlsPublicCertificate')}
            value={currentJobDetails?.[0]?.registrationForm?.mTLSCertificate}
            collapsable
            className="key"
          />
          <LineData
            label={t('pages.adminClientManagement.signingKeyId')}
            value={currentJobDetails?.[0]?.registrationForm?.keyId}
          />
          <LineData
            label={t('pages.adminClientManagement.signingPrivateKey')}
            value={currentJobDetails?.[0]?.registrationForm?.privateKey}
            collapsable
            className="key"
          />
          <LineData
            label={t('pages.adminClientManagement.softwareAssertion')}
            value={currentJobDetails?.[0]?.registrationForm?.softwareStatement}
            collapsable
            className="key"
          />
        </Card>
      ) : (
        <div className={'loader-wrapper'}>
          <Loader />
        </div>
      )}
    </Page>
  )
}

export default ClientDetailsPage;
