import React, { useCallback, useState } from 'react'

import ASPSPSelect from './aspsps'

const AppCreateRow = (props) => {
  const [values, setValues] = useState({});
  const [forms, setForms] = useState(null);

  const onASPSPChange = useCallback(({ name, value, wellKnownEndpoint, forms }) => {
    const data = { [name]: value }
    const newValues = { ...values, ...data, wellKnownEndpoint };

    setValues(newValues);
    setForms(forms);
    props.onChange(newValues);
  }, [values]);

  const onInputChange = useCallback(({ target }) => {
    const { name, value } = target
    const data = { [name]: value }
    const newValues = { ...values, ...data };

    setValues(newValues);
    props.onChange(newValues);
  }, [values]);

  return (
    <div className="form create-app-form">
      <div className="form-group select-wrap">
        <label>ASPSP</label>
        <ASPSPSelect name="aspspId" onChange={onASPSPChange} />
      </div>
      <hr />
      {forms ? (
        <>
          <form
            ref="form"
            onSubmit={props.onSubmit}
            className="form create-app-form">

            <div className="form-group">
              <label>MTLS PRIVATE KEY</label>
              <textarea
                rows="8"
                name="mtlsPrivateKey"
                value={values.mtlsPrivateKey}
                onChange={onInputChange}
                required />
            </div>
            <div className="form-group">
              <label>MTLS CERTIFICATE</label>
              <textarea
                rows="8"
                name="mtlsCertificate"
                value={values.mtlsCertificate}
                onChange={onInputChange}
                required />
            </div>
            <div className="form-group">
              <label>SIGNATURE PRIVATE KEY</label>
              <textarea
                rows="8"
                name="privateKey"
                value={values.privateKey}
                onChange={onInputChange}
                required />
            </div>
            <div className="form-group">
              <label>KEY ID (KID)</label>
              <input
                type="text"
                name="keyId"
                value={values.keyId}
                onChange={onInputChange}
                required />
            </div>
            <div className="form-group">
              <label>SOFTWARE STATEMENT</label>
              <textarea
                rows="8"
                name="softwareStatement"
                value={values.softwareStatement}
                onChange={onInputChange}
                required />
            </div>
            <div className="form-group">
              <label>REDIRECT URL</label>
              <input
                type="text"
                name="tppRedirectUrl"
                value={values.tppRedirectUrl}
                onChange={onInputChange}
                required />
            </div>
            <button type="submit" id="submitter" className="hide" />
          </form>
        </>
      ) : null}
    </div>
  );
};

export default AppCreateRow;
