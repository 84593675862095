import React from 'react';
import * as dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime';
import { calculatePercentage } from '../../../../common/helpers';
import useAspsp from 'components/data-hooks/aspsp';
import './statisticsCard.scss';
import { useTranslation } from 'react-i18next';

dayjs.extend(relativeTime)

const indexClass = (index) => {
  switch (index) {
    case 1:
      return 'center';
    case 2:
      return 'last';
    default:
      return '';
  }
}

const StatisticsCard = ({ dataSource }) => {
  const {t} = useTranslation();
  const { aspspsMap, loading: aspspsMapLoading } = useAspsp();
  const [, setIsLoading] = React.useState(false)
  const [updatedData, setUpdatedData] = React.useState([])

  React.useEffect(() => {
    setIsLoading(true)
    let tempData = [...dataSource?.concat([])?.reverse()]
    for (let i = 0; i < tempData.length; i++) {
      tempData[i].upTime = calculatePercentage(
        tempData[i]?.totalReading - tempData[i]?.totalFailure,
        tempData[i]?.totalReading
      )
      tempData[i].downTime = dayjs
        ?.default(+new Date(tempData[i]?.failureTime))
        ?.fromNow()
      tempData[i].averageResponseTime = (
        tempData[i]?.durationInMillis / tempData[i]?.totalReading
      )?.toFixed(2)
      tempData[i].name = aspspsMapLoading ? '' : aspspsMap[tempData[i].aspspId]?.name
    }

    tempData = (!tempData || tempData?.length) <= 3 ? tempData : tempData?.slice(0, 3);
    setUpdatedData(tempData);
    setIsLoading(false);
  }, [dataSource, aspspsMap, aspspsMapLoading])

  return (
    <div>
      <div className="statistics-card-wrapper">
        <div className="statistics-card-content-wrapper">
          <h4 className="statistics-card-heading">{t('pages.adminAnalytics.bestUptime')}</h4>
          <div>
            {updatedData?.map((eachItem, index) => {
              const classes = 'statistics-card-value-wrapper ' + indexClass(index);
              const pc = calculatePercentage(
                eachItem?.totalReading - eachItem?.totalFailure,
                eachItem?.totalReading
              );
              return (
                <div className={classes} key={eachItem?.aspspId}>
                  <p className="statistics-card-label text-capitalize">
                    {eachItem.name}
                  </p>
                  <p className="statistics-card-value">{pc + '%'}</p>
                </div>
              );
            })}
          </div>
        </div>
        <div className="statistics-card-content-wrapper">
          <h4 className="statistics-card-heading">{t('pages.adminAnalytics.lastDowntime')}</h4>
          <div>
            {updatedData?.map((eachItem, index) => {
              const classes = 'statistics-card-value-wrapper ' + indexClass(index);
              return (
                <div
                  className={classes}
                  key={eachItem?.aspspId}
                >
                  <p className="statistics-card-label text-capitalize">
                    {eachItem.name}
                  </p>
                  <p className="statistics-card-value">
                    {eachItem?.failureTime
                      ? dayjs
                        .default(+new Date(eachItem?.failureTime))
                        .fromNow()
                      : 'No downtime'}
                  </p>
                </div>
              );
            })}
          </div>
        </div>
        <div className="statistics-card-content-wrapper">
          <h4 className="statistics-card-heading">{t('pages.adminAnalytics.fastestAvgResponseTime')}</h4>
          {updatedData?.map((eachItem, index) => {
            const classes = 'statistics-card-value-wrapper ' + indexClass(index);
            return (
              <div
                className={classes}
                key={eachItem?.aspspId}
              >
                <p className="statistics-card-label text-capitalize">
                  {eachItem.name}
                </p>
                <p className="statistics-card-value">
                  {(
                    eachItem?.durationInMillis / eachItem?.totalReading
                  )?.toFixed(2) + 'ms'}
                </p>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  )
}

export default StatisticsCard;