/* eslint-disable max-lines */
import React, { useCallback, useState, useMemo, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next'
import { Page, Chip, WizardSteps, Paper } from 'components/common';
import { Row, Col } from 'reactstrap';
import DcrJobsTable from './dcr-jobs-table';
import './dcr-management.scss';
// eslint-disable-next-line import/no-named-as-default
import toast from 'react-hot-toast';
import Service from 'service';
import isBrowser from 'components/lib/isbrowser';
import useAspsp from 'components/data-hooks/aspsp';

import DcrInfo from './dcr-info';
import { showError } from 'utils';

const DcrManagement = () => {
  const [aspsp, setAspsp] = useState(null);
  const [client, setClient] = useState(null);
  const [clientLoading, setClientLoading] = useState(false);
  const dcrJobsTableRef = useRef();
  const dcrInfoRef = useRef();
  const { aspspsMap } = useAspsp();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation()


  const locationSearch = isBrowser ? window?.location?.search : ''
  const clientId = useMemo(() => {
    const urlSearchParams = isBrowser ? new URLSearchParams(window.location.search) : '';
    const params = Object.fromEntries(urlSearchParams.entries());

    return params.clientId;
  }, [locationSearch]);

  const loadClient = useCallback(async () => {
    if (!clientId) {
      return;
    }
    setClientLoading(true);

    try {
      const response = await Service.clients.get(clientId);

      setClient(response.data);
    } catch (err) {
      console.log(err);
      showError(err);
    }

    setClientLoading(false);
  }, [clientId]);

  useEffect(() => {
    loadClient();
  }, [loadClient]);

  const handleAspspRemove = useCallback((remove) => {
    const newList = aspsp.filter((a) => a.id !== remove.id);
    setAspsp(newList);
    dcrInfoRef.current.onAspspChange(newList);
    // onASPSPChange(newList);
  }, [aspsp]);

  const handleAspspChange = (value) => {
    setAspsp(value);
  }

  const onSubmit = useCallback(async (bulkName, body) => {
    try {
      const model = body;

      await Service.multi.bulk(bulkName, model);
      dcrInfoRef.current.clear();
      toast.success(t('common.addedSuccessfully'))
      dcrJobsTableRef.current.refreshTable();

      // const failedItems = response.items.filter((r) => r.status !== 200);
      // if (failedItems.length > 0) {
      //   failedItems.forEach((r, i) => {
      //     const payload = JSON.parse(r.payload);
      //     const aspspId = body.items[i].aspspId;
      //     const name = aspspsMap[aspspId]?.name;
      //     toast.error(`${name}: ${payload.message}`);
      //   });
      // } else {
      //   dcrInfoRef.current.clear();
      //   toast.success(t('common.addedSuccessfully'))
      //   dcrJobsTableRef.current.refreshTable();
      // }

    } catch (err) {
      console.log(err);
      showError(err);
      console.error('* app client create error:', err);
    }

    setLoading(false);
  }, []);

  return (
    <Page
      title={t('pages.adminClientManagement.dcrManagement')}
      breadcrumb={[
        {
          label: t('pages.adminClientManagement.aspspClientManagement'),
          url: '/user/aspsp-client-management',
        },
        { label: t('pages.adminClientManagement.dcrManagement') },
      ]}
      wrapperClassName="dcr-management"
      loading={clientLoading}
    >
      <Row>
        <Col xs={12} sm={12} md={9} lg={10}>
          <Paper className="addCard">
            <div className="title">{`${t('common.add')} ${t(
              'pages.adminClientManagement.aspsp'
            )}`}</div>
            <DcrInfo
                onNext={onSubmit}
                clientId={clientId}
                aspsp={aspsp}
                handleAspspChange={handleAspspChange}
                dcrInfoRef={dcrInfoRef}
                client={client}
                loading={loading}
                setLoading={setLoading}
              />
          </Paper>
        </Col>
        <Col xs={12} sm={12} md={3} lg={2}>
          <div className="rightPane">
            <div className="title">
              {t('pages.adminClientManagement.selectedAspsp')}
            </div>
            {aspsp?.length > 0 ? (
              <div className="selectedAspsps">
                {aspsp.map((a) => (
                  <Chip
                    key={a.id || a.name}
                    label={a.name}
                    className="selectedChip"
                    onClose={() => handleAspspRemove(a)}
                  />
                ))}
              </div>
            ) : (
              <div className="count">
                {t('pages.adminClientManagement.noAspspSelected')}
              </div>
            )}
          </div>
        </Col>
      </Row>
      <div className="dcr-jobs-title">
        {t('pages.adminClientManagement.dcrJobs')}
      </div>
      <DcrJobsTable product={client?.product} tableRef={dcrJobsTableRef} />
    </Page>
  )
}

export default DcrManagement;