/* eslint-disable no-useless-escape */
import config from 'components/config';

import SwaggerBase from './base';

import AuthHeadersPlugin from './auth-headers';
import AuthFieldsPlugin from './auth-fields';
import Oauth2AuthorizePlugin from './oauth2';

const {oauth2RedirectUrl} = config;

export default class SwaggerUIObie extends SwaggerBase {

	getParams () {
		return {
			dom_id: '#swagger',
			url: this.props.src,
			oauth2RedirectUrl,
			requestInterceptor: this.interceptor,
			plugins: [
				AuthHeadersPlugin,
				AuthFieldsPlugin,
				Oauth2AuthorizePlugin
			]
		};
	}
}