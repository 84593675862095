import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next'
import { Table, Chip, LinkButton } from 'components/common';
import isBrowser from 'components/lib/isbrowser';
import './clients-table.scss';

const statusVariant = (status) => status == 'SUCCESS'? 'success' : 'error';
const statusLabel = (status) => status == 'SUCCESS'? 'Success' : 'Failed';

const ClientTable = ({ jobId, data }) => {
     const urlSearchParams = isBrowser
       ? new URLSearchParams(window.location.search)
       : ''
     const params = Object.fromEntries(urlSearchParams.entries())
     const { t } = useTranslation()


  const columns = useMemo(
    () => [
      {
        header: t('common.date'),
        render: ({ date }) => new Date(date).toDateString?.()
      },
      {
        header: `${t('pages.adminClientManagement.aspsp')} ${t('common.name')}`,
        field: 'aspspLabel'
      },
      {
        header: t('pages.apps.clientId'),
        render: ({ registrationForm }) => registrationForm?.clientId
      },
      {
        header: t('pages.adminClientManagement.registrationStatus'),
        render: ({ status }) => (
          <Chip variant={statusVariant(status)} label={statusLabel(status)} />
        )
      },
      {
        header: '',
        render: (rowData) => (
          <div className="table-buttons">
            <LinkButton
              size="small"
              variant="secondary"
              className="edit"
              to={
                '/user/aspsp-client-management/dcr-management/job/client?jobId=' +
                jobId +
                '&clientId=' +
                rowData?.aspspId +
                '&product=' +
                params?.product
              }
            >
              {t('pages.adminClientManagement.clientDetails')}
            </LinkButton>
          </div>
        )
      }
    ],
    []
  )

  return (
    <div className="clients-table">
      <Table
        columns={columns}
        data={data}
        noDataMessage={t('pages.adminClientManagement.noDataMessageClient')}
      />
    </div>
  )
}

export default ClientTable;