import config from 'components/config';
import buildFormParams from 'components/lib/formparams';

import updateParams from './params';

const { apiAccessAuth } = config;

const revokeAccessToken = (
  system,
  specName
) => {
  const auth = system
    .auth()
    .toJS()
    .authorized[specName];

  const {
    token,
    clientId,
    clientSecret
  } = auth;

  const form = {
    client_id: clientId,
    client_secret: clientSecret,
    refresh_token: token.refresh_token
  };

  return fetch(`${apiAccessAuth}/protocol/openid-connect/logout`, {
    method: 'POST',
    headers: {
      'content-type': 'application/x-www-form-urlencoded'
    },
    body: buildFormParams(form)
  });
}


export default (oriAction, system) => async (payload) => {

  updateParams(system, payload[0], null);

  return revokeAccessToken(system, payload[0])
    .then(() => oriAction(payload));
}