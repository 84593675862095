import React from 'react';
import { useTranslation } from 'react-i18next'
import {
  Button,
  Modal,
  ModalHeader,
  ModalFooter,
  ModalBody
} from 'reactstrap';

export default ({
  footer,
  toggle,
  title,
  children,
  ...props
}) => {
  const { t } = useTranslation()
  return (
  <Modal toggle={toggle} {...props}>

    <ModalHeader
      toggle={toggle}>
      {title}
    </ModalHeader>

    <ModalBody>
      {children}
    </ModalBody>

    {typeof footer === 'undefined' ? (
      <ModalFooter>
        <Button
          className="special"
          onClick={toggle}>
          {t('common.cancel')}
        </Button>
      </ModalFooter>
    ) : footer || null}
  </Modal>
  )
}