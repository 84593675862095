/* eslint-disable import/no-named-as-default */
/* eslint-disable no-undefined */
/* eslint-disable max-statements-per-line */
import React, { useState, useMemo, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next'
import { Table, Button, Chip, Modal } from 'components/common';
import './fintech-table.scss';
import { showError } from 'utils';
import FintechFormModal from '../fintech-form-modal';
import Service from 'service';
import { useFintech } from 'components/context/fintech-context';
import useIsMounted from 'components/hooks/use-is-mounted';
import { dateFormatter } from 'components/common/helpers'

const FintechTable = ({ filter, page, pageSize, setPage, setPageSize }) => {
  const isMounted = useIsMounted();
  const [updatingFintech, setUpdatingFintech] = useState(null);
  const [removeFintech, setRemoveFintech] = useState(null);
  const [data, setData] = useState([]);
  const [removing, setRemoving] = useState(false);
  const [loading, setLoading] = useState(false);
  const [totalPage, setTotalPage] = useState(0);
  const { reloadFintechList } = useFintech();
  const { t } = useTranslation();
  const [sortDirection, setSortDirection] = useState('DESC');

  const columns = useMemo(
    () => [
      { header: t('common.name'), render: (rowData) => <span className="data">{rowData.name}</span> },
      // { header: t('common.website'), render: (rowData) => <span className="data">{rowData.website}</span> },
      { header: t('pages.fintechManagement.organizationId'), render: (rowData) => <span className="data">{rowData.id}</span> },
      // { header: t('pages.fintechManagement.adminEmail'), render: (rowData) => <span className="data">{rowData.adminEmail}</span> },
      {
        header: t('pages.fintechManagement.createdAt'),
        render: (rowData) => dateFormatter(rowData.createdAt)
      },
      {
        header: t('common.status'),
        render: ({ status }) => (
          <>
            <Chip
              className="data"
              variant={
                status?.toLowerCase?.() === 'active' ? 'success' : 'error'
              }
              label={status?.toLowerCase?.() === 'active' ? 'Active' : 'Inactive'}
            />
          </>
        )
      },
      {
        header: '',
        render: (rowData) => (
          <div className="table-buttons">
            <Button
              size="small"
              variant="secondary"
              className="remove"
              onClick={() => setRemoveFintech(rowData)}
            >
              {t('common.remove')}
            </Button>
            <Button
              size="small"
              variant="secondary"
              className="edit"
              onClick={() => {
                setUpdatingFintech(rowData)
                // setCreateModalOpen(true);
              }}
            >
              {t('common.edit')}
            </Button>
          </div>
        )
      }
    ],
    [sortDirection]
  )

  const loadFintechs = useCallback(async () => {
    setLoading(true);
    const query = [
      `sortDirection=${sortDirection}`
    ];
    filter.searchQuery && query.push(`text=${filter?.searchQuery}`);
    filter.status?.label && query.push(`status=${filter?.status?.value ? 'ACTIVE' : 'INACTIVE'}`)
    const queryText = `?${query.join('&')}`;

    try {
      const { data: fintechs } = await Service.tpps.list(
        queryText,
        page,
        pageSize
      )
      if (isMounted.current) {
        setData(fintechs?.items)
        setTotalPage(Math.trunc(fintechs.total / pageSize) + (fintechs.total % pageSize ? 1 : 0));
      }
    } catch (err) {
      console.log(err);
      showError(err);
    }

    isMounted.current && setLoading(false)
  }, [page, pageSize, filter, sortDirection])

  const onRemoveFintech = useCallback(async () => {
    setRemoving(true)

    try {
      await Service.tpps.delete(removeFintech.id)
      setRemoveFintech(null);
      loadFintechs();
      reloadFintechList();
    } catch (err) {
      console.log(err);
      showError(err);
    }

    setRemoving(false)
  }, [removeFintech, reloadFintechList, loadFintechs])

  useEffect(() => {
    loadFintechs()
  }, [loadFintechs])

  return (
    <div className="users-table">
      <Table
        columns={columns}
        data={data}
        loading={loading}
        noDataMessage={t('pages.fintechManagement.noFintechMessage')}
        pagination={{
          page,
          onChange: setPage,
          total: totalPage,
          size: pageSize,
          onSizeChange: setPageSize
        }}
      />
      <FintechFormModal
        key={updatingFintech?.id || 'empty'}
        initialData={updatingFintech}
        isOpen={Boolean(updatingFintech)}
        onClose={(updated) => {
          setUpdatingFintech(null)
          if (updated) {
            loadFintechs();
          }
        }}
      />
      <Modal
        isOpen={Boolean(removeFintech)}
        title={t('common.pleaseConfirm')}
        onCancel={() => setRemoveFintech(null)}
        onClose={() => setRemoveFintech(null)}
        onConfirm={onRemoveFintech}
        confirmText={t('common.delete')}
        confirmVariant="error"
        loading={removing}
      >
        <div className="deleteModal">
          <div className="subtitle">
            {t('pages.fintechManagement.deleteConfirmationMessage')}
          </div>
          <div className="line">
            <div className="label">{t('common.name')}</div>
            <div className="value">{removeFintech?.name}</div>
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default FintechTable;