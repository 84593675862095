/* eslint-disable no-useless-escape */
import React, { Component } from 'react';
import 'swagger-ui/dist/swagger-ui.css';

import config from 'components/config';
import debounce from 'lodash.debounce';

import {
  authorization
} from 'components/lib/request';

import updateTPPIds from './tpp-ids';

const { oauth2RedirectUrl } = config;

export default class SwaggerUIBase extends Component {

  swaggerReady;
  swaggerResolver;
  getParams;
  oauth2RedirectUrl;
  setupOAuth;
  interceptor;
  swagger;
  ui;

  constructor(props) {
    super(props);

    this.oauth2RedirectUrl = oauth2RedirectUrl;
    this.interceptor = this.interceptor.bind(this);
    this.setupOAuth = debounce(this.setupOAuth.bind(this), 500);
    this.swaggerReady = new Promise((resolve) =>
      this.swaggerResolver = resolve
    )
  }

  componentDidMount() {
    import('swagger-ui')
      .then(({ default: swagger }) =>
        this.swaggerResolver(
          this.swagger = swagger
        )
      )
  }

  shouldComponentUpdate({ src }) {
    return !!src && (this.props.src !== src);
  }

  componentDidUpdate() {
    this.swaggerReady
      .then(() => {
        this.setupUI();
        this.setupOAuth();
      })
  }

  setupUI() {
    if (!this.props.src) {
      return;
    }

    console.log('* swagger-ui setup specs:', this.props.src);

    this.ui = this.swagger(
      this.getParams()
    );
  }

  setupOAuth() {
    const {
      fintechId,
      clientId
    } = this.props;

    if (
      !this.ui ||
      !fintechId ||
      !clientId
    ) {
      return;
    }

    this.ui.initOAuth({ clientId });

    setTimeout(() =>
      updateTPPIds(
        this.ui.getSystem(),
        fintechId
      ), 1000
    );

    console.log('* swagger-ui setup oauth:', clientId);
  }

  interceptor(req) {
    if (typeof req.headers.Authorization !== 'undefined') {
      delete req.headers.authorization;
    }

    if (req.loadSpec) {
      req.headers = {
        ...req.headers,
        ...authorization()
      };
    }

    delete req.headers['X-Requested-With'];
    return req;
  }

  render() {
    return (
      <div id="swagger">
        <div className="loader" />
      </div>
    );
  }
}