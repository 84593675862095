import React, { useCallback, useEffect, useState } from 'react';

import { getASPSP } from 'components/content/util';
import useIsMounted from 'components/hooks/use-is-mounted';

const Loader = ({ error }) =>
  error ? (
    <i className="fa fa-exclamation-triangle" />
  ) : (
    <i className="fa fa-spin fa-spinner" />
  )
const Info = ({ data }) => <div>{data.name}</div>;

const ApplicationsList = ({ aspspId }) => {
  const isMounted = useIsMounted();
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);

  const update = useCallback(() => {
    getASPSP(aspspId)
      .then((response) => isMounted.current && setData(response.data))
      .catch((e) => {
        console.error('* load data error', e);
        isMounted.current && setError('data load error');
      });
  }, [aspspId]);

  useEffect(()=> {
    update();
  }, [update]);

  return (
    <div className="aspsp-info">
      {data ? (
        <Info data={data} />
      ) : (
        <Loader error={error} />
      )}
    </div>
  );
}

export default ApplicationsList;