import React, { useCallback, useState } from 'react';
import JsonForm from 'react-jsonschema-form';

import ASPSPSelect from './aspsps';

const AppCreateRow = (props) => {
  const [values, setValues] = useState(...props.data);
  const [formData, setFormData] = useState(...props.data.settigns);
  const [forms, setForms] = useState(null);

  const onASPSPChange = useCallback(({ name, value, forms }) => {
    const data = { [name]: value };
    const newValues = { ...values, ...data };

    setValues(newValues);
    setForms(forms);
    props.onChange(newValues);
  }, [values, props.onChange]);

  const onFormChange = useCallback(({ formData }) => {
    setFormData(formData)

    props.onChange({ settings: formData });
  }, [props.onChange]);


  return (
    <div className="form create-app-form">

      <div className="form-group select-wrap">
        <label>ASPSP</label>
        <ASPSPSelect
          name="aspspId"
          value={values.aspspId}
          onChange={onASPSPChange} />
      </div>

      <hr />

      {forms ? (
        <JsonForm
          formData={formData}
          schema={forms.register.jsonSchema}
          uiSchema={forms.register.uiSchema}
          onChange={onFormChange}
          onSubmit={props.onSubmit}>
          <button
            type="submit"
            id="submitter"
            className="hide" />
        </JsonForm>
      ) : null}
    </div>
  );
};

export default AppCreateRow;