import React from 'react';
import ErrorMessage from 'components/lib/error';

export default ({ error }) =>
  error ? (
    <tr className="loader-row">
      <td colSpan="4">
        <p className="error">
          <ErrorMessage error={error} />
        </p>
      </td>
    </tr>
  ) : (
    <tr className="loader-row">
      <td colSpan="4">
        <div className="loader" />
      </td>
    </tr>
  );