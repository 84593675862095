import React, { useMemo } from 'react';
import * as dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import { Table } from 'components/common';
import { calculatePercentage } from 'components/common/helpers'
import useAspsp from 'components/data-hooks/aspsp';
import { AspspView } from 'components/business-components';
import { useTranslation } from 'react-i18next';

import './statistics-list.scss';
dayjs.extend(relativeTime)

const StatisticsList = ({ dataSource, handleRowClick }) => {
  const {t} = useTranslation();
  const { aspspsMap, loading: aspspsMapLoading } = useAspsp();

  const columns = useMemo(() => [
    {
      header: t('pages.adminAnalytics.banks'),
      render: (rowData) => aspspsMapLoading ? (t('common.loading') + '...') : <AspspView aspsp={aspspsMap[rowData.aspspId]} />
    },
    {
      header: t('pages.adminAnalytics.avgUptime'),
      field: 'durationInMillis',
      render: (eachValue) => {
        return `${calculatePercentage(
          eachValue?.totalReading - eachValue?.totalFailure,
          eachValue?.totalReading
        )}%`
      }
    },
    {
      header: t('pages.adminAnalytics.lastFailure'),
      field: 'avgDowntime',
      render: (eachValue) => {
        return eachValue?.failureTime
          ? dayjs?.default(+new Date(eachValue?.failureTime))?.fromNow()
          : t('pages.adminAnalytics.noDowntime')
      }
    },
    {
      header: t('pages.adminAnalytics.avgResponseTime'),
      field: 'startDateTime',
      render: (eachValue) => {
        return `${(
          eachValue?.durationInMillis / eachValue?.totalReading
        )?.toFixed(2)}
                  ms`
      }
    }
  ], [aspspsMap, aspspsMapLoading, t]);

  return (
    <div className="statistics-list-wrapper">
      <Table className="row-data" columns={columns} data={dataSource} handleRowClick={handleRowClick} />
    </div>
  )
}

export default StatisticsList
