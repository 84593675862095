import React, { useState, useMemo, useCallback, useEffect } from 'react';
import { getUrlParams } from 'components/content/util';
import { onSpecsClick, getProductSpecs } from './util';
import Service from 'service';
import SwaggerUI from './swagger';
import useIsMounted from 'components/hooks/use-is-mounted';

const AppInfo = ({
  data: {
    name,
    product
  }
}) => (
  <ul className="apps-breadcrumbs">
    <li>{product}</li>
    <li>{name}</li>
  </ul>
);

const AppPage = () => {
  const isMounted = useIsMounted();
  const params = useMemo(() => getUrlParams(), []);
  const [app, setApp] = useState(null);
  const [error, setError] = useState(null);
  const [fintechId, setFintechId] = useState(null);
  const [clientId, setClientId] = useState(null);
  const [src, setSrc] = useState(null);

  const getSwaggerSrc = useCallback((product) => {
    const { serviceId } = product || {};

    console.log('* swagger-ui product service id:', serviceId)

    return getProductSpecs(serviceId);
  }, []);

  const update = useCallback(() => {
    if (!params) {
      return;
    }

    const appId = params.get('clientId');

    if (!appId) {
      return;
    }

    Service.apps.get(appId)
      .then(({ data }) => {
        isMounted.current && setApp(data);

        const { fintechId, product, clientId } = app;

        if (product) {
          setFintechId(fintechId);
          setClientId(clientId);
          setSrc(getSwaggerSrc(product));
        }
      })
      .catch((err) => isMounted.current && setError(err));
  }, [params, getSwaggerSrc]);

  useEffect(() => {
    update();
  }, [update]);

  return (
    <div
      className="apps-page"
      onClick={onSpecsClick}>

      {app ? (
        <section className="section">
          <div className="inner content">
            <AppInfo data={app} />
          </div>
        </section>
      ) : (
        <div className="loader-wrap">
          <div className="loader" />
        </div>
      )}

      {app ? (
        <section className="section specs">
          <SwaggerUI
            src={src}
            fintechId={fintechId}
            clientId={clientId} />
        </section>
      ) : null}
    </div>
  );
};

export default AppPage;