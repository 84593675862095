/* eslint-disable no-warning-comments */
/* eslint-disable import/no-named-as-default */
/* eslint-disable no-undefined */
/* eslint-disable max-statements-per-line */
import React, { useState, useMemo, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next'
import { Table, Button, Chip, Modal } from 'components/common';
import './users-table.scss';
import { showError } from 'utils';
import FormModal from '../formModal';
import Service from 'service';
import { useFintech } from 'components/context/fintech-context';
import { useSession } from 'components/context/session-context';
import { RolesMap } from 'utils/constants';
import useIsMounted from 'components/hooks/use-is-mounted';

const UsersTable = ({ filterState, tableRef, page, pageSize, setPage, setPageSize }) => {
  const isMounted = useIsMounted();
  const { selectedFintech } = useFintech();
  const { user } = useSession();
  const [updatingUser, setUpdatingUser] = useState(null);
  const [removeUser, setRemoveUser] = useState(null);
  
  const [totalPage, setTotalPage] = useState(0)
  const [displayData, setDisplayData] = useState([]);
  const [removing, setRemoving] = useState(false);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation()

  const columns = useMemo(
    () => [
      { header: t('pages.contact.email'),
        render: (rowData) => <span className="data">{rowData.userName}</span>
      },
      {
        header: t('common.name'),
        render: (rowData) => <span className="data">{rowData.name?.givenName}</span>
      },
      { header: t('pages.userManagement.userPermissions'), render: ({ userType }) => <span className="data">{RolesMap[userType]}</span> },
      {
        header: t('common.status'),
        render: ({ active }) => (
          <Chip
            className="data"
            variant={active ? 'success' : 'error'}
            label={active ? t('common.tableStatus.active') : t('common.tableStatus.inActive')}
          />
        )
      },
      {
        header: '',
        render: (rowData) => (
          <div className="table-buttons">
            {rowData.isFintechOwner
              ? <i className="fa fa-star starIcon" />
              : user.userName !== rowData.userName && (
                <Button
                  size="small"
                  variant="secondary"
                  className="remove"
                  onClick={() => setRemoveUser(rowData)}
                  disabled={user.userName === rowData.userName}
                >
                  {t('common.remove')}
                </Button>
              )
            }
            <Button
              size="small"
              variant="secondary"
              className="edit"
              onClick={() => {
                setUpdatingUser(rowData)
              }}
            >
              {t('common.edit')}
            </Button>
          </div>
        )
      }
    ],
    [user]
  );

  const loadUsers = useCallback(async () => {
    if (!selectedFintech?.id) {
      return;
    }

    setLoading(true)

    try {
      const params = {
        fintechId: selectedFintech?.id,
        page,
        size: pageSize
      };

      filterState?.searchQuery && (params.search = filterState.searchQuery);
      filterState?.status && (params.status = filterState?.status?.value ? 'ACTIVE' : 'INACTIVE');
      filterState?.role?.value && (params.role = filterState?.role?.value);

     const response = await Service.users.list(params, user?.fintechId)
      if (isMounted.current) {
        setTotalPage(Math.trunc(response.total / pageSize) + (response.total % pageSize ? 1 : 0));
        setDisplayData(response.data);
      }
    } catch (err) {
      console.log(err)
      showError(err);
    }

    isMounted.current && setLoading(false)
  }, [selectedFintech?.id, page, pageSize, filterState]);

  const onRemoveUser = useCallback(async () => {
    setRemoving(true)

    try {
      await Service.users.delete(removeUser.id)
      setRemoveUser(null)
      loadUsers()
    } catch (err) {
      console.log(err)
      showError(err);
    }

    setRemoving(false)
  }, [removeUser])

  useEffect(() => {
    loadUsers();

    tableRef.current = {
      refreshUsers: loadUsers
    };
  }, [loadUsers])

  return (
    <div className="users-table">
      <Table
        columns={columns}
        data={displayData}
        loading={loading}
        noDataMessage={t('pages.userManagement.noAppDataMessage')}
        pagination={{
          page,
          onChange: setPage,
          total: totalPage,
          size: pageSize,
          onSizeChange: setPageSize
        }}
      />
      <FormModal
        key={updatingUser?.id || 'empty'}
        initialData={updatingUser}
        isOpen={Boolean(updatingUser)}
        onClose={(updated) => {
          setUpdatingUser(null)
          if (updated) {
            loadUsers()
          }
        }}
      />
      <Modal
        isOpen={Boolean(removeUser)}
        title={t('common.pleaseConfirm')}
        onCancel={() => setRemoveUser(null)}
        onClose={() => setRemoveUser(null)}
        onConfirm={onRemoveUser}
        confirmText={t('common.delete')}
        confirmVariant="error"
        loading={removing}
      >
        <div className="deleteModal">
          <div className="subtitle">
            {t('common.confirmationMessage.delete')}{' '}
            {t('pages.userManagement.followingUser')}
          </div>
          <div className="line divider">
            <div className="label">{t('common.name')}</div>
            <div className="value">{removeUser?.name?.givenName}</div>
          </div>
          <div className="line divider">
            <div className="label">{t('common.email')}</div>
            <div className="value">{removeUser?.userName}</div>
          </div>
          <div className="line">
            <div className="label">
              {t('pages.userManagement.userPermissions')}
            </div>
            <div className="value">{RolesMap[removeUser?.userType]}</div>
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default UsersTable;