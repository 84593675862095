import logout from './logout';
import refreshToken from './refresh';
import authorizeOauth2 from './authorize';
// import authorizeAccessCodeWithFormParams from './accesscode';


export default () => ({
	statePlugins: {
		auth: {
			actions: {
				refreshToken
			},
			wrapActions: {
				// authorizeAccessCodeWithFormParams,
				authorizeOauth2,
				logout
			}
		}
	}
});