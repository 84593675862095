import React, { useEffect, useMemo } from 'react';

import { Router } from '@reach/router';
import { graphql, navigate } from 'gatsby';
import Layout from 'components/layout/private';
import config from 'components/config';
import { useTranslation } from 'react-i18next'
import isBrowser from 'components/lib/isbrowser';

import Profile from 'components/content/profile';
import Apps from 'components/content/apps';
import Test from 'components/content/test';
import { useSession } from 'components/context/session-context';
import Navigation from 'components/navigation';

import Products from 'components/content/user-pages/products';
import ProductDetail from 'components/content/user-pages/product-detail';
import Documentation from 'components/content/user-pages/documentation';
import UserAnalytics from 'components/content/user-pages/user-analytics';
// import Customers from 'components/content/user-pages/customers';
// import CustomerDetails from 'components/content/user-pages/customer-details';
// import Lending from 'components/content/user-pages/lending';
// import LendingDetails from 'components/content/user-pages/lending-details';
// import ApiKeys from 'components/content/user-pages/api-keys';

import PaymentHistory from 'components/content/user-pages/payment-history';
import PaymentHistoryDetails from 'components/content/user-pages/payment-history/selected-payment-details';
import PaymentStatus from 'components/content/user-pages/payment-history/selected-payment-status';
import SelectedBankDetail from 'components/content/user-pages/payment-history/selected-bank-detail';
import FintechManagement from 'components/content/user-pages/fintech-management';
import UserManagement from 'components/content/user-pages/user-management';
import AdminAnalytics from 'components/content/user-pages/admin-analytics';
import AdminAnalyticsDetail from 'components/content/user-pages/admin-analytics-detail';
import AspspClientManagement from 'components/content/user-pages/aspsp-client-management';
import DcrManagement from 'components/content/user-pages/aspsp-client-management/dcr-management';
import JobPage from 'components/content/user-pages/aspsp-client-management/dcr-management/job-page';
import ClientDetailsPage from 'components/content/user-pages/aspsp-client-management/dcr-management/job-page/client-details-page';

const getRedirect = (message = '') => {
	if (message.match(/email/)) {
		return config.paths.verification;
	}

	if (message.match(/registration/)) {
		return config.paths.register;
	}

	return config.paths.login;
}



const Loader = () => <div className="preload"><div className="loader" /></div>

const PrivateTemplate = ({ location, data }) => {
  const { user, userLoading, errorMessage, loggedIn } = useSession();
  const { t } = useTranslation()
  const userType = user?.userType;
  const dashboardNavigationData = {
    'fintech-developer': [
      {
        label: t('pages.navigationLabels.products'),
        url: '/user/products',
        component: Products
      },
      {
        label: t('pages.navigationLabels.productDetail'),
        url: '/user/product/detail',
        component: ProductDetail,
        hideOnNavigation: true
      },
      {
        label: t('pages.navigationLabels.documentation'),
        url: '/user/documentation/accounts-api',
        component: Documentation
      },
      {
        label: t('pages.navigationLabels.documentation'),
        url: '/user/documentation/*',
        component: Documentation,
        hideOnNavigation: true
      },
      {
        label: t('pages.navigationLabels.apiAnalytics'),
        url: '/user/analytics',
        component: UserAnalytics
      }
      // {
      //   label: t('pages.navigationLabels.apiKeys'),
      //   url: '/user/api-keys',
      //   component: ApiKeys
      // }
    ],
    'fintech-admin': [
      {
        label: t('pages.navigationLabels.userManagement'),
        url: '/user/user-management',
        component: UserManagement
      },
      {
        label: t('pages.navigationLabels.aspspClientManagement'),
        url: '/user/aspsp-client-management',
        component: AspspClientManagement
      },
      {
        label: t('pages.navigationLabels.dcrManagement'),
        url: '/user/aspsp-client-management/dcr-management',
        component: DcrManagement,
        hideOnNavigation: true
      },
      {
        label: t('pages.navigationLabels.dcrManagement'),
        url: '/user/aspsp-client-management/dcr-management/job',
        component: JobPage,
        hideOnNavigation: true
      },
      {
        label: t('pages.navigationLabels.dcrManagement'),
        url: '/user/aspsp-client-management/dcr-management/job/client',
        component: ClientDetailsPage,
        hideOnNavigation: true
      },
      {
        label: t('pages.navigationLabels.apiAnalytics'),
        url: '/user/analytics',
        component: AdminAnalytics
      },
      {
        label: t('pages.navigationLabels.apiAnalyticsDetail'),
        url: '/user/analytics/detail',
        component: AdminAnalyticsDetail,
        hideOnNavigation: true
      },
      // {
      //   label: t('pages.navigationLabels.customers'),
      //   url: '/user/customers',
      //   component: Customers
      // },
      // {
      //   label: t('pages.navigationLabels.customers'),
      //   url: '/user/customers/details',
      //   component: CustomerDetails,
      //   hideOnNavigation: true
      // },
      // {
      //   label: t('pages.navigationLabels.lending'),
      //   url: '/user/lending',
      //   component: Lending
      // },
      {
        label: t('pages.navigationLabels.paymentHistory'),
        url: '/user/payment-history',
        component: PaymentHistory
      },
      {
        label: t('pages.navigationLabels.paymentHistory'),
        url: '/user/payment-history/details',
        component: PaymentHistoryDetails,
        hideOnNavigation: true
      },
      {
        label: t('pages.navigationLabels.paymentHistory'),
        url: '/user/payment-history/payment-status',
        component: PaymentStatus,
        hideOnNavigation: true
      },
      {
        label: t('pages.navigationLabels.paymentHistory'),
        url: '/user/payment-history/bank-details',
        component: SelectedBankDetail,
        hideOnNavigation: true
      }
      // {
      //   label: t('pages.navigationLabels.customers'),
      //   url: '/user/lending/details',
      //   component: LendingDetails,
      //   hideOnNavigation: true
      // }
    ],
    'super-admin': [
      {
        label: t('pages.navigationLabels.fintechManagement'),
        url: '/user/fintech-management',
        component: FintechManagement
      },
      {
        label: t('pages.navigationLabels.userManagement'),
        url: '/user/user-management',
        component: UserManagement
      },
      {
        label: t('pages.navigationLabels.dcrManagement'),
        url: '/user/aspsp-client-management/dcr-management',
        component: DcrManagement,
        hideOnNavigation: true
      },
      {
        label: t('pages.navigationLabels.dcrManagement'),
        url: '/user/aspsp-client-management/dcr-management/job',
        component: JobPage,
        hideOnNavigation: true
      },
      {
        label: t('pages.navigationLabels.dcrManagement'),
        url: '/user/aspsp-client-management/dcr-management/job/client',
        component: ClientDetailsPage,
        hideOnNavigation: true
      },
      {
        label: t('pages.navigationLabels.aspspClientManagement'),
        url: '/user/aspsp-client-management',
        component: AspspClientManagement
      },
      {
        label: t('pages.navigationLabels.apiAnalytics'),
        url: '/user/analytics',
        component: AdminAnalytics
      },
      {
        label: t('pages.navigationLabels.apiAnalyticsDetail'),
        url: '/user/analytics/detail',
        component: AdminAnalyticsDetail,
        hideOnNavigation: true
      },
      {
        label: t('pages.navigationLabels.paymentHistory'),
        url: '/user/payment-history',
        component: PaymentHistory
      },
      {
        label: t('pages.navigationLabels.paymentHistory'),
        url: '/user/payment-history/details',
        component: PaymentHistoryDetails,
        hideOnNavigation: true
      },
      {
        label: t('pages.navigationLabels.paymentHistory'),
        url: '/user/payment-history/payment-status',
        component: PaymentStatus,
        hideOnNavigation: true
      }
    ]
  }

  const RouteComponent = (props) => {
    const { component: Component, location, user } = props
    return (
      <>
        <Navigation
          navigationData={dashboardNavigationData[user?.userType]}
          location={location}
          user={user}
        />
        <Component {...props} user={user} />
      </>
    )
  }

  const routes = useMemo(() => {
    const paths = [
      { path: '/user/profile', component: Profile },
      { path: '/user/dashboard', component: Loader },
      { path: '/user/test', component: Test },
      { path: '/user/apps', component: Apps }
    ];

    if (user) {
      dashboardNavigationData[userType]
        .filter((p) => p.url.startsWith('/user/'))
        .forEach((p) => {
          paths.push({ path: p.url, component: p.component })
        })
    }

    return paths;
  }, [user, userType]);

  useEffect(() => {
    if (!loggedIn) {
      navigate(getRedirect(errorMessage))
    } else if (user) {
      if (location.pathname === '/user/dashboard') {
        navigate(dashboardNavigationData[userType][0].url);
      }
    }
  }, [user, userLoading, errorMessage, location.pathname, loggedIn]);

  if (!isBrowser) {
    return null;
  }

  if (!user && userLoading) {
    return <Loader />;
  }

  return (
    <Layout className="private">
      <Router>
        {routes.map((route) => <RouteComponent key={route.path} {...route} user={user} location={location} data={data} />)}
      </Router>
    </Layout>
  );
}

export default PrivateTemplate;

export const pageQuery = graphql`
	query PrivatePageQuery ($path: String!) {
		...MarkdownPages
	}
`