import React, { useCallback, useState } from 'react';
import { Link } from 'gatsby';

import { useTranslation } from 'react-i18next';

const AppItem = ({data}) => {
  const { t } = useTranslation();
  const [editModal, setEditModal] = useState(false);

  const toggleEdit = useCallback(() => setEditModal(!editModal), [editModal]);

  const onItemEdit = useCallback((e) => {
    toggleEdit();
    e.preventDefault();
  }, [toggleEdit]);

  return (
    <div className="item-actions">
      <div className="actions-list">
        <a
          href="#"
          className="icon edit-item mr-3"
          onClick={onItemEdit}>
          <i className="fa fa-pencil" />
        </a>
        <Link
          to={`/user/test?clientId=${data.clientId}`}
          className="icon special test-item">
          {t('pages.apps.test')}
        </Link>
      </div>

    </div>
  );
}

export default AppItem;