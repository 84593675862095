import React, { useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next'
import { Input, Page, Button, Select, IconButton } from 'components/common';
import { Row, Col } from 'reactstrap';
import './fintech-management.scss';
import FintechTable from './fintech-table';
import FintechFormModal from './fintech-form-modal';
import debounce from 'lodash.debounce';


const FintechManagement = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [filterState, setFilterState] = useState({ searchQuery: '', status: '' });
  const [searchQueryInner, setSearchQueryInner] = useState('');
  const { t } = useTranslation()
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);

  const statusOptions = [
    { label: t('common.tableStatus.active'), value: true },
    { label: t('common.tableStatus.inActive'), value: false }
  ]

  const handleStatusChange = (value) => {
    setFilterState({
      ...filterState,
      status: value
    })
  };

  const handleFilterClear = () => {
    setFilterState({
      searchQuery: '',
      status: ''
    })
    setQuery('');
    setSearchQueryInner('');
  }

  const handleQueryChange = useCallback(debounce((e) => {
    setFilterState({
      ...filterState,
      searchQuery: e.target.value
    })
    setPage(0)
  }, 200), [filterState]);

  return (
    <Page
      title={t('pages.fintechManagement.title')}
      wrapperClassName="fintech-management"
      subtitle={t('pages.fintechManagement.subtitle')}
      actions={
        <Button icon="fa fa-plus" onClick={() => setModalOpen(true)}>
          {t('common.create')}
        </Button>
      }
    >
      <div className="filter-bar">
        <Row noGutters>
          <Col xs={12} sm={12} md={8} className="left-col">
            <Input
              fullWidth
              placeholder={t('pages.fintechManagement.fintechSearchText')}
              value={searchQueryInner}
              name="search"
              id="search"
              onChange={(e) => {
                setSearchQueryInner(e.target.value);
                handleQueryChange(e);
              }}
              endAdornment={
                filterState?.searchQuery && <IconButton
                    icon={'fa fa-close'}
                    onClick={() => {
                      setSearchQueryInner('');
                      setFilterState({
                         ...filterState,
                         searchQuery: ''
                      })
                    }}
                />
              }
            />
          </Col>
          {/* <Col xs={0} sm={0} md={2} className="col" />
          <Col xs={0} sm={0} md={2} className="col" /> */}

          <Col xs={12} sm={12} md={4} className="col">
            <Select
              placeholder={t('common.status')}
              fullWidth
              options={statusOptions}
              value={filterState?.status}
              onChange={handleStatusChange}
              isClearable
            />
          </Col>
          {/* <Col xs={12} sm={12} md={2} className="right-col">
            <Button variant="secondary" fullWidth onClick={handleFilterClear}>
              {t('common.clear')}
            </Button>
          </Col> */}
        </Row>
      </div>
      <FintechTable filter={filterState} page={page} setPage={setPage} pageSize={pageSize} setPageSize={setPageSize}/>
      <FintechFormModal
        isOpen={modalOpen}
        onClose={(updated) => {
          setModalOpen(false);
          if (updated) {
            setFilterState({ ...filterState })
          }
        }}
      />
    </Page>
  )
};

export default FintechManagement;