/* eslint-disable no-nested-ternary */
import React, { useState, useMemo, useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Page, Table, Chip, Button, Tooltip } from 'components/common'
import Amount from 'components/lib/amount';
import queryparam from 'components/lib/queryparam';
import { showError } from 'utils'
import Service from 'service'
import moment from 'moment';
import { format } from 'date-fns';
import { useFintech } from 'components/context/fintech-context'
import useAspsp from 'components/data-hooks/aspsp'
import { navigate } from 'gatsby'
import { useSession } from '../../../../context/session-context';

// import './selected-payment-status'

const SelectedPaymentStatus = () => {
  const { selectedFintech } = useFintech()
  const { aspspsMap, loading: aspspsMapLoading } = useAspsp()
  const [page, setPage] = useState(0)
  const [pageSize, setPageSize] = useState(10)
  const [totalPage, setTotalPage] = useState(0)
  const {t} = useTranslation();
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)
  const { user } = useSession()

  const columns = useMemo(
    () => [
      {
        header: t('common.date'),
        render: (rowData) => {
          return (
            <span className="data">{format(new Date(rowData.$obaf.timestamp), 'yyyy-MM-dd')}</span>
          )
      }
      },
      {
        header: t('pages.paymentHistory.debtorBank'),
        render: (rowData) => {
          if (aspspsMapLoading) {
            return `${t('common.loading')}...`
          }

          const aspsp = aspspsMap[rowData?.$obaf?.aspspId]
          if (!aspsp) {
            return ''
          }

          return (
            <div className="banks">
              <span>{aspsp?.name}</span>
            </div>
          )
        }
      },
      { header: t('pages.paymentHistory.pisClient'),
        render: (rowData) => {
          return (
            <span className="data">{rowData?.$obaf?.clientName ? rowData?.$obaf?.clientName: 'N/A'}</span>
          )
        }
      },
      { header: t('pages.paymentHistory.paymentId'), render: (rowData) => {
        return (
          <Tooltip title={rowData.paymentId}>
            <div className="element">
              <span className="data">{rowData.paymentId}</span>
            </div>
          </Tooltip>
        )
      }},
      { header: 'Debtor CPF/CNPJ',
        render: (rowData) => {
          return (
            <span className="data">{rowData?.details?.cpf || rowData?.details?.cnpj}</span>
          )
        }
      },
      { header: 'Creditor CPF/CNPJ',
        render: (rowData) => {
          return (
            <span className="data">{rowData.details?.creditorCpfCnpj}</span>
          )
        }
      },
      {
        header: t('pages.paymentHistory.amount'),
        render: (rowData) => {
          return (
            // <span className="data">{Amount(rowData?.amount)}</span>
            <span className="data">{rowData?.amount.amount}</span>
          )
        }
      },
      {
        header: t('common.status'),
        field: 'status',
        render: (rowData) => {
          let variant = 'default';
          let variantDesc='';
          if (rowData.status === 'Pending') {
              rowData.status = t('pages.paymentHistory.filter.status.pending')
              variant = 'pending';
          }
          if (rowData.status === 'Booked') {
              rowData.status = t('pages.paymentHistory.filter.status.booked')
              variant = 'success';
              variantDesc = t('pages.paymentHistory.paymentStatus.booked')
          }
          if (rowData.status === 'Future') {
              rowData.status = t('pages.paymentHistory.filter.status.future')
              variant = 'future';
              variantDesc = t('pages.paymentHistory.paymentStatus.future')
          }
          if (rowData.status === 'Rejected') {
              rowData.status = t('pages.paymentHistory.filter.status.rejected')
              variant = 'error';
              variantDesc = t('pages.paymentHistory.paymentStatus.rejected')
          }
          if (rowData.status === 'Cancelled') {
              rowData.status = t('pages.paymentHistory.filter.status.cancelled')
              variant = 'default';
              variantDesc = t('pages.paymentHistory.paymentStatus.cancelled')
          }
          return (
            <Tooltip className="tooltip" title={variantDesc? variantDesc: <><div>{t('pages.paymentHistory.paymentStatus.pending1')}</div><div>{t('pages.paymentHistory.paymentStatus.pending2')}</div></>}>
                <Chip className="chip" label={rowData.status} variant={variant} />
            </Tooltip>
          )
        }
      },
      {
        header: '',
        render: (rowData) => (
          <div className="table-buttons">
            <Button
              size="small"
              variant="secondary"
              className="edit"
              onClick={() => {
                navigate(
                  `/user/payment-history/details?paymentId=${rowData.paymentId}&aspspId=${rowData?.$obaf?.aspspId}&status=${rowData.status}&debtorCpf=${rowData.details.cpf}&creditorCpf=${rowData.details.creditorCpfCnpj}&client=${rowData?.$obaf?.name}${rowData?.details?.rejectionReason ? `&rejectionReason=${rowData.details.rejectionReason.detail}`: ''}${rowData?.details?.cancellation?.cancelledByCpf ? `&cancellationReason=${rowData.details.cancellation.reason}`: ''}${rowData?.remittanceInformation ? `&remittanceInformation=${rowData.remittanceInformation}`: ''}`
                )
              }}
            >
              {t('common.viewDetails')}
            </Button>
          </div>
        )
      }
    ],
    [aspspsMap, aspspsMapLoading]
  )

  const loadPayments = useCallback(async () => {
    if (!selectedFintech?.id) {
      return
    }

    setLoading(true)

    try {
      const { data, total } = await Service.payments.paymentHistory(
        selectedFintech?.id,
        user?.fintechId,
        {status: queryparam('status'),
          range: queryparam('range')},
          'desc',
          page * pageSize,
          pageSize
      )
      setData(data?.payments || [])
      setTotalPage(Math.trunc(total / pageSize) + (total % pageSize ? 1 : 0))
    } catch (err) {
      console.log(err)
      showError(err)
    }
    setLoading(false)
  }, [selectedFintech, page, pageSize])

  useEffect(() => {
    loadPayments()
  }, [loadPayments])

  return (
   <Page
    breadcrumb ={[
      {
        label: t('pages.paymentHistory.paymentDashboard'),
        url: '/user/payment-history'
      },
      {label: t('pages.paymentHistory.details')}
    ]}
   >
  <Table
    columns={columns}
    data={data}
    loading={loading}
    pagination={{
      page,
      onChange: setPage,
      total: totalPage,
      size: pageSize,
      onSizeChange: setPageSize
    }}
    noDataMessage={t('pages.paymentHistory.paymentNoDataMessage')}
  />

   </Page>
  )
}

export default SelectedPaymentStatus