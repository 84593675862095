import React, { useState, useCallback, useMemo } from 'react';
import { Input, Button } from 'components/common';
// eslint-disable-next-line import/no-named-as-default
import toast from 'react-hot-toast';
import { Row, Col } from 'reactstrap';
import { changePassword } from 'components/lib/auth';
import { showError } from 'utils';
import { useTranslation } from 'react-i18next';

const Password = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState({});

  const onInputChange = useCallback(({ target }) => {
    const { id, value } = target;
    setValues({ ...values, [id]: value });
  }, [values]);

  const onSubmit = useCallback(async() => {
    setLoading(true);
    try {
      await changePassword(values);
      setValues({});
      toast.success(t('common.updatedSuccessfully'));
    } catch (err) {
      console.log(err);
      showError(err);
    }

    setLoading(false);
  }, [values]);

  const passwordStatus = useMemo(() => ({
    lengthRule: values.newPassword && values.newPassword.length >= 8,
    upperCaseRule: values.newPassword && /[A-Z]/.test(values.newPassword),
    numberRule: values.newPassword && /\d/.test(values.newPassword),
    charRule: values.newPassword && /[!@#$~]/.test(values.newPassword)
  }), [values.newPassword]);

  const isFormValid = useMemo(() => {
    return values.newPassword &&
    values.newPassword === values.newPassword2 &&
    // values.password &&
    passwordStatus.lengthRule &&
    passwordStatus.upperCaseRule &&
    passwordStatus.numberRule &&
    passwordStatus.charRule;
  }, [passwordStatus, values]);

  return (
    <div className="form password">
      {/* <Input
        id="password"
        name="password"
        label={t('pages.profile.oldPassword')}
        value={values?.password || ''}
        required
        fullWidth
        onChange={onInputChange}
        wrapperClass="control"
        type="password"
      /> */}
      <Input
        id="newPassword"
        name="newPassword"
        label={t('pages.profile.newPassword')}
        value={values?.newPassword || ''}
        required
        fullWidth
        onChange={onInputChange}
        wrapperClass="control"
        type="password"
      />
      <Input
        id="newPassword2"
        name="newPassword2"
        label={t('pages.profile.retypeNewPassword')}
        value={values?.newPassword2 || ''}
        required
        fullWidth
        onChange={onInputChange}
        wrapperClass="control"
        type="password"
      />

      <div className="passwordRules">
        <Row>
          <Col xs={12} sm={12} md={6} lg={6}>
            <div className={'rule' + (passwordStatus.lengthRule ? ' valid' : '')}><i className="fa fa-check" />{t('pages.profile.atLeastCharacters')}</div>
          </Col>
          <Col xs={12} sm={12} md={6} lg={6}>
            <div className={'rule' + (passwordStatus.upperCaseRule ? ' valid' : '')}><i className="fa fa-check" />{t('pages.profile.atLeastUpperCaseLetter')}</div>
          </Col>
          <Col xs={12} sm={12} md={6} lg={6}>
            <div className={'rule' + (passwordStatus.numberRule ? ' valid' : '')}><i className="fa fa-check" />{t('pages.profile.atLeastNumber')}</div>
          </Col>
          <Col xs={12} sm={12} md={6} lg={6}>
            <div className={'rule' + (passwordStatus.charRule ? ' valid' : '')}><i className="fa fa-check" />{t('pages.profile.atLeastSpecialCharacters')}</div>
          </Col>
        </Row>
      </div>

      <Button
        className="register-btn"
        disabled={loading || !isFormValid}
        onClick={onSubmit}
      >
        {loading ? <i className="fa fa-spin fa-circle-o-notch" /> : null}
        {t('common.saveChanges')}
      </Button>
    </div>
  );
};

export default Password;