/* eslint-disable dot-notation */
/* eslint-disable max-lines */
import React, { useState, useEffect, useCallback } from 'react'
import { Loader } from 'components/common'
import Service from 'service'
import { showError } from 'utils'
import moment from 'moment'
import './response-time.scss'
import { rangeOptions } from '../../range-filter'
import ChartBox from '../../chart-box'
import { DownloadCard } from 'components/business-components'
import { useTranslation } from 'react-i18next'
import useIsMounted from 'components/hooks/use-is-mounted'
import { useFintech } from 'components/context/fintech-context';
import * as FileSaver from 'file-saver'
import * as XLSX from 'xlsx-js-style'

const ResponseTime = ({ aspspId }) => {
  const isMounted = useIsMounted()
  const { t } = useTranslation()
  const [aispChartData, setAispChartData] = useState([])
  const [aispChartLabelValues, setAispChartLabelValues] = useState([])
  const [pispChartData, setPispChartData] = useState([])
  const [pispChartLabelValues, setPispChartLabelValues] = useState([])
  const [isLoading, setIsLoading] = useState('')
  const [range, setRange] = useState(rangeOptions[0])
  const { selectedFintech } = useFintech();

  const loadData = useCallback(async () => {
    setIsLoading('loading')

    try {
      const from = moment().add(-1 * range, 'days').format('YYYY-MM-DD');
      const to = moment().format('YYYY-MM-DD')

      const metricsValues = await Service.metrics.getSelectedBank(aspspId, from, to, selectedFintech?.id);
      groupByValuesForChart(metricsValues?.data?.metrics);
    } catch (err) {
      showError(err)
    }
  }, [aspspId, range])

  useEffect(() => {
    loadData()
  }, [loadData])

  const groupByValuesForChart = useCallback((data) => {
    let tempAispData = { values: [], label: [] }
    let tempPispData = { values: [], label: [] }
    for (let i = 0; i < data?.length; i++) {
      if (data[i].type === 'PISP') {
        tempPispData.values.push(data[i].durationInMillis ?? 0)
        tempPispData.label.push(
          moment.utc(new Date(data[i].startDateTime)).format('YYYY-MM-DD')
        )
      } else {
        tempAispData.values.push(data[i].durationInMillis ?? 0)
        tempAispData.label.push(
          moment.utc(new Date(data[i].startDateTime)).format('YYYY-MM-DD')
        )
      }
    }
    if (isMounted.current) {
      setAispChartData(tempAispData.values)
      setAispChartLabelValues(tempAispData.label)
      setPispChartData(tempPispData.values)
      setPispChartLabelValues(tempPispData.label)
      setIsLoading('success')
    }
  }, [])

  const onDownload = async (fromDate, toDate) => {
    const from = moment(fromDate).format('YYYY-MM-DD')
    const to = moment(toDate).format('YYYY-MM-DD')

    const { data } = await Service.metrics.getSelectedBank(aspspId, from, to, selectedFintech?.id)
    const result = FormatDownloadData(data.metrics)

    const fileType =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
    const fileExtension = '.xlsx'

    const ws = result.length && XLSX.utils.json_to_sheet(result)

    ws['A1'].s = {
      fill: {
        patternType: 'solid',
        fgColor: { rgb: 'FFBDC3C7' }
      },
      font: {
        name: 'Times New Roman',
        sz: 14,
        color: { rgb: '#FF000000' },
        bold: false,
        italic: false,
        underline: false
      },
      alignment: {
        vertical: 'center',
        horizontal: 'left'
      },
      border: {
        right: { style: 'thin', color: 'FF979A9A' }
      }
    }

    ws['B1'].s = {
      fill: {
        patternType: 'solid',
        fgColor: { rgb: 'FFBDC3C7s' }
      },
      font: {
        name: 'Times New Roman',
        sz: 14,
        color: { rgb: '#FF000000' },
        bold: false,
        italic: false,
        underline: false
      },
      alignment: {
        vertical: 'center',
        horizontal: 'left'
      },
      border: {
        right: { style: 'thin', color: 'FF979A9A' }
      }
    }

    ws['C1'].s = {
      fill: {
        patternType: 'solid',
        fgColor: { rgb: 'FFBDC3C7' }
      },
      font: {
        name: 'Times New Roman',
        sz: 14,
        color: { rgb: '#FF000000' },
        bold: false,
        italic: false,
        underline: false
      },
      alignment: {
        vertical: 'center',
        horizontal: 'left'
      },
      border: {
        right: { style: 'thin', color: 'FF979A9A' }
      }
    }

    var wscols = [{ wch: 18 }, { wch: 25 }, { wch: 25 }]

    ws['!cols'] = wscols

    ws['!cols'][3] = { hidden: true }

    var wsrows = [{ hpt: 20 }]

    ws['!rows'] = wsrows

    const wb = { Sheets: { data: ws }, SheetNames: ['data'] }
    const excelBuffer = XLSX.write(wb, {
      bookType: 'xlsx',
      type: 'array',
      cellStyles: true
    })
    const data1 = new Blob([excelBuffer], { type: fileType })

    FileSaver.saveAs(data1, 'Api Response Time' + fileExtension)
  }

  const FormatDownloadData = (data) => {
    let tempData = []
    for (let i = 0; i < data?.length; i++) {
      data[i].Date = new Date(data[i].startDateTime).toLocaleDateString()
      data[i].type == 'PISP'
        ? (data[i].Pisp_Response_Time = data[i].durationInMillis)
        : (data[i].Aisp_Response_Time = data[i].durationInMillis)
      tempData.push({
        Date: data[i].Date,
        'Aisp Response Time (ms)': data[i].Aisp_Response_Time,
        'Pisp Response Time (ms)': data[i].Pisp_Response_Time
      })
    }
    return tempData
  }

  const options = {
    title: {
      text: ''
    },
    series: [
      {
        type: 'area',
        data: aispChartData
      }
    ],
    xAxis: {
      categories: aispChartLabelValues
    },
    yAxis: {
      title: false
    },
    legend: {
      enabled: false
    },
    credits: {
      enabled: false
    }
  }
  const options1 = {
    title: {
      text: ''
    },
    series: [
      {
        type: 'area',
        data: pispChartData
      }
    ],
    xAxis: {
      categories: pispChartLabelValues
    },
    yAxis: {
      title: false
    },
    legend: {
      enabled: false
    },
    credits: {
      enabled: false
    }
  }

  return isLoading === 'loading' ? (
    <div className="charts-loader">
      <Loader />
    </div>
  ) : (
    <>
      <ChartBox
        rangeValue={range}
        onRangerangeValueChange={setRange}
        noData={aispChartData?.length == 0}
        options={options}
        title={t('pages.adminAnalyticsDetail.aispAvgResponseTime')}
        noDataText={t('pages.adminAnalyticsDetail.noAispDataFound')}
      />

      <ChartBox
        rangeValue={range}
        onRangerangeValueChange={setRange}
        noData={pispChartData?.length == 0}
        options={options1}
        title={t('pages.adminAnalyticsDetail.pispAvgResponseTime')}
        noDataText={t('pages.adminAnalyticsDetail.noPispDataFound')}
      />

      <DownloadCard
        title={t('pages.adminAnalyticsDetail.apiResponseTime')}
        onDownload={(fromDate, toDate) => {
          onDownload(fromDate, toDate)
        }}
      />
    </>
  )
}

export default ResponseTime
