/* eslint-disable max-lines */
/* eslint-disable max-statements */
/* eslint-disable no-nested-ternary */
import React, {useMemo, useState, useEffect, useCallback} from 'react';
import './payment-graph.scss';
import { Select, DatePicker, Loader } from 'components/common';
import Amount from 'components/lib/amount';
import ChartBox from '../../admin-analytics-detail/chart-box'
import { useTranslation } from 'react-i18next';
import Service from 'service'
import { useFintech } from 'components/context/fintech-context'
import { showError } from 'utils';
import moment from 'moment';
import { navigate } from 'gatsby-link';
import useAspsp from 'components/data-hooks/aspsp';
import greaterThanIcon from '../../../../../assets/images/Left.png'
// @ts-ignore

import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import { DateRangePicker } from 'react-dates';
import { useSession } from '../../../../context/session-context';

const PaymentGraph = ({value, onChange, TabToggle, tab, user, setIsDataPresent}) => {
  const { selectedFintech } = useFintech();
  const { aspspsMap, loading: aspspsMapLoading } = useAspsp();
  const [pendingPayments, setPendingPayments] = useState();
  const [bookedPayments, setBookedPayments] = useState();
  const [rejectedPayments, setRejectedPayments] = useState();
  const [canceledPayments, setCanceledPayments] = useState();
  const [futurePayments, setFuturePayments] = useState();
  const [dateRange, setDateRange] = useState([null, null]);
  const [paymentsTotal, setPaymentsTotal] = useState();
  const [selectedPaymentstotal, setSelectedPaymentsTotal] = useState();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState('');
  const [isRejectedPaymentsLoading, setIsRejectedPaymentsLoading] = useState('');
  const [date, setDate] = useState([null, null]);
  const [labelValues, setLabelValues] = useState();
  const [rejectedPaymentValues, setRejectedPaymentValues] = useState();
  const [canceledPaymentValues, setCanceledPaymentValues] = useState();

  const today = moment();
  const [startDate, setStartDate] = useState(value.range?.[0]);
  const [endDate, setEndDate] = useState(value?.range?.[1]);
  const [focusedInput, setFocusedInput] = useState(null);

  const [startDateRange, setStartDateRange] = useState(value?.range?.[0]);
  const [endDateRange, setEndDateRange] = useState(value?.range?.[1]);
  const [focusedInputPayments, setFocusedInputPayments] = useState(null);
  const [showleftSubtitleRange, setShowleftSubtitleRange] = useState(true);
  const [showrightSubtitleRange, setShowrightSubtitleRange] = useState(true);

  const loadSelectedPayments = useCallback(async (startDate, endDate) => {

    if (!selectedFintech?.id) {
      return
    }
    setIsRejectedPaymentsLoading('loading')


    try {
      const {data} = startDate ? await Service.payments.paymentHistory(
        selectedFintech?.id,
        user?.fintechId,
        {range: [startDate, endDate ? endDate : today]}
        ) : await Service.payments.paymentHistory(selectedFintech?.id,  user?.fintechId)
          groupByValuesforBarChart(data?.payments)
    } catch (err) {
      console.log(err)
      showError(err || err.message.error)
    }

  }, [selectedFintech])


  const loadPayments = useCallback(async (startDate, endDate) => {
    if (!selectedFintech?.id) {
      return
    }
    setIsLoading('loading')
    try {
      const {data, total} =  startDate ? await Service.payments.paymentHistory(
        selectedFintech?.id,
        user?.fintechId,
        {range: [startDate, endDate ? endDate : today]}
        ): await Service.payments.paymentHistory(selectedFintech?.id,  user?.fintechId)
      total == 0 ? setIsDataPresent(false) : setIsDataPresent(true)
      groupByValues(data.payments)
    } catch (err) {
      console.log(err)
      showError(err || err.message.error)
    }

    setIsLoading(false)
  }, [selectedFintech])

  useEffect(() => {
      loadPayments(startDateRange, endDateRange)
  }, [loadPayments])

  useEffect(() => {
      loadSelectedPayments(startDate, endDate)
  }, [loadSelectedPayments])

  const groupByValues = useCallback((data) => {
    let pendingStatus = [];
    let bookedStatus = [];
    let canceledStatus = [];
    let rejectedStatus = [];
    let futureStatus = [];

    for (let i =0; i < data?.length; i++) {
      if (data[i].status == 'Pending') {
        pendingStatus.push(data[i])
      } else if (data[i].status == 'Booked') {
        bookedStatus.push(data[i])
      } else if (data[i].status == 'Rejected') {
        rejectedStatus.push(data[i])
      } else if (data[i].status == 'Cancelled') {
        canceledStatus.push(data[i])
      } else if (data[i].status == 'Future') {
        futureStatus.push(data[i])
      }
    }

    setPaymentsTotal(pendingStatus.length + bookedStatus.length + canceledStatus.length + rejectedStatus.length + futureStatus.length)
    setPendingPayments(pendingStatus.length);
    setBookedPayments(bookedStatus.length);
    setRejectedPayments(rejectedStatus.length);
    setCanceledPayments(canceledStatus.length);
    setFuturePayments(futureStatus.length);
    setIsLoading('success');
  })

  const groupByValuesforBarChart = useCallback((data) => {
   let tempData = [];

    for (let i =0; i < data?.length; i++) {
      if (data[i].status == 'Rejected') {
        tempData.push(data[i])
      } else if (data[i].status == 'Cancelled') {
        tempData.push(data[i])
      }
    }

    const formattedData = []

    tempData?.reduce(function (res, value) {
      if (!res[value.$obaf.aspspId]) {
        res[value.$obaf.aspspId] = {
          // aspsp: aspspsMap[value.$obaf.aspspId]?.name && aspspsMap[value.$obaf.aspspId]?.name,
          aspsp: value.$obaf.aspspId,
          rejectedPayments: 0,
          cancelledPayments: 0
        }
        formattedData.push(res[value.$obaf.aspspId])
      }

      if (value.status =='Rejected') {
        res[value.$obaf.aspspId].rejectedPayments++;
      } else {
        res[value.$obaf.aspspId].cancelledPayments++;
      }
      return res;
    }, {})

    const labels= [];
    const rejectedValues = [];
    const canceledValues = [];

   formattedData.map((data) => (
     // eslint-disable-next-line no-sequences
      labels.push(data.aspsp),
      rejectedValues.push(data.rejectedPayments),
      canceledValues.push(data.cancelledPayments)
   ))

  setSelectedPaymentsTotal(rejectedValues.length + canceledValues.length);

   setLabelValues(labels);
   setRejectedPaymentValues(rejectedValues);
   setCanceledPaymentValues(canceledValues);
   setIsRejectedPaymentsLoading('success')
  }, [])

  const getOptions = useCallback((pendingPaymentData, bookedPaymentData, canceledPaymentData, rejectedPaymentData, futurePaymentData) => ({
    colors: ['#2FAFA1', '#B1466D', '#E78D69', '#75859F', '#50B8EA'],
    chart: {
      type: 'column',
      inverted: true,
      polar: true,
      height: 180
    },
    credits: {
      enabled: false
    },
    title: {
      text: `<h3 style="font-weight:700">${paymentsTotal}</h3><br><span style="color:#60708B; font-size: 14px">${t('pages.paymentHistory.payments')}</span></br>`,
      align: 'center',
      verticalAlign: 'top',
      y: 80
    },
    pane: {
        size: '169%',
        innerSize: '40%',
        center: ['50%', '30%']
    },
    xAxis: {
      visible: false,
      // tickInterval: 1,
      // labels: {
      //     align: 'right',
      //     useHTML: true,
      //     allowOverlap: true,
      //     step: 1,
      //     y: 5,
      //     style: {
      //         fontSize: '13px'
      //     }
      // },
      lineWidth: 0,
      categories: [
        '</span></span>'
      ]
    },
    yAxis: {
      gridLineColor: 'transparent',
      tickLength: 0,
      labels: {
        enabled: false
      },
      lineWidth: 0
    },
    plotOptions: {
      column: {
          allowPointSelect: true,
          stacking: 'normal',
          borderWidth: 4,
          pointPadding: 0,
          groupPadding: 0.15
      }
    },
    series: [{
        name: 'Booked (in %)',
        data: [bookedPaymentData],
        showInLegend: false,
        cursor: 'pointer',
        // dataLabels: {
        //   enabled: true,
        //   inside: true,
        //   format: `<b style="width: 100px; display: block; color: #000">${bookedPaymentData}%</b>`
        // },
        point: {
          events: {
            click: function () {
              if (!this.selected) {
                TabToggle('all')
                // tab == 'scheduled' && TabToggle('instant');
                onChange({...value, status: 'BOOKED'})
              } else {
                // tab == 'scheduled' && TabToggle('instant');
                const updatedData = {...value};
                delete updatedData.status
                onChange(updatedData)
              }
            }
          }
        },
        states: {
          select: {
            borderWidth: 4,
            borderColor: '#2FAFA1',
            color: '#2FAFA1'
          }
        }
      },
      {
        name: 'Rejected (in %)',
        data: [rejectedPaymentData],
        showInLegend: false,
        cursor: 'pointer',
        // dataLabels: {
        //   enabled: true,
        //   inside: true,
        //   format: `<b style="width: 100px; display: block; color: #000">${rejectedPaymentData}%</b>`
        // },
        point: {
          events: {
            click: function () {
              if (!this.selected) {
                TabToggle('all')
                // tab == 'scheduled' && TabToggle('instant');
                onChange({...value, status: 'REJECTED'})
              } else {
                // tab == 'scheduled' && TabToggle('instant');
                const updatedData = {...value};
                delete updatedData.status
                onChange(updatedData)
              }
            }
          }
        },
        states: {
          select: {
            borderWidth: 4,
            borderColor: '#B1466D',
            color: '#B1466D'
          }
        }
      },
      {
        name: 'Pending (in %)',
        data: [pendingPaymentData],
        showInLegend: false,
        cursor: 'pointer',
        // dataLabels: {
        //   enabled: true,
        //   inside: true,
        //   format: `<b style="width: 100px; display: block; color: #000">${pendingPaymentData}%</b>`
        // },
        point: {
          events: {
            click: function () {
              if (!this.selected) {
                TabToggle('all')
                // tab == 'scheduled' && TabToggle('instant');
                onChange({...value, status: 'PENDING'})
              } else {
                // tab == 'scheduled' && TabToggle('instant');
                const updatedData = {...value};
                delete updatedData.status
                onChange(updatedData)
              }
            }
          }
        },
        states: {
          select: {
            borderWidth: 4,
            borderColor: '#E78D69',
            color: '#E78D69'
          }
        }
      },
      {
        name: 'Cancelled (in %)',
        data: [canceledPaymentData],
        showInLegend: false,
        cursor: 'pointer',
        // dataLabels: {
        //   enabled: true,
        //   inside: true,
        //   format: `<b style="width: 100px; display: block; color: #000">${canceledPaymentData}%</b>`
        // },
        point: {
            events: {
              click: function () {
                if (!this.selected) {
                  TabToggle('all')
                  // tab == 'instant' && TabToggle('scheduled');
                  onChange({...value, status: 'CANCELLED'})
                } else {
                  // tab == 'scheduled' && TabToggle('instant');
                  const updatedData = {...value};
                  delete updatedData.status
                  onChange(updatedData)
                }
              }
            }
        },
        states: {
          select: {
            borderWidth: 4,
            borderColor: '#75859F',
            color: '#75859F'
          }
        }
      },
      {
        name: 'Future (in %)',
        data: [futurePaymentData],
        showInLegend: false,
        cursor: 'pointer',
        // dataLabels: {
        //   enabled: true,
        //   inside: true,
        //   format: `<b style="width: 100px; display: block; color: #000">${futurePaymentData}%</b>`
        // },
        point: {
            events: {
              click: function () {
                if (!this.selected) {
                  TabToggle('all')
                  // tab == 'instant' && TabToggle('scheduled');
                  onChange({...value, status: 'FUTURE'})
                } else {
                  // tab == 'scheduled' && TabToggle('instant');
                  const updatedData = {...value};
                  delete updatedData.status
                  onChange(updatedData)
                }
              }
            }
        },
        states: {
          select: {
            borderWidth: 4,
            borderColor: '#50B8EA',
            color: '#50B8EA'
          }
        }
      }]
}))

  const getbarChartOptions = useCallback((labels, rejectedPayments, canceledPayments) => ({
      chart: {
        type: 'column',
        height: 215
      },
      title: {
        text: ''
      },
      credits: {
        enabled: false
      },
      pane: {
        size: '169%',
        innerSize: '40%',
        center: ['50%', '30%']
      },
      xAxis: {
        visible: true,
        categories: labels
      },
      legend: {
        enabled: false
      },
      plotOptions: {
        column: {
            stacking: 'normal'
        }
      },
      yAxis: {
        title: 'Payments'
      },
      series: [
        {
          name: 'Rejected',
          data: rejectedPayments,
          color: '#B1466D'
        },
        {
          name: 'Canceled',
          data: canceledPayments,
          color: '#75859F'
        }
      ]
  }), [t]);

  const pieChartOptions = useMemo(() =>
    getOptions(Math.round((pendingPayments/paymentsTotal)*100), Math.round((bookedPayments/paymentsTotal)*100), Math.round((canceledPayments/paymentsTotal)*100), Math.round((rejectedPayments/paymentsTotal)*100), Math.round((futurePayments/paymentsTotal)*100)), [getOptions]
  );

  const barChartOptions = useMemo(() =>
    getbarChartOptions(labelValues, rejectedPaymentValues, canceledPaymentValues)
  , [getbarChartOptions, labelValues, rejectedPaymentValues, canceledPaymentValues])

  const isSameDay = (a, b) => {
    if (!moment.isMoment(a) || !moment.isMoment(b)) {
        return false;
    }
    return a.date() === b.date() &&
      a.month() === b.month() &&
      a.year() === b.year();
  }

  const onDatesChange = (startDate, endDate) => {
    setStartDate(startDate)
    setEndDate(endDate)
    loadSelectedPayments(startDate, endDate)
    setFocusedInput(false)
    setShowrightSubtitleRange(false)
    // onChange({range: [startDate, endDate]});
  }

  const onDateRangeChange = (startDate, endDate) => {
    setStartDateRange(startDate)
    setEndDateRange(endDate)
    loadPayments(startDate, endDate)
    onChange({...value, range: [startDate, endDate]});
    setFocusedInputPayments(false)
    setShowleftSubtitleRange(false)
    onDatesChange(startDate, endDate)
  }

  const renderDatePresets= (onDatesChange) => {
    const presets = [{
      text: 'Today',
      startDate: moment().utc().startOf('day'),
      endDate: moment().utc().endOf('day')
    },
    {
      text: 'Last 7 days',
      startDate: moment().subtract(1, 'week'),
      endDate: today
    },
    {
      text: 'Last 14 days',
      startDate: moment().subtract(14, 'days'),
      endDate: today
    },
    {
      text: 'Last 30 days',
      startDate: moment().subtract(30, 'days'),
      endDate: today
    }
  ];

    return (
      <div className="infoContainer">
        {presets.map(({ text, startDate: start, endDate: end }) => {
          return (
            <button
              key={text}
              className={'button'}
              className="button"
              type="button"
              onClick={() => {
                onDatesChange(start, end);
                // setIsActive(text)
              }}
            >
              {text}
            </button>
          );
        })}
      </div>
    )
  }
 


  return (
    <>
    <div className="payment-graph-wrapper">
      <div className="left-chart">
        <div className="left">
          <h6 className={user?.userType == 'super-admin' ? 'admin-heading' : 'heading'}>{t('pages.paymentHistory.overview')}</h6>
          {showleftSubtitleRange && <p className="subTitle">Showing data for last 7 days</p>}
          {
             isLoading == 'loading'
             ? <div className="charts-loader"><Loader /></div>
             : <ChartBox
             // rangeValue={range}
             // onRangerangeValueChange={setRange}
             noData={paymentsTotal == 0}
             options={pieChartOptions}
             // title={t('pages.adminAnalyticsDetail.aispNoOfRequests')}
             noDataText={t('pages.adminAnalyticsDetail.noDataFound')}
             />
          }
        </div>
        <div className="right">
          <div className={user?.userType == 'super-admin' ? 'admin-date-container' : 'date-container'}>
            <DateRangePicker
              startDate={startDateRange}
              startDateId="startDate"
              endDate={endDateRange}
              endDateId="endDate"
              onDatesChange={({ startDate, endDate }) => {
                onDateRangeChange(startDate, endDate)
              }}
              focusedInput={focusedInputPayments}
              onFocusChange={(focusedInput) => {
                setFocusedInputPayments(focusedInput)
              }}
              showDefaultInputIcon
              inputIconPosition="after"
              startDatePlaceholderText={'Start date'}
              endDatePlaceholderText={'End date'}
              isOutsideRange={() => false}
              anchorDirection="left"
              daySize={30}
              small
              calendarInfoPosition="before"
              renderCalendarInfo={() => renderDatePresets(onDateRangeChange)}
              firstDayOfWeek={1}
              displayFormat="YYYY-MM-DD"
              readOnly
            />
          </div>
          {isLoading!=='loading' && (
            <table className="payment-table">
            {bookedPayments ? (
                <tr>
                  {/* <td><span className="booked-color"></span></td> */}
                  <td className="status">
                    <span className="booked-color"></span>
                    <span className="title">{t('pages.paymentHistory.filter.graph.booked')}</span>
                  </td>
                  <td/>
                  <td className="count" onClick={() => {
                    navigate(`/user/payment-history/payment-status?status=BOOKED${startDateRange ? `&range=${[startDateRange, endDateRange]}`: ''}`)
                  }}>{bookedPayments}</td>
                </tr>
            ): null}
            {pendingPayments ? (
              <tr>
                {/* <td><span className="pending-color"></span></td> */}
                <td className="status">
                  <span className="pending-color"></span>
                  <span className="title">{t('pages.paymentHistory.filter.graph.pending')}</span>
                </td>
                <td/>
                <td className="count" onClick={() => {
                    navigate(`/user/payment-history/payment-status?status=PENDING${startDateRange ? `&range=${[startDateRange, endDateRange]}`: ''}`)
                }}>{pendingPayments}</td>
              </tr>
            ): null}
            {rejectedPayments ? (
              <tr>
                {/* <td><span className="rejected-color"></span></td> */}
                <td className="status">
                  <span className="rejected-color"></span>
                  <span className="title">{t('pages.paymentHistory.filter.graph.rejected')}</span>
                </td>
                <td/>
                <td className="count" onClick={() => {
                    navigate(`/user/payment-history/payment-status?status=REJECTED${startDateRange ? `&range=${[startDateRange, endDateRange]}`: ''}`)
                }}>{rejectedPayments}</td>
              </tr>
            ): null}
            {canceledPayments ? (
              <tr>
                {/* <td><span className="canceled-color"></span></td> */}
                <td className="status">
                  <span className="canceled-color"></span>
                  <span className="title">{t('pages.paymentHistory.filter.graph.cancelled')}</span>
                </td>
                <td/>
                <td className="count"onClick={() => {
                    navigate(`/user/payment-history/payment-status?status=CANCELLED${startDateRange ? `&range=${[startDateRange, endDateRange]}`: ''}`)
                }}>{canceledPayments}</td>
            </tr>
            ): null}
            {futurePayments ? (
              <tr>
                {/* <td><span className="canceled-color"></span></td> */}
                <td className="status">
                  <span className="future-color"></span>
                  <span className="title">{t('pages.paymentHistory.filter.graph.future')}</span></td>
                <td/>
                <td className="count" onClick={() => {
                    navigate(`/user/payment-history/payment-status?status=FUTURE${startDateRange ? `&range=${[startDateRange, endDateRange]}`: ''}`)
                }}>{futurePayments}</td>
            </tr>
            ): null}
          </table>
          )}
        </div>
       </div>
      <div>
     </div>

     <div className="right-chart">
       <div className="right">
          <div className="header">
            <h6 className={user?.userType == 'super-admin' ? 'admin-title' : 'title'}>{t('pages.paymentHistory.rejectedPayments')}</h6>
            {showrightSubtitleRange && <p className="subTitle">Showing data for last 7 days</p>}
          </div>
          <div className={user?.userType == 'super-admin' ? 'admin-date-container' : 'date-container'}>
            <DateRangePicker
              startDate={startDate}
              startDateId="startDate"
              endDate={endDate}
              endDateId="endDate"
              onDatesChange={({ startDate, endDate }) => {
                onDatesChange(startDate, endDate)
              }}
              focusedInput={focusedInput}
              onFocusChange={(focusedInput) => {
                setFocusedInput(focusedInput)
              }}
              showDefaultInputIcon
              inputIconPosition="after"
              startDatePlaceholderText={'Start date'}
              endDatePlaceholderText={'End date'}
              isOutsideRange={() => false}
              anchorDirection="right"
              daySize={30}
              small
              calendarInfoPosition="before"
              renderCalendarInfo={() => renderDatePresets(onDatesChange)}
              firstDayOfWeek={1}
              displayFormat="YYYY-MM-DD"
              readOnly
            />
          </div>
       </div>
       {isRejectedPaymentsLoading === 'loading'
       ? <div className="charts-loader"><Loader /></div>
       : <ChartBox
       // rangeValue={range}
       // onRangerangeValueChange={setRange}
       noData={selectedPaymentstotal == 0}
       options={barChartOptions}
       // title={t('pages.adminAnalyticsDetail.aispNoOfRequests')}
       noDataText={t('pages.adminAnalyticsDetail.noDataFound')}
       />
       }
     </div>
    </div>
    </>
  )
}

export default PaymentGraph