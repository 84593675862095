/* eslint-disable */

import React from 'react'
import PropTypes from 'prop-types';

import createJwt from 'components/webtoken';
import oauth2Authorize from './authorize';

import PasswordInput from 'components/form/password';
import {getNewAuthUrl} from 'components/common/helpers';

const processRequest = (
	type,
	scopes,
	clientId,
	system
) => {
	const privateKey = system
		.clientPrivateKeySelectors
		.getPrivateKey ();

	const signed = createJwt ({
		type,
		scopes,
		privateKey,
		clientId
	});

	system
		.requestObjectActions
		.updateRequestObject (signed);

	return signed;
};


const LoadableButton = ({
	component: Button,
	label,
	onClick,
	loading,
	className
}) =>
	<Button
		className={className}
		disabled={!!loading}
		onClick={onClick}>
		{loading ? (
			<i className="fa fa-spin fa-cog"/>
		) : null} {label}
	</Button>



class Oauth2 extends React.Component {

	static propTypes = {
		name: PropTypes.string,
		authorized: PropTypes.object,
		getComponent: PropTypes.func.isRequired,
		schema: PropTypes.object.isRequired,
		authSelectors: PropTypes.object.isRequired,
		authActions: PropTypes.object.isRequired,
		errSelectors: PropTypes.object.isRequired,
		specSelectors: PropTypes.object.isRequired,
		errActions: PropTypes.object.isRequired,
		getConfigs: PropTypes.any
	};

	constructor (props, context) {
		super (props, context);

		const {
			name,
			schema,
			authorized,
			authSelectors,
			requestObjectSelectors
		} = this.props;

		const auth = authorized && authorized.get (name);
		const authConfigs = authSelectors.getConfigs () || {};
		const username = auth && auth.get ('username') || '';
		const clientId = auth && auth.get ('clientId') || authConfigs.clientId || '';
		const clientSecret = auth && auth.get ('clientSecret') || authConfigs.clientSecret || '';
		const passwordType = auth && auth.get ('passwordType') || 'request-body';

		this.state = {
			appName: authConfigs.appName,
			name: name,
			schema: schema,
			scopes: [],
			clientId: clientId,
			clientSecret: clientSecret,
			redirectUrl: authConfigs.oauth2RedirectUrl || '',
			username: username,
			password: '',
			passwordType: passwordType
		};

		this.close = this.close.bind (this);
		this.logout = this.logout.bind (this);
		this.authorize = this.authorize.bind (this);
		this.refreshToken = this.refreshToken.bind (this);
		this.onInputChange = this.onInputChange.bind (this);
		this.onScopeChange = this.onScopeChange.bind (this);
		this.untoggleLoad = this.untoggleLoad.bind (this);

		this.componentId = Math.floor (Math.random () * 10000);
	}

	componentWillUnmount () {
		clearTimeout (this._t);
	}

	close (e) {
		e.preventDefault ();

		let {authActions} = this.props;

		authActions.showDefinitions (false);
	}

	authorize (e) {
		e.preventDefault ();

		const {
			authActions,
			errActions,
			getConfigs,
			authSelectors
		} = this.props;

		const configs = getConfigs ();
		const authConfigs = authSelectors.getConfigs ();

		errActions.clear ({
			authId: name,
			type: 'auth',
			source: 'auth'
		});

		oauth2Authorize ({
			auth: this.state,
			authActions,
			errActions,
			configs,
			authConfigs
		});
	}

	onScopeChange ({target}) {
		const {checked} = target;
		const scope = target.dataset.value;
		const {scopes} = this.state;

		let newScopes = null;

		if (checked && scopes.indexOf (scope) === -1) {
			newScopes = scopes.concat ([scope]);
		} else if (!checked && scopes.indexOf (scope) > -1) {
			newScopes = scopes.filter ((val) => val !== scope);
		}

		if (newScopes) {
			this.setState ({scopes: newScopes});
		}
	}

	onInputChange ({target}) {
		const {dataset: {name}, value} = target;

		this.setState ({[name]: value});
	}

	logout (e) {
		e.preventDefault ();

		const {
			authActions,
			errActions,
			name
		} = this.props;

		this.setState ({
			loading: 'revoke'
		});

		errActions.clear ({
			authId: name,
			type: 'auth',
			source: 'auth'
		});

		authActions.logout ([name]);
		this.untoggleLoad ();
	}

	refreshToken (e) {
		e.preventDefault ();

		const {
			authActions,
			errActions,
			name
		} = this.props;

		this.setState ({
			loading: 'refresh'
		});

		errActions.clear ({
			authId: name,
			type: 'auth',
			source: 'auth'
		});

		authActions.refreshToken (this.state);
		this.untoggleLoad ();
	}

	untoggleLoad () {
		clearTimeout (this._t);

		this._t = setTimeout (() => {
			this.setState ({loading: null});
		}, 1000);
	}

	render () {
		let {
			name,
			schema,
			getComponent,
			authSelectors,
			errSelectors,
			specSelectors,
			requestObjectSelectors
		} = this.props;


		const Input = getComponent ("Input");
		const Row = getComponent ("Row");
		const Col = getComponent ("Col");
		const Button = getComponent ("Button");
		const AuthError = getComponent ("authError");
		const JumpToPath = getComponent ("JumpToPath", true);
		const Markdown = getComponent ("Markdown");

		const {isOAS3} = specSelectors;

		// Auth type consts
		const IMPLICIT = "implicit";
		const PASSWORD = "password";
		const ACCESS_CODE = isOAS3() ? "authorizationCode" : "accessCode";
		const APPLICATION = isOAS3() ? "clientCredentials" : "application";

		const flow = schema.get ("flow");
		const scopes = schema.get ("allowedScopes") || schema.get ("scopes");
		const authorizedAuth = authSelectors.authorized ().get (name);
		const isAuthorized = !!authorizedAuth;
		const errors = errSelectors.allErrors ().filter ((err) => err.get ("authId") === name);
		const isValid = !errors.filter ((err) => err.get ("source") === "validation").size;
		const description = schema.get ("description");

		const {loading} = this.state;

		return (
			<div>
				<h4>
					{name} (OAuth2, {schema.get("flow")}) <JumpToPath path={[ "securityDefinitions", name ]}/>
				</h4>

				{!this.state.appName ? null : <h5>Application: {this.state.appName}</h5>}

				{description && <Markdown source={schema.get ("description")}/>}

				{isAuthorized && <h6>Authorized</h6>}

				{(flow === IMPLICIT || flow === ACCESS_CODE ) && <p>Authorization URL: <code>{ schema.get("authorizationUrl") }</code></p>}
				{(flow === PASSWORD || flow === ACCESS_CODE || flow === APPLICATION ) && <p>Token URL:<code> { getNewAuthUrl(schema.get("tokenUrl")) }</code></p>}

				<p className="flow">
					Flow: <code>{ schema.get("flow") }</code>
				</p>

				{flow !== PASSWORD ? null
					: <Row>

						<Row>
							<label htmlFor="oauth_username">username:</label>
								{isAuthorized
									? <code> {this.state.username} </code>
									: <Col tablet={10} desktop={10}>
										<input id="oauth_username" type="text" data-name="username" onChange={this.onInputChange}/>
									</Col>
								}
						</Row>

						<Row>
							<label htmlFor="oauth_password">password:</label>
								{isAuthorized
									? <code className="authorised"> ****** </code>
									: <Col tablet={10} desktop={10}>
										<input id="oauth_password" type="password" data-name="password" onChange={ this.onInputChange }/>
									</Col>
								}
							</Row>
							<Row>
								<label htmlFor="password_type">type:</label>
								{isAuthorized
									? <code> {this.state.passwordType} </code>
									: <Col tablet={10} desktop={10}>
										<select id="password_type" data-name="passwordType" onChange={ this.onInputChange }>
											<option value="request-body">Request body</option>
											<option value="basic">Basic auth</option>
											<option value="query">Query parameters</option>
										</select>
									</Col>
								}
							</Row>
						</Row>
				}

				{(
					flow === IMPLICIT ||
					flow === APPLICATION ||
					flow === ACCESS_CODE ||
					(flow === PASSWORD && this.state.passwordType!== "basic")) &&
					(!isAuthorized || isAuthorized && this.state.clientId) &&
					(<Row>
						<label htmlFor={`client_id_${this.componentId}`}>client_id:</label>
						{isAuthorized
							? <code className="authorised"> ****** </code>
							: <Col tablet={10} desktop={10}>
								<input id={`client_id_${this.componentId}`}
									type="text"
									required={flow === PASSWORD}
									value={this.state.clientId}
									data-name="clientId"
									onChange={ this.onInputChange }/>
							</Col>
						}
					</Row>)
				}

				{(
					flow === APPLICATION ||
					flow === ACCESS_CODE ||
					(flow === PASSWORD && this.state.passwordType!== "basic")) &&
					(<Row>
						<label htmlFor={`client_secret_${this.componentId}`}>client_secret:</label>
						{isAuthorized
							? <code className="authorised"> ****** </code>
							: <Col tablet={10} desktop={10}>
								<PasswordInput
									id={`client_secret_${this.componentId}`}
									value={this.state.clientSecret}
									dataName="clientSecret"
									onChange={this.onInputChange}/>
							</Col>
						}
					</Row>)
				}

				{!isAuthorized && scopes && scopes.size
					? <div className="scopes">
						<h2>Scopes:</h2>
						{scopes.map ((description, name) => {
							return (
								<Row key={ name }>
									<div className="checkbox">
										<Input data-value={ name }
											id={`${name}-${flow}-checkbox-${this.state.name}`}
											type="checkbox"
											disabled={isAuthorized}
											onChange={this.onScopeChange}/>

										<label htmlFor={`${name}-${flow}-checkbox-${this.state.name}`}>
											<span className="item"/>
											<div className="text">
												<p className="name">{name}</p>
												<p className="description">{description}</p>
											</div>
										</label>
									</div>
								</Row>
							)
						}).toArray ()}
					</div> : null
				}

				{errors.valueSeq ().map ((error, key) => {
					return <AuthError error={error} key={key}/>
				})}

				<div className="auth-btn-wrapper">
					{isValid && (isAuthorized
						? <LoadableButton
							component={Button}
							label="LOGOUT"
							className="btn modal-btn auth authorize"
							loading={loading === 'revoke'}
							onClick={this.logout}/>
						: <Button
							className="btn modal-btn auth authorize"
							onClick={this.authorize}>AUTHORIZE</Button>
					)}
					{isAuthorized ? (
						<LoadableButton
							component={Button}
							label="REFRESH"
							loading={loading === 'refresh'}
							className="btn modal-btn auth btn-warn"
							onClick={this.refreshToken}/>
					) : (
						<Button
							className="btn modal-btn auth btn-done"
							onClick={this.close}>
							CLOSE
						</Button>
					)}
				</div>
			</div>
		);
	}
}


export default () => ({
	components: {
		oauth2: Oauth2
	}
})
