import React, { useCallback, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next'
import { Modal, Input, Label, Switch, Alert } from 'components/common';
import { UserPasswordInput, RoleSelect } from 'components/business-components';
import './formModal.scss';
// eslint-disable-next-line import/no-named-as-default
import toast from 'react-hot-toast';
import Service from 'service';
import { useFintech } from 'components/context/fintech-context';
import { showError } from 'utils';

const FormModal = ({ isOpen, onClose, initialData }) => {
  const { selectedFintech } = useFintech();
  const [isPasswordValid, setIsPasswordValid] = useState(false);
  const [data, setData] = useState({
    ...initialData,
    firstName: initialData?.name?.givenName?.replace('n/a', '') || '',
    active: initialData?.active || false,
    userName: initialData?.userName || '',
    email: initialData?.email || '',
    userType: initialData?.userType || ''
  })
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation()

  const setField = useCallback((field, value) => setData({ ...data, [field]: value }), [data]);
  const handleChange = useCallback((e) => setField(e.target.name, e.target.value), [setField]);
  const isValid = useMemo(() => {
    const result = data.firstName && data.userName && data.userType;
    if (initialData) {
      return result;
    } else {
      return result && isPasswordValid;
    }
  }, [data, initialData, isPasswordValid]);

  const submit = useCallback(async () => {
    if (!selectedFintech?.id) {
      return;
    }

    setLoading(true);

    try {

      const param = {
        ...data,
        emails: [{
          value: data.userName,
          primary: true
        }],
        externalId: data.userName,
        name: {
          givenName: data.firstName
        },
        roles: [
          {
            'type': '3scale_role',
            'value': data.userType
          }
        ],
        fintechId: selectedFintech?.id,
        schemas: [
          'urn:ietf:params:scim:schemas:core:2.0:User'
        ],
        organization: data.organization || 'Banfico LTD',
        userType: data.userType
      }
      initialData ? await Service.users.update(data.id, param) : await Service.users.create(param);
      setData({});
      onClose(true);

      toast.success(`${t('common.user')} ${t('common.addedSuccessfully')}`)
    } catch (err) {
      console.log(err);
      showError(err);
    }

    setLoading(false);
  }, [onClose, data]);

  return (
    <Modal
      isOpen={isOpen}
      title={
        initialData
          ? `${t('common.edit')} ${t('common.user')}`
          : `${t('common.add')} ${t('common.user')}`
      }
      onClose={() => {
        onClose(false);
        setData({});
      }}
      onCancel={() => {
        onClose(false);
        setData({});
      }}
      onConfirm={submit}
      confirmText={initialData ? t('common.saveChanges') : t('common.save')}
      confirmDisabled={!isValid}
      loading={loading}
    >
      <div className="formModal">
        {initialData?.isFintechOwner && (
          <Alert variant="warning" className="fintechOwnerAlert">
            <div className="fintechOwnerAlertContent">
              <i className="fa fa-star" />
              <span>
                <b>{t('pages.userManagement.adminUser')}</b> –{' '}
                {t('pages.userManagement.adminUserDescription')}{' '}
                {/* <b className="underline">
                  {t('pages.userManagement.adminUser')}
                </b> */}
                .
              </span>
            </div>
          </Alert>
        )}

        <Input
          label={t('pages.profile.fullName')}
          required
          name="firstName"
          onChange={handleChange}
          value={data?.firstName}
        />
        <Input
          label={t('pages.profile.email')}
          required
          name="userName"
          onChange={handleChange}
          value={data?.userName}
          disabled={initialData?.isFintechOwner}
        />
        <div className="permissions">
          <Label
            required
            infoTooltip={
              <>
                <div>
                  <b>{t('pages.userManagement.audit')}</b> -{' '}
                  {t('pages.userManagement.auditDescription')}
                </div>
                <div>
                  <b>{t('pages.userManagement.developer')}</b> -{' '}
                  {t('pages.userManagement.developerDescription')}
                </div>
                <div>
                  <b>{t('pages.userManagement.fintechAdmin')}</b> -{' '}
                  {t('pages.userManagement.fintechAdminDescription')}
                </div>
              </>
            }
          >
            {t('pages.userManagement.userPermissions')}
          </Label>
          <RoleSelect
            fullWidth
            onChange={(e) => setField('userType', e?.value)}
            isMulti={false}
            value={data?.userType}
            disabled={initialData?.isFintechOwner}
          />
        </div>
        {!initialData && (
          <UserPasswordInput
            label={t('pages.profile.temporaryPassword')}
            required
            name="password"
            onChange={handleChange}
            value={data?.password || ''}
            onValidChange={setIsPasswordValid}
          />
        )}
        <Label>{t('common.status')}</Label>
        <Switch
          checked={data?.active}
          onChange={(e) => setField('active', e.target.checked)}
          label={
            data?.active
              ? t('common.tableStatus.active')
              : t('common.tableStatus.inActive')
          }
          disabled={initialData?.isFintechOwner}
        />
      </div>
    </Modal>
  )
}

export default FormModal;