/* eslint-disable */

import jwt from 'jsonwebtoken';
import config from 'components/config';
import randomnum from 'components/lib/randomnum';

const {defaultScopes} = config;


function Win () {
	let win = {
		location: {},
		history: {},
		open: () => {},
		close: () => {},
		File: function() {}
	};

	if (typeof window === 'undefined') {
		return win;
	}

	try {
		win = window;

		const props = ['File', 'Blob', 'FormData'];

		for (let prop of props) {
			if (prop in window) {
				win [prop] = window [prop];
			}
		}
	} catch (e) {
		console.error ('* window props error:', e);
	}

	return win
}

const btoa = (str) => {
	let buffer

	if (str instanceof Buffer) {
		buffer = str
	} else {
		buffer = new Buffer(str.toString(), 'utf-8')
	}

	return buffer.toString('base64')
}

const win = Win ();

export default function authorize ({
	auth,
	configs,
	errActions,
	authActions,
	authConfigs = {}
}) {
	const {schema, scopes, name, clientId} = auth;
	const flow = schema.get ('flow');
	const query = [];

	switch (flow) {
		case 'password':
			return authActions.authorizePassword (auth);

		case 'application':
			return authActions.authorizeApplication (auth);

		case 'accessCode':
			query.push ('response_type=code');
			break;

		case 'implicit':
			query.push ('response_type=token');
			break;

		case 'clientCredentials':
			// OAS3
			return authActions.authorizeApplication (auth);

		case 'authorizationCode':
			// OAS3
			query.push ('response_type=code');
			break;
	}

	if (typeof clientId === 'string') {
		query.push ('client_id=' + encodeURIComponent(clientId))
	}

	const redirectUrl = configs.oauth2RedirectUrl

	// todo move to parser
	if (typeof redirectUrl === 'undefined') {
		return errActions.newAuthErr ({
			authId: name,
			source: 'validation',
			level: 'error',
			message: 'oauth2RedirectUrl configuration is not passed. Oauth2 authorization cannot be performed.'
		});
	}

	query.push ('redirect_uri=' + encodeURIComponent (redirectUrl));

	if (Array.isArray (scopes) && scopes.length) {
		const scopeSeparator = authConfigs.scopeSeparator || ' ';

		query.push ('scope=' + encodeURIComponent (
			[...defaultScopes, ...scopes].join (scopeSeparator)
		));
	}

	const state = randomnum (12);
	const nonce = randomnum (12);

	query.push ('state=' + encodeURIComponent (state));
	query.push ('nonce=' + encodeURIComponent (nonce));

	if (typeof authConfigs.realm !== 'undefined') {
		query.push ('realm=' + encodeURIComponent (authConfigs.realm))
	}

	const {additionalQueryStringParams} = authConfigs;

	for (let key in additionalQueryStringParams) {
		if (typeof additionalQueryStringParams [key] !== 'undefined') {
			query.push ([
				key,
				additionalQueryStringParams [key]
			].map (encodeURIComponent).join ('='));
		}
	}

	const authorizationUrl = schema.get ('authorizationUrl');

	const url = [
		authorizationUrl,
		query.join ('&')
	].join (
		authorizationUrl.indexOf ('?') === -1 ? '?' : '&'
	);

	let callback = null;

	if (flow === 'implicit') {
		callback = authActions.preAuthorizeImplicit;
	} else if (authConfigs.useBasicAuthenticationWithAccessCodeGrant) {
		callback = authActions.authorizeAccessCodeWithBasicAuthentication;
	} else {
		callback = authActions.authorizeAccessCodeWithFormParams;
	}

	win.swaggerUIRedirectOauth2 = {
		auth,
		state,
		nonce,
		callback,
		redirectUrl,
		errCb: errActions.newAuthErr
	};

	win.open (url);

	console.log ('* authorisation url:', url);
}