import React from 'react';

export default ({ error: e }) =>
  <span>
    {console.error('* error caught:', e)}
    {e && typeof e === 'object'
      ? JSON.stringify(
        e.message ||
        e.errors ||
        e.error ||
        e
      ) : (e || 'unknown error')
    }
  </span>