import React, { Component } from 'react'
import { ModalFooter } from 'reactstrap'

import config from 'components/config'
import request from 'components/lib/request'
import pubsub from 'components/lib/pubsub'

import ErrorMessage from 'components/lib/error'
import Modal from 'components/modal'
import Form from './form'
import jwt from 'jsonwebtoken'


const createClient = (fintechId, body) =>
  request({
    uri: `${config.apiBase}/resources/clients`,
    method: 'POST',
    body
  })


const getEndpoint = (body) =>
  request({
    uri: `${body.wellKnownEndpoint}`,
    method: 'GET'
  })


const createDynamicClient = (body) => {
  return request({
    uri: `${config.apiBase}/resources/dcrClients`,
    method: 'POST',
    body
  })
}

const CreateBtn = ({ loading, onClick }) => (
  <button className="button special" onClick={onClick} disabled={loading}>
    {loading ? <i className="fa fa-spin fa-circle-o-notch" /> : null}
    {' Create'}
  </button>
)

export default class CreateTPPClientModal extends Component {
  submitter;

  constructor(props) {
    super(props)

    this.state = {
      values: {}
    }

    this.onChange = this.onChange.bind(this)
    this.onCreate = this.onCreate.bind(this)
    this.onSubmit = this.onSubmit.bind(this)
  }

  onCreate(e) {
    e.preventDefault()

    const submitter = document.getElementById('submitter')

    if (submitter) {
      submitter.click()
    }
  }

  onSubmit(e) {
    if (e && e.preventDefault) {
      e.preventDefault()
    }

    this.setState({
      loading: true,
      error: null
    })

    const { appData } = this.props
    const { fintechId } = appData
    const { values } = this.state
    const aspspId = values.aspspId
    const { wellKnownEndpoint, softwareStatement, mtlsPrivateKey, mtlsCertificate, privateKey, keyId, tppRedirectUrl } = values

    const tppData = { aspspId, fintechId, product: appData.product }

    const decodedData = jwt.decode(softwareStatement)

    const orgId = decodedData.org_id;
    const softwareId = decodedData.software_id


    const dcrPayload = {
      grant_types: [
        'authorization_code',
        'implicit',
        'refresh_token',
        'client_credentials'
      ],
      jwks_uri: `https://keystore.sandbox.directory.openbankingbrasil.org.br/${orgId}/${softwareId}/application.jwks`,
      token_endpoint_auth_method: 'private_key_jwt',
      response_types: [
        'code id_token'
      ],
      redirect_uris: config.platformApiPispCallback
        ? config.platformApiPispCallback.split('|')
        : ['https://www.certification.openid.net/test/a/obbsb/callback'],
      software_statement: softwareStatement
    }

    const payload = { dcrPayload, wellKnownEndpoint }



    getEndpoint(payload)
      .then((data) => {
        payload.registrationEndpoint = data.mtls_endpoint_aliases.registration_endpoint
        createDynamicClient(payload)
          .then((res) => {
            if (res) {
              tppData.dcrDetails = res.data;
              tppData.settings = {
                clientId: res.data.client_id,
                mTLSPrivateKey: mtlsPrivateKey,
                mTLSCertificate: mtlsCertificate,
                keyId,
                privateKey,
                tppRedirectUrl
              }
              createClient(fintechId, tppData)
                .then(() => {
                  this.props.toggle()
                  pubsub.publish('clients:update')
                })
                .catch((e) => {
                  this.setState({
                    loading: false,
                    error: e.message || JSON.stringify(e)
                  })
                  console.error('* app client create error:', e)
                })
            }
          })
          .catch((e) => {
            this.setState({
              loading: false,
              error: e.message || JSON.stringify(e)
            })
          })
      })
  }

  onChange(update) {
    const { values } = this.state

    this.setState({
      values: { ...values, ...update },
      error: null
    })
  }

  render() {
    const { fintechId, ...props } = this.props
    const { loading, error } = this.state

    return (
      <Modal
        {...props}
        footer={
          <ModalFooter>
            <button disabled={loading} onClick={this.props.toggle}>
              Cancel
            </button>
            <CreateBtn loading={loading} onClick={this.onCreate} />
          </ModalFooter>
        }
      >
        <Form fintechId={fintechId} onChange={this.onChange} onSubmit={this.onSubmit} />

        {error ? (
          <p className="error">
            <ErrorMessage error={error} />
          </p>
        ) : null}
      </Modal>
    )
  }
}
