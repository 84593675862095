import React from 'react';
import './range-filter.scss';
import { useTranslation } from 'react-i18next';

export const rangeOptions = [7, 30, 90];

const RangeFilter = ({ value, onChange }) => {
  const { t } = useTranslation();

  return (
    <div className="range-filter">
      {rangeOptions.map((r) => (
        <p
          key={r}
          className={r === value ? 'active' : ''}
          id={r}
          onClick={() => onChange(r)}
        >
          {r} {t('pages.adminAnalyticsDetail.days')}
        </p>
      ))}
    </div>
  );
}

export default RangeFilter;