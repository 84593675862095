import React, { useState, useMemo } from 'react';

import { Page } from 'components/common';
import './profile.scss';
import Account from './account';
import Password from './password';
import { useTranslation } from 'react-i18next';

export default ({ user }) => {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState('account');

  const tabs = useMemo(() => [
    { label: t('pages.profile.accountSettings'), key: 'account' },
    { label: t('pages.profile.password'), key: 'password' }
  ], [t]);

  return (
    <Page
      title={t('pages.profile.title')}
      className="profile-page"
      wrapperClassName="profile-page-wrapper"
      subtitle={t('pages.profile.subtitle')}
    >
      <div className="sidebar">
        {tabs.map((tab) => (
          <div
            key={tab.key} 
            className={'item ' + (activeTab === tab.key ? ' active' : '')} 
            onClick={() => setActiveTab(tab.key)}
          >
            {tab.label}
          </div>
        ))}
      </div>
      <div className="tabContent">
        {activeTab === 'account' && <Account user={user} />}
        {activeTab === 'password' && <Password user={user} />}
      </div>
    </Page>
  )
}
