import * as React from 'react';
import './navbar.scss';
import { useTranslation } from 'react-i18next';

const NavBar = ({ value, onChange }) => {
  const { t } = useTranslation();
  const handleOnClick = (e) => {
    onChange(e.target.id)
  }
  return (
    <nav className="nav-wrapper">
      <ul className="nav-items-wrapper">
        <li
          id="response"
          className={value === 'response' ? 'active' : ''}
          onClick={handleOnClick}
        >
          {t('pages.adminAnalyticsDetail.nav.responseTime')}
        </li>
        <li
          id="uptime"
          className={value === 'uptime' ? 'active' : ''}
          onClick={handleOnClick}
        >
          {t('pages.adminAnalyticsDetail.nav.uptime')}
        </li>
        <li
          id="requests"
          className={value === 'requests' ? 'active' : ''}
          onClick={handleOnClick}
        >
          {t('pages.adminAnalyticsDetail.nav.apiRequests')}
        </li>
        <li
          id="users"
          className={value === 'users' ? 'active' : ''}
          onClick={handleOnClick}
        >
          {t('pages.adminAnalyticsDetail.nav.uniqueApiUsers')}
        </li>
        {/* <li
          id="reports"
          className={value === 'reports' ? 'active' : ''}
          onClick={handleOnClick}
        >
          {t('pages.adminAnalyticsDetail.nav.regulatoryReports')}
        </li> */}
      </ul>
    </nav>
  )
}

export default NavBar
