import React, { Component, useCallback, useEffect, useState } from 'react';

import Select from 'components/form/selector/select';

import {
  getASPSPs,
  aspsps2values
} from 'components/content/util';
import useIsMounted from 'components/hooks/use-is-mounted';

const Selector = (props) => {
  const isMounted = useIsMounted();
  const [data, setData] = useState([]);
  const [values, setValues] = useState({});
  const [error, setError] = useState(null);

  const onLoad = useCallback((allData) => {
    const {
      name,
      value: aspspId
    } = props;

    const value = allData.find(({ value }) => value === aspspId);

    setValues({
      ...values,
      [name]: value
    });
    
    if (value) {
      props.onChange({
        name,
        value,
        forms: value.forms
      });
    }
  }, [values, props.name, props.value, props.onChange]);

  const update = useCallback(() => {
    getASPSPs()
      .then((response) => {
        if (isMounted.current) {
          setData(aspsps2values(response.data));
          onLoad(response.data);
        }
      })
      .catch((error) => isMounted.current && setError(error));
  }, [onLoad]);

  useEffect(() => {
    update();
  }, [update]);

  return (
    <Select
      values={values}
      disabled={true}
      name={props.name}
      placeholder="Select ASPSP"
      options={data || []} />
  );
}

export default Selector;