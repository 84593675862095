import React, { useMemo, useState } from 'react';
import { Page } from 'components/common'
import isBrowser from 'components/lib/isbrowser'
import NavBar from './navbar';
import './admin-analytics-detail.scss'
// eslint-disable-next-line import/no-named-as-default

import ResponseTime from './graphs/response-time';
import Uptime from './graphs/uptime';
import Requests from './graphs/requests';
import Users from './graphs/users';
import Reports from './graphs/reports';
import { useTranslation } from 'react-i18next';

const AdminAnalyticsDetail = () => {
  const { t } = useTranslation();
  const locationSearch = isBrowser ? window?.location?.search : '';
  const [activeTab, setActiveTab] = useState('response');

  const [aspspId, aspspName] = useMemo(() => {
    const urlSearchParams = locationSearch ? new URLSearchParams(window.location.search) : ''
    const params = Object.fromEntries(urlSearchParams.entries())

    return [params.aspspId, params.name];
  }, [locationSearch]);

  return (
    <Page
      title={aspspName}
      wrapperClassName="api-analytics-wrapper"
      breadcrumb={[
        { label: t('nav.apiAnalytics'), url: '/user/analytics/' },
        { label: aspspName }
      ]}
    >
      <div className="metrics-wrapper">
        <NavBar value={activeTab} onChange={setActiveTab} />
        <div className="metrics-charts-wrapper">
          {activeTab === 'response' && <ResponseTime aspspId={aspspId} />}
          {activeTab === 'uptime' && <Uptime aspspId={aspspId} />}
          {activeTab === 'requests' && <Requests aspspId={aspspId} />}
          {activeTab === 'users' && <Users aspspId={aspspId} />}
          {activeTab === 'reports' && <Reports aspspId={aspspId} />}
        </div>
      </div>
    </Page>
  );
};

export default AdminAnalyticsDetail;