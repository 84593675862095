import React from 'react';
import './chart-box.scss';
import RangeFilter from '../range-filter';
import { Chart } from 'components/common'

const ChartBox = ({ rangeValue, onRangerangeValueChange, noData, options, title, noDataText, subContent }) => (
  <div className="chart-box">
    <div className="title-wrapper">
      <p className="title">{title}</p>
      {rangeValue && <RangeFilter value={rangeValue} onChange={onRangerangeValueChange} />}
    </div>
    {noData > 0 ? <p className="noData-text">{noDataText}</p> : <Chart options={options}/>}
    {noData > 0 ? null : (
      <div className="subContent">
        {subContent}
      </div>
    )}
  </div>
);

export default ChartBox;