/* eslint-disable max-lines */
import React, { useCallback, useState, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next'
import {
  Modal, Label, Tab, Button, Loader
} from 'components/common';
import { ProductSelect, AspspSelect } from 'components/business-components';
import './dynamicModal.scss';
import JsonForm from 'react-jsonschema-form';
import applyNavs from 'react-jsonschema-form-pagination';
import { GENERIC_NAV } from 'react-jsonschema-form-pagination/lib/utils';
import { getUser } from 'components/lib/session';
// eslint-disable-next-line import/no-named-as-default
import toast from 'react-hot-toast';
import { useFintech } from 'components/context/fintech-context';
import Service from 'service';
import useAspsp from 'components/data-hooks/aspsp';
import { showError } from 'utils';

function CustomAdvancedFormNavs({ navs: { links }, onNavChange }) {
  const activeName = useMemo(() => links.filter(({ nav }) => nav !== GENERIC_NAV).find((l) => l.isActive)?.nav, [links]);

  const tabs = useMemo(() => {
    const items = links.filter(({ nav }) => nav !== GENERIC_NAV);
    return items.map(({ nav, name }) => ({
      key: nav,
      // title: nav === DCR_FAKE_FORM ? name : entry.label,
      title: name,
      content: null
    }));
  }, [links])

  return (
    <Tab tabs={tabs} onChange={onNavChange} value={activeName} />
  );
}

const AdvancedJsonForm = applyNavs(JsonForm, CustomAdvancedFormNavs);

const DCR_FAKE_FORM = 'dcr';
const propagateNav = (schema, nav) => ({
  ...Object.keys(schema)
    .reduce((obj, field) => {
      obj[field] = {
        ...schema[field],
        nav
      };

      return obj;
    }, {}),
  nav // this will be ignored cause type==object but there for "doc"
});

const getFormData = (initialData) => {
  if (!initialData) {
    return {};
  }

  if (initialData.settings) {
    return {
      [initialData.aspspId]: JSON.parse(initialData.settings)
    }
  }

  return {}; // TODO return data according to DCR providers
}

const DynamicModal = ({ isOpen, onClose, initialData }) => {
  const { selectedFintech } = useFintech();
  const [product, setProduct] = useState();
  const [aspsp, setAspsp] = useState();
  const [loading, setLoading] = useState(false);
  const [formConfig, setFormConfig] = useState(null);
  const [formData, setFormData] = useState(getFormData(initialData));
  const registrations = useRef(null);
  const { aspspsMap } = useAspsp();
  const { t } = useTranslation()

  const clear = useCallback(() => {
    setProduct();
    setAspsp();
    setLoading();
    setFormConfig();
    setFormData();
    registrations.current = null;
  }, []);

  const onASPSPChange = useCallback((value) => {
    const dcrRegistrations = value || [];

    const dcrUiSchema = (dcrRegistrations.length > 0 ? {
      [DCR_FAKE_FORM]: propagateNav(dcrRegistrations[0].forms.register.uiSchema, DCR_FAKE_FORM)
    } : {});
    const navConf = {
      order: [
        ...(dcrRegistrations.length > 0 ? [DCR_FAKE_FORM] : [])],
      navs: [
        ...(dcrRegistrations.length > 0 ? [{
          nav: DCR_FAKE_FORM,
          name: `Dynamic Client Registration (${dcrRegistrations.length})`,
          entry: dcrRegistrations[0]
        }] : [])
      ]
    };
    const uiSchema = {
      navConf,
      ...dcrUiSchema
    };

    const dcrJsonSchema = (dcrRegistrations.length > 0 ? {
      [DCR_FAKE_FORM]: {
        ...dcrRegistrations[0].forms.register.jsonSchema,
        title: '',
        description: ''
      }
    } : {});
    const jsonSchema = {
      type: 'object',
      properties: {
        ...dcrJsonSchema
      }
    };

    setFormConfig({
      jsonSchema,
      uiSchema
    });
    registrations.current = {
      dcr: dcrRegistrations
    };

    // onChange(this.state.values));

    // ensure formData exists for that tab
    const newFormData = { ...formData };
    dcrRegistrations
      .filter((it) => !newFormData[it.value || it.id])
      .forEach((it) => newFormData[it.value || it.id] = {});

    setFormData(newFormData);
    setAspsp(value);
  }, [formData]);

  // eslint-disable-next-line max-statements
  const onSubmit = useCallback(async (data) => {
    setLoading(true);

    try {
      const { dcr } = registrations.current || {};

      if ((dcr || []).length == 0) {
        setLoading(false);
        return; // nothing to do
      }

      const fintechId = selectedFintech.id;
      // TBD: find another way to detect that
      let productType = initialData?.product || '';
      if (!productType) {
        if (product.label.includes('AISP')) {
          productType = 'AISP'
        } else if (product.label.includes('PISP')) {
          productType = 'PISP'
        }
      }
      const userId = getUser();

      const headers = {
        'accept': 'application/json',
        'content-type': 'application/json',
        'x-user-id': userId,
        'fintechId': fintechId
      };

      let body = null;
      if (initialData) {
        body = {
          items: (dcr || []).map((it) => ({
            method: 'PUT',
            relativeUri: `/api/providers/${product == 'AISP' ? 'accounts' : 'payments'}/${encodeURIComponent(it.value || it.id)}/dcr/update/${encodeURIComponent(dcr.id)}?aspsp=${encodeURIComponent(it.value || it.id)}`,
            headers,
            payload: data[DCR_FAKE_FORM],
            aspspId: it.value || it.id // Just for showing error
          }))
        };

      } else {
        body = {
          items: (dcr || []).map((it) => ({
            method: 'POST',
            relativeUri: `/api/providers/${product == 'AISP' ? 'accounts' : 'payments'}/${encodeURIComponent(it.value || it.id)}/dcr/register?aspsp=${encodeURIComponent(it.value || it.id)}`,
            headers,
            payload: (data.formData || data)[DCR_FAKE_FORM],
            aspspId: it.value || it.id // Just for showing error
          }))
        };
      }

      const bulkName = initialData ? 'devportal_tpp-clients_edit' : 'devportal_tpp-clients_create';
      const response = await Service.multi.bulk(bulkName, body);

      const failedItems = response.items.filter((r) => r.status !== 200);
      if (failedItems.length > 0) {
        toast.error(t(
              'pages.adminClientManagement.dcrRegistrationError'
            ));

        failedItems.forEach((r, i) => {
          const payload = JSON.parse(r.payload);
          const aspspId = body.items[i].aspspId;
          const name = aspspsMap[aspspId]?.name;
          toast.error(`${name}: ${payload.message}`);
        });
      } else {
        clear();
        onClose(true);
        toast.success(t('common.addedSuccessfully'))
      }
    } catch (err) {
      console.log(err);
      showError(err);
      console.error('* app client create error:', err);
    }

    setLoading(false);
  }, [product?.label, initialData, selectedFintech, clear, aspspsMap]);

  const showForm = useMemo(() => formConfig && formConfig.uiSchema.navConf.order.length > 0, [formConfig]);

  return (
    <Modal
      isOpen={isOpen}
      title={
        initialData
          ? `${t('common.edit')} ${t(
              'pages.adminClientManagement.title'
            )}`
          : `${t('common.add')} ${t(
              'pages.adminClientManagement.title'
            )}`
      }
      onClose={() => onClose(false)}
      onCancel={showForm ? null : () => onClose(false)}
      className="dynamicModal"
    >
      {!initialData && (
        <div className="control">
          <Label required>Product</Label>
          <ProductSelect
            value={product}
            onChange={setProduct}
            disabled={Boolean(initialData)}
          />
        </div>
      )}
      <div className="control">
        <Label required>ASPSP</Label>
        <AspspSelect
          value={aspsp}
          onChange={onASPSPChange}
          disabled={Boolean(initialData)}
          isMulti
          defaultAspspId={initialData?.aspspId}
        />
      </div>

      {showForm && (
        <div className="jsonForm">
          <AdvancedJsonForm
            formData={formData}
            onChange={({ formData: newFormData }) => setFormData(newFormData)}
            schema={formConfig.jsonSchema}
            uiSchema={formConfig.uiSchema}
            onSubmit={onSubmit}
          >
            <div className="footer formActions">
              {loading && <Loader />}
              <Button
                onClick={() => onClose(false)}
                variant="secondary"
                disabled={loading}
              >
                Cancel
              </Button>
              <Button type="submit" variant="primary" disabled={loading}>
                Save
              </Button>
            </div>
          </AdvancedJsonForm>
        </div>
      )}
    </Modal>
  )
}
export default DynamicModal;