
import React, { useMemo, useState } from 'react';
import { ModalFooter } from 'reactstrap';

import config from 'components/config';
import request from 'components/lib/request';
import pubsub from 'components/lib/pubsub';

import Modal from 'components/modal';

const { apiBase } = config;

const deleteApplication = (id) =>
  request({
    uri: `${apiBase}/resources/clients/${id}`,
    method: 'DELETE'
  });


const DeleteBtn = ({ loading, onClick }) =>
  <button
    className="has-text-danger"
    onClick={onClick}
    disabled={loading}>
    {loading ? (
      <i className="fa fa-spin fa-circle-o-notch" />
    ) : null}
    {' Delete'}
  </button>

const DeleteTPPClientModal = (props) => {
  const { data, ...restProps } = props;
  const [loading, setLoading] = useState(false);

  const deleteApp = useCallback((e) => {
    setLoading(true);

    const { id } = data;

    if (!id) {
      return;
    }

    deleteApplication(id)
      .then(() => {
        props.toggle();
        pubsub.publish('clients:update');
      })
      .catch((e) => {
        setLoading(false);
        console.error('* app client delete error:', e);
      });

    e.preventDefault();
  }, [data, props.toggle]);

  const settings = useMemo(() => JSON.parse(data.settings || '{}'), [data.settings]);

  return (
    <Modal {...restProps} footer={
      <ModalFooter>
        <button
          disabled={loading}
          onClick={restProps.toggle}>
          Cancel
        </button>
        <DeleteBtn
          loading={loading}
          onClick={deleteApp} />
      </ModalFooter>
    }>
      <p>Are you sure you want to delete <strong>{
        settings?.clientId
      }</strong>?</p>
    </Modal>
  );
};

export default DeleteTPPClientModal;