import React, { useCallback, useMemo, useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next'
import './dcr-info.scss';
import { useFintech } from 'components/context/fintech-context';
import { getUser } from 'components/lib/session';
import { showError } from 'utils';
import { Button, Label, Tab, Loader } from 'components/common';
import { AspspSelect, ProductSelect, PispAspspSelect } from 'components/business-components';
import applyNavs from 'react-jsonschema-form-pagination';
import JsonForm from 'react-jsonschema-form';
import { GENERIC_NAV } from 'react-jsonschema-form-pagination/lib/utils';
import Service from 'service';

function CustomAdvancedFormNavs({ navs: { links }, onNavChange }) {
  const activeName = useMemo(() => links.filter(({ nav }) => nav !== GENERIC_NAV).find((l) => l.isActive)?.nav, [links]);

  const tabs = useMemo(() => {
    const items = links.filter(({ nav }) => nav !== GENERIC_NAV);
    return items.map(({ nav, name }) => ({
      key: nav,
      title: name,
      content: null
    }));
  }, [links])

  return (
    <Tab tabs={tabs} onChange={onNavChange} value={activeName} />
  );
}
const AdvancedJsonForm = applyNavs(JsonForm, CustomAdvancedFormNavs);

const DcrInfo = (props) => {
  const { onNext, clientId, aspsp, handleAspspChange, dcrInfoRef, client, loading, setLoading } = props;
  const [product, setProduct] = useState();
  const [formConfig, setFormConfig] = useState(null);
  const { selectedFintech } = useFintech();
  const [formData, setFormData] = useState(null);
  const refData = useRef({ aspsp });
  const { t } = useTranslation();

  const showForm = useMemo(() => formConfig && formConfig.uiSchema, [formConfig]);

  const onAspspChange = useCallback((value) => {
    const dcrRegistrations = value || [];
    if (dcrRegistrations.length === 0) {
      setFormConfig();
      setFormData();
      handleAspspChange(null);
      return;
    }

    const uiSchema = {
      dcr: dcrRegistrations[0].forms.register.uiSchema || {}
    };

    const dcrJsonSchema = (dcrRegistrations.length > 0 ? {
      dcr: {
        ...dcrRegistrations[0].forms.register.jsonSchema,
        title: '',
        description: ''
      }
    } : {});
    const jsonSchema = {
      type: 'object',
      properties: {
        ...dcrJsonSchema
      }
    };

    setFormConfig({
      jsonSchema,
      uiSchema
    });

    handleAspspChange(value);
    refData.current = {
      ...refData.current,
      aspsp: value
    }
  }, [formData]);

  // eslint-disable-next-line max-statements
  const onSubmit = async (formDataValues, selectedProduct) => {
    if (formDataValues && selectedProduct) {
      try {
        setLoading(true)
        const selectedAspsps = refData.current.aspsp;
        if (selectedAspsps?.length == 0) {
          return; // nothing to do
        }

        const fintechId = selectedFintech.id;
        let productType = selectedProduct.value;
        const userId = getUser();

        const headers = {
          'accept': 'application/json',
          'content-type': 'application/json',
          'x-user-id': userId,
          'fintechId': fintechId
        };

        const { data: clients } = await Service.clients.list(fintechId);
        let body = {
          items: (selectedAspsps).map((a) => {
            const clientOfAspsp = clients.find((c) => c.aspspId === a.id && c.product === productType);

            if (clientOfAspsp) {
              return {
                method: 'PUT',
                relativeUri: `/api/providers${productType == 'AISP' ? '' : '/payments'}/${encodeURIComponent(a.id)}/dcr/update/${encodeURIComponent(clientOfAspsp.id)}`,
                headers,
                payload: {
                  clientId: clientOfAspsp.id,
                  aspspId: a.id,
                  product: productType,
                  fintechId,
                  // settings: (data.formData || data).dcr
                  ...formDataValues?.dcr
                },
                aspspId: a.id // Just for showing error
              };
            } else {
              return {
                method: 'POST',
                relativeUri: `/api/providers/${productType == 'AISP' ? 'accounts' : 'payments'}/${encodeURIComponent(a.id)}/dcr/register`,
                headers,
                payload: {
                  aspspId: a.id,
                  product: productType,
                  fintechId,
                  // settings: (data.formData || data).dcr,
                  ...formDataValues?.dcr,
                  dcrDetails: ''
                },
                aspspId: a.id // Just for showing error
              }
            }
          })
        };

        const bulkName = clientId ? 'devportal_tpp-clients_edit' : 'devportal_tpp-clients_create';
        onNext(bulkName, body);
      } catch (err) {
        setLoading(false)
        console.log(err);
        showError(err);
        console.error('* app client create error:', err);
      }
    }
  };

  const clear = useCallback(() => {
    setProduct();
    setFormConfig();
    setFormData();
  }, []);

  useEffect(() => {
    dcrInfoRef.current = {
      onAspspChange,
      clear
    }
  }, [onAspspChange, clear]);

  useEffect(() => {
    if (client) {
      const dcr = JSON.parse(client.settings);
      setFormData({ dcr });

      setProduct({ value: client.product })
    }

    return {};
  }, [client])

  return (
    <div className="dcr-info">
      {/* <Debug data={formData} /> */}
      <div className="control">
        <Label required>{t('common.product')}</Label>
        <ProductSelect
          value={product}
          onChange={setProduct}
          initialValueLabel={client?.product}
        />
      </div>
      <div className="control">
        <Label required>{t('pages.adminClientManagement.aspsp')}(s)</Label>
        {product?.value == 'AISP' ? (
          <AspspSelect
            value={aspsp}
            onChange={onAspspChange}
            isMulti
            defaultAspspId={client?.aspspId}
          />
        ): (
          <PispAspspSelect
            value={aspsp}
            onChange={onAspspChange}
            isMulti
            defaultAspspId={client?.aspspId}
          />
        )}
      </div>
      {showForm && (
        <>
          <div className="divider" />
          <div className="jsonForm">
            <AdvancedJsonForm
              formData={formData}
              onChange={({ formData: newFormData }) => setFormData(newFormData)}
              schema={formConfig.jsonSchema}
              uiSchema={formConfig.uiSchema}
            // onSubmit={onSubmit}
            />
            <div className="footer formActions">
              {loading && <Loader />}
              <Button disabled={loading || (!formData?.dcr?.mTLSPrivateKey || !formData?.dcr?.mTLSCertificate || !formData?.dcr?.keyId || !formData?.dcr?.privateKey || !formData?.dcr?.softwareStatement)} onClick={() => onSubmit(formData, product)}>
                {t('pages.adminClientManagement.runDcr')}
              </Button>
            </div>
          </div>
        </>
      )}
    </div>
  )
};

export default DcrInfo;