import config from 'components/config';
import buildFormData from 'components/lib/formparams';

const {
  defaultScopes
} = config;

export default ({ name }) => (system) => {
  const {
    authorized
  } = system.auth().toJS();
  const auth = authorized[name];

  const {
    token,
    scopes,
    schema,
    clientId,
    clientSecret
  } = auth;

  const form = {
    client_id: clientId,
    client_secret: clientSecret,
    grant_type: 'refresh_token',
    refresh_token: token.refresh_token,
    scope: defaultScopes.join(' ') + `${Array.isArray(scopes) && scopes.length
        ? ` ${scopes.join(' ')}` : ''
      }`
  };

  return system
    .authActions
    .authorizeRequest({
      name,
      auth,
      body: buildFormData(form),
      url: schema.tokenUrl
    });
}