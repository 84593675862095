import React, { useCallback, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next'
import { Modal, Input, Label, Switch, IconButton } from 'components/common';
import './fintech-form-modal.scss';
// eslint-disable-next-line import/no-named-as-default
import toast from 'react-hot-toast';
import Service from 'service';
import { isValidWebUrl, validateEmail } from 'components/common/helpers'
import { useFintech } from 'components/context/fintech-context';
import { showError } from 'utils';
import { UserPasswordInput } from 'components/business-components';

const FormModal = ({ isOpen, onClose, initialData }) => {
  const { reloadFintechList } = useFintech();
  const [data, setData] = useState(initialData || {enableAccountsDataStorage: false, status: 'INACTIVE'});
  const [isPasswordValid, setIsPasswordValid] = useState(false);
  const [adminUser, setAdminUser] = useState(initialData
    ? {
      adminEmail: initialData.adminEmail,
      adminFullName: initialData.adminFullName,
      adminJobTitle: initialData.adminJobTitle
    }
    : {}
  );
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation()

  const setDataField = useCallback((field, value) => setData({ ...data, [field]: value }), [data]);
  const handleDataChange = useCallback((e) => setDataField(e.target.name, e.target.value), [setDataField]);

  const setAdminUserField = useCallback((field, value) => setAdminUser({ ...adminUser, [field]: value }), [adminUser]);
  const handleAdminUserChange = useCallback((e) => setAdminUserField(e.target.name, e.target.value), [setAdminUserField]);

  const isValid = useMemo(() => {
    if (initialData) {
      return data.name && data.website
    } else {
      return data.name &&
        data.website &&
        adminUser.adminFullName &&
        adminUser.adminEmail &&
        validateEmail(adminUser.adminEmail) &&
        isValidWebUrl(data.logoUrl) &&
        isPasswordValid
    }

  }, [data, adminUser, isPasswordValid]);

  const submit = useCallback(async () => {
    setLoading(true);

    try {
      if (initialData) {
        await Service.tpps.update(data.id, data);

        toast.success(`${t('common.fintech')} ${t('common.updatedSuccessfully')}`)
      } else {
        const fintechData = {
          ...data,
          adminEmail: adminUser.adminEmail,
          adminFullName: adminUser.adminFullName,
          adminJobTitle: adminUser.adminJobTitle
        };
        const response = await Service.tpps.create(fintechData);
        
        const param = {
          userName: adminUser.adminEmail,
          emails: [
            {
              value: adminUser.adminEmail,
              primary: true
            }
          ],
          externalId: adminUser.adminEmail,
          name: {
            givenName: adminUser.adminFullName,
            familyName: ''
          },
          roles: [
            {
              'type': '3scale_role',
              'value': 'fintech-admin'
            }
          ],

          fintechId: response?.data?.id,
          schemas: ['urn:ietf:params:scim:schemas:core:2.0:User'],
          isFintechOwner: true,
          organization: 'Banfico LTD',
          password: adminUser.password,
          userType: 'fintech-admin'
        }

        try {
          await Service.users.create(param)
        } catch (userErr) {
          if (userErr.message !== t('common.errorMessage.internalError')) {
            throw userErr
          }
        }

        toast.success(`${t('common.fintech')} ${t('common.addedSuccessfully')}`)

        setData({});
        setAdminUser({});
      }

      reloadFintechList();
      onClose(true);
    } catch (err) {
      console.log(err);
      showError(err);
    }

    setLoading(false);
  }, [onClose, data, adminUser]);

  return (
    <Modal
      isOpen={isOpen}
      title={
        initialData
          ? `${t('common.edit')} ${t('common.fintech')}`
          : `${t('common.add')} ${t('common.fintech')}`
      }
      onClose={() => {
        onClose(false);
        setData({});
        setAdminUser({});
      }}
      onCancel={() => {
        onClose(false);
        setData({});
        setAdminUser({});
      }}
      onConfirm={submit}
      confirmText={initialData ? t('common.edit') : t('common.save')}
      confirmDisabled={!isValid}
      loading={loading}
    >
      <div className="fintech-form-modal">
        <Input
          label={t('pages.profile.organizationName')}
          required
          name="name"
          onChange={handleDataChange}
          value={data?.name || ''}
        />
        <Input
          label={t('common.website')}
          required
          name="website"
          onChange={handleDataChange}
          value={data?.website || ''}
        />
        <Input
          label={t('pages.profile.organizationLogo')}
          placeholder="eg:https://via.placeholder.com/300"
          required
          name="logoUrl"
          onChange={handleDataChange}
          value={data?.logoUrl || ''}
        />
        <div className="toggleBtn">
          <Label>{t('pages.profile.organizationStatus')}</Label>
          <Switch
            checked={data?.status=='ACTIVE'}
            onChange={(e) => setDataField('status', e.target.checked?'ACTIVE':'INACTIVE')}
          />
        </div>
         <div className="toggleBtn">
          <Label>{t('pages.fintechManagement.enableAccountsDataStorage')}</Label>
          <Switch
            checked={data?.enableAccountsDataStorage==true}
            onChange={(e) => setDataField('enableAccountsDataStorage', !!e.target.checked)}
          />
        </div>
        <>
          <div className="divider" />
          <div className="title">
            {t('pages.fintechManagement.addAdminUser')}
          </div>
          <Input
            label={t('pages.profile.fullName')}
            required
            name="adminFullName"
            onChange={handleAdminUserChange}
            value={adminUser?.adminFullName || ''}
            disabled={initialData}
          />
          <Input
            label={t('pages.profile.jobTitle')}
            name="adminJobTitle"
            onChange={handleAdminUserChange}
            value={adminUser?.adminJobTitle || ''}
            disabled={initialData}
          />
          <Input
            label={t('pages.profile.email')}
            required
            name="adminEmail"
            onChange={handleAdminUserChange}
            value={adminUser?.adminEmail || ''}
            disabled={initialData}
          />
          <Input
            label={t('pages.profile.phone')}
            name="adminPhone"
            onChange={handleAdminUserChange}
            value={adminUser?.adminPhone || ''}
            disabled={initialData}
          />
          <UserPasswordInput
            label={t('pages.profile.temporaryPassword')}
            required
            name="password"
            onChange={handleAdminUserChange}
            value={adminUser?.password}
            disabled={initialData}
            onValidChange={setIsPasswordValid}
          />
        </>
      </div>
    </Modal>
  )
}

export default FormModal;