/* eslint-disable max-lines */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import './filter.scss';
import debounce from 'lodash.debounce';
import { Input, DatePicker, Select, Button } from 'components/common';
import { AspspSelect } from 'components/business-components';
import { Row, Col } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import { DateRangePicker } from 'react-dates';

const Filter = ({ value, onChange, onClear, user, setPage }) => {
  const { t } = useTranslation();
  const [aspspValue, setAspspValue] = useState(null);
  const [searchby, setSearchby] = useState();
  const [searchText, setSearchText] = useState();
  const [status, setStatus] = useState();

  const [startDate, setStartDate] = useState(value.range?.[0]);
  const [endDate, setEndDate] = useState(value.range?.[1]);
  const [focusedInput, setFocusedInput] = useState(null);

  const handleChange = useCallback((fieldValue, field) => {
    onChange({
      ...value,
      [field]: fieldValue
    });
  }, [onChange, value]);

  const statusOptions = useMemo(() => [
    { value: 'BOOKED', label: t('pages.paymentHistory.filter.status.booked') },
    { value: 'PENDING', label: t('pages.paymentHistory.filter.status.pending') },
    { value: 'REJECTED', label: t('pages.paymentHistory.filter.status.rejected') }
  ], []);

  const searchOptions = useMemo(() => [
    // { value: 'clientId', label: t('pages.paymentHistory.clientId') },
    { value: 'consentId', label: t('pages.paymentHistory.consentId') },
    { value: 'paymentId', label: t('pages.paymentHistory.paymentId') },
    { value: 'debtorCpfCnpj', label: t('pages.paymentHistory.debtorCpfCnpj') },
    { value: 'creditorCpfCnpj', label: t('pages.paymentHistory.creditorCpfCnpj') },
    { value: 'amount', label: t('pages.paymentHistory.amount') },
    { value: 'transactionIdentification', label: t('pages.paymentHistory.transactionIdentification') }
  ], [])

  const searchValue = useMemo(() => searchOptions.find((o) => o.value === searchby), [searchby, searchOptions]);

  const handleChangeWithDebounce = useCallback(debounce(handleChange, 200), [handleChange]);

  const onSubmit = (e) => {
    e.preventDefault();
    const filterValues = {
      ...(searchby && {[searchby?.value]: searchText}),
      ...(startDate!==null && { range: [startDate, endDate] }),
      ...(status && { status: status?.value}),
      ...(aspspValue && { aspspId: aspspValue?.id})
    };
    setPage(0);
    onChange(filterValues)
  }

  const formatString = (text) => {
    return text.replace(/\w\S*/g, () => {
      return text.charAt(0).toUpperCase() + text.substr(1).toLowerCase();
    })
  }

  const onDatesChange = (startDate, endDate) => {
    setStartDate(startDate)
    setEndDate(endDate)
    setFocusedInput(false)
  }

  const onClearBtnClick = (e) => {
    e.preventDefault();
    onClear();
    setStatus(null);
    setAspspValue(null);
    setSearchby(null);
    setSearchText('');
    setStartDate();
    setEndDate();
  }

  useEffect(() => {
    setStartDate(value.range?.[0])
    setEndDate(value.range?.[1])
    const statusvalue = statusOptions.find((obj) => obj.value == value.status)
    setStatus(statusvalue ? statusvalue : null)
  }, [value])

  return (
    <div className="filter">
      {user?.userType == 'super-admin' ? (
        <>
        <Row className="payment-history-filter">
          <Col xl={8} lg={12} md={12} sm={12}>
            <div>
              <Input
                fullWidth
                // value={filterValues.searchby}
                // onChange={(e) => handleChange(e.target.value, searchby)}
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
                className="input"
                startAdornment={
                  <Select
                    options={searchOptions}
                    fullWidth
                    noBorder
                    className="start-select"
                    // value={search}
                    // onChange={(v) => setSearchby(v?.value)}
                    value={searchby}
                    onChange={setSearchby}
                    placeholder={t('pages.paymentHistory.searchByPlaceholder')}
                  />
                }
              />
            </div>
          </Col>
          <Col xl={4} lg={4} md={6} sm={12}>
              <AspspSelect
                fullWidth
                className="aspsp-select"
                // value={aspspValue}
                // onChange={(a) => {
                //   handleChange(a.id, 'aspspId');
                //   setAspspValue(a);
                // }}
                value={aspspValue}
                onChange={setAspspValue}
                // eslint-disable-next-line no-sequences
                placeholder={t('pages.paymentHistory.debtorBank')}
              />
          </Col>
        </Row>
        
        <Row>
          <Col xl={4} lg={4} md={6} sm={12} className="filter-field-first">
            <Select
              options={statusOptions}
              fullWidth
              // value={status}
              // onChange={(v) => {
              //   handleChange(v?.value, 'status');
              //   setStatus(v?.value)
              // }}
              value={status}
              onChange={setStatus}
              placeholder={t('pages.paymentHistory.status')}
            />
          </Col>
          <Col xl={4} lg={4} md={6} sm={12}>
            <DateRangePicker
                startDate={startDate}
                startDateId="startDate"
                endDate={endDate}
                endDateId="endDate"
                onDatesChange={({ startDate, endDate}) => {
                  onDatesChange(startDate, endDate)
                }}
                focusedInput={focusedInput}
                onFocusChange={(focusedInput) => {
                  setFocusedInput(focusedInput)
                }}
                showClearDates
                reopenPickerOnClearDates
                showDefaultInputIcon
                inputIconPosition="after"
                startDatePlaceholderText={'Start date'}
                endDatePlaceholderText={'End date'}
                isOutsideRange={() => false}
                anchorDirection="right"
                daySize={30}
                small
                calendarInfoPosition="before"
                firstDayOfWeek={1}
                displayFormat="YYYY-MM-DD"
                readOnly
                verticalSpacing={16}
            />
          </Col>
          <Col xl={1} lg={4} md={6} sm={12}>
            <Button variant="small" className="button" onClick={(e) => {
              onClearBtnClick(e)
            }}>
              {t('common.clearAll')}
            </Button>
           </Col>
          <Col xl={3} lg={4} md={6} sm={12}>
              <Button variant="primary" fullWidth onClick={(e) => {
                onSubmit(e)
              }}>
              {t('common.search')}
              </Button>
          </Col>
        </Row>
        </>
      ) : (
      <>
        <Row className="payment-history-filter">
          <Col xl={12} lg={12} md={12} sm={12}>
              <div>
                <Input
                  fullWidth
                  // value={filterValues.searchby}
                  // onChange={(e) => handleChange(e.target.value, searchby)}
                  value={searchText}
                  onChange={(e) => setSearchText(e.target.value)}
                  className="input"
                  startAdornment={
                    <Select
                      options={searchOptions}
                      fullWidth
                      noBorder
                      className="select"
                      // value={search}
                      // onChange={(v) => setSearchby(v?.value)}
                      value={searchby}
                      onChange={setSearchby}
                      placeholder={t('pages.paymentHistory.searchByPlaceholder')}
                    />
                  }
                />
              </div>
          </Col>
        </Row>
        <Row>
          <Col xl={3} lg={4} md={6} sm={12} className="filter-field-first">
            <AspspSelect
              // value={aspspValue}
              // onChange={(a) => {
              //   handleChange(a.id, 'aspspId');
              //   setAspspValue(a);
              // }}
              value={aspspValue}
              onChange={setAspspValue}
              // eslint-disable-next-line no-sequences
              placeholder={t('pages.paymentHistory.debtorBank')}
            />
          </Col>
          <Col xl={3} lg={4} md={6} sm={12} className="filter-field-first">
            <Select
              options={statusOptions}
              fullWidth
              // value={status}
              // onChange={(v) => {
              //   handleChange(v?.value, 'status');
              //   setStatus(v?.value)
              // }}
              value={status}
              onChange={setStatus}
              placeholder={t('pages.paymentHistory.status')}
            />
          </Col>
          <Col xl={3} lg={4} md={6} sm={12}>
            <DateRangePicker
              startDate={startDate}
              startDateId="startDate"
              endDate={endDate}
              endDateId="endDate"
              onDatesChange={({ startDate, endDate}) => {
                onDatesChange(startDate, endDate)
              }}
              focusedInput={focusedInput}
              onFocusChange={(focusedInput) => {
                setFocusedInput(focusedInput)
              }}
              showClearDates
              reopenPickerOnClearDates
              showDefaultInputIcon
              inputIconPosition="after"
              startDatePlaceholderText={'Start date'}
              endDatePlaceholderText={'End date'}
              isOutsideRange={() => false}
              anchorDirection="right"
              daySize={30}
              small
              calendarInfoPosition="before"
              firstDayOfWeek={1}
              displayFormat="YYYY-MM-DD"
              readOnly
              verticalSpacing={16}
            />
          </Col>
          <Col xl={1} lg={4} md={6} sm={12}>
              <Button variant="small" className="button" onClick={(e) => {
                onClearBtnClick(e)
              }}>
                {t('common.clearAll')}
              </Button>
          </Col>
          <Col xl={2} lg={4} md={6} sm={12} className="filter-field-last">
              <Button variant="primary" fullWidth onClick={(e) => {
                onSubmit(e)
              }}>
              {t('common.search')}
              </Button>
          </Col>
        </Row>
      </>
      )}
      
    </div>
  );
};

export default Filter;