/* eslint-disable complexity */
/* eslint-disable no-nested-ternary */
/* eslint-disable quotes */
import React from 'react';
import './stack.scss';
import Amount from 'components/lib/amount';
import { useTranslation } from 'react-i18next';
import useAspsp from 'components/data-hooks/aspsp'
import { Chip, Image, Alert } from 'components/common';
import { format } from 'date-fns'
import moment from 'moment';

const StackComponent = (props) => {
  const { t } = useTranslation();
  const { aspspsMap } = useAspsp()

  const {loading, data, paymentId, aspspId, status, debtorCpf, creditorCpf, client, rejectionReason, cancellationReason, remittanceInformation} = props;

  const aspsp = aspspsMap[aspspId]

  if (loading) {
    return (
      <div align="center">
          {t('common.loading')}...
      </div>
    );
  }

  const getRgb = () => {
    return Math.floor(Math.random()*256)
  }

  const rgbToHex = (r, g, b) => {
    return '#' + [r, g, b].map((x) => {
      const hex = x.toString(16);
      return hex.length === 1 ? '0' + hex : hex
    }).join('')
  }

  const handleGenerate = () => {
    const color = {
      r: getRgb(),
      g: getRgb(),
      b: getRgb()
    }
    return (rgbToHex(color.r, color.g, color.b))
  }

  const StatusChip = ({status}) => {
    if (status == 'Booked') {
      return <Chip variant="success" className="paymentStatus" label={t('pages.paymentHistory.filter.status.booked')}/>
    } else if (status == 'Rejected') {
      return <Chip variant="error" className="paymentStatus" label={t('pages.paymentHistory.filter.status.rejected')}/>
    } else if (status == 'Pending') {
      return <Chip variant="pending" className="paymentStatus" label={t('pages.paymentHistory.filter.status.pending')}></Chip>
    } else if (status == 'Future') {
      return <Chip variant="future" className="paymentStatus" label={t('pages.paymentHistory.filter.status.future')}></Chip>
    } else {
      return <Chip variant="default" className="paymentStatus" label={t('pages.paymentHistory.filter.status.cancelled')}></Chip>
    }
  }

  const PaymentStatusChip = ({status, statusType}) => {

    let str1 = statusType.toLowerCase();
    let str2 = statusType.charAt(0).toUpperCase();
    let formattedStr = str2 + str1.slice(1)

    if (status == 'AUTHORISED') {
      return <Chip variant="success" className="status" label={`${formattedStr} - ${t('pages.paymentHistory.consentStatus.AUTHORISED')}`}/>
    } else if (status == 'AWAITING_AUTHORISATION') {
      return <Chip variant="warning" className="status" label={`${formattedStr} - ${t('pages.paymentHistory.consentStatus.AWAITING_AUTHORISATION')}`}/>
    } else if (status == 'SCHD') {
      return <Chip variant="future" className="status" label={`${formattedStr} - ${t('pages.paymentHistory.consentStatus.FUTURE')}`}/>
    } else if (status == 'RCVD') {
      return <Chip variant="warning" className="status" label={`${formattedStr} - ${t('pages.paymentHistory.consentStatus.RECEIVED')}`}/>
    } else if (status == 'CANC') {
      return <Chip variant="default" className="status" label={`${formattedStr} - ${t('pages.paymentHistory.consentStatus.CANCELLED')}`}/>
    } else if (status == 'RJCT') {
      return <Chip variant="error" className="status" label={`${formattedStr} - ${t('pages.paymentHistory.consentStatus.REJECTED')}`}/>
    } else if (status == 'ACSC') {
      return <Chip variant="success" className="status" label={`${formattedStr} - ${t('pages.paymentHistory.consentStatus.BOOKED')}`}/>
    } else {
      return <Chip variant="default" className="status" label={`${formattedStr} - ${t('pages.paymentHistory.consentStatus.CONSUMED')}`}/>
    }
  }

  const getFutureDates = (date, quantity, type) => {
    if (type == 'daily') {
      const formattedDate = moment(date, 'YYYY-MM-DD').add(quantity, 'days')
      return formattedDate.format('YYYY-MM-DD')
    } else if (type == 'monthly') {
      const formattedDate = moment(date, 'YYYY-MM-DD').add(quantity, 'months')
      return formattedDate.format('YYYY-MM-DD')
    } else if (type =='weekly') {
      const formattedDate = moment(date, 'YYYY-MM-DD').add(quantity, 'weeks')
      return formattedDate.format('YYYY-MM-DD')
    }
  }

  return (
    data?.length
    ? <div className="obaf-stack-wrapper">
      {data.length &&  (
        <div className="obaf-stack-right-container">
          <table>
            <div className="right-subtitle"></div>
            <tr>
              <td className="right-row-data">
                <h5>{t('pages.paymentHistory.debtorBank')}</h5>
                <div className="bank-logo">
                  <Image src={aspsp?.logo} />
                  <h6>{aspsp?.name}</h6>
                </div>
              </td>
            </tr>
            <tr>
              <td className="right-row-data">
                <h5>{t('pages.paymentHistory.amount')}</h5>
                {/* <h6>{Amount(JSON.parse(data[0].payment))}</h6> */}
                <h6>{JSON.parse(data[0].payment).amount}</h6>
              </td>
            </tr>
            <tr>
              <td className="right-row-data">
                <h5>{t('pages.paymentHistory.status')}</h5>
                <StatusChip status ={status}/>
              </td>
            </tr>
            <tr>
              <td className="right-row-data">
                <h5>{t('pages.paymentHistory.paymentId')}</h5>
                <h6>{paymentId}</h6>
              </td>
            </tr>
            <tr>
              <td className="right-row-data">
                <h5>{t('pages.paymentHistory.accountNumber')}</h5>
                <h6>{JSON.parse(data[0].payment).details.creditorAccount.issuer}</h6>
              </td>
            </tr>
            <tr>
              <td className="right-row-data">
                <h5>Debtor CPF/CNPJ</h5>
                <h6>{debtorCpf}</h6>
              </td>
            </tr>
            <tr>
              <td className="right-row-data">
                <h5>Creditor CPF/CNPJ</h5>
                <h6>{creditorCpf}</h6>
              </td>
            </tr>
            <tr>
              <td className="right-row-data">
                <h5>{t('pages.paymentHistory.pisClient')}</h5>
                <h6>{client}</h6>
              </td>
            </tr>
            <tr>
              <td className="right-row-data">
                <h5>{t('pages.paymentHistory.appId')}</h5>
                <h6>{data[0].clientId}</h6>
              </td>
            </tr>
            <tr>
              <td className="right-row-data">
                <h5>{t('pages.paymentHistory.debtorsReference')}</h5>
                <h6>{remittanceInformation}</h6>
              </td>
            </tr>
            {(status == 'Future' || JSON.parse(data[0].payment)?.schedule)&& (
              <tr>
                <td className="right-row-data">
                  <h5>{t('pages.paymentHistory.scheduledType')}</h5>
                  <h6>{
                    JSON.parse(data[0].payment).schedule?.daily ? 'Daily'
                    : JSON.parse(data[0].payment).schedule?.weekly ? 'Weekly'
                    : JSON.parse(data[0].payment).schedule?.monthly ? 'Monthly'
                    : JSON.parse(data[0].payment).schedule?.custom ? 'Custom' : 'Single'
                  }</h6>
                </td>
              </tr>
            )}
            {(status == 'Future' || JSON.parse(data[0].payment)?.schedule) ? (
              <tr>
                <td className="right-row-data">
                  <h5>{t('pages.paymentHistory.startDate')}</h5>
                  <h6>{JSON.parse(data[0].payment).schedule?.daily?.startDate ? format(new Date(JSON.parse(data[0].payment).schedule.daily.startDate), "yyyy-MM-dd")
                  : JSON.parse(data[0].payment).schedule?.single?.date ? format(new Date(JSON.parse(data[0].payment).schedule.single.date), "yyyy-MM-dd")
                  : JSON.parse(data[0].payment).schedule?.monthly?.startDate ? format(new Date(JSON.parse(data[0].payment).schedule.monthly.startDate), "yyyy-MM-dd")
                  : JSON.parse(data[0].payment).schedule?.weekly?.startDate ? format(new Date(JSON.parse(data[0].payment).schedule.weekly.startDate), "yyyy-MM-dd")
                  : JSON.parse(data[0].payment).schedule?.custom?.dates ? format(new Date(JSON.parse(data[0].payment).schedule.custom.dates[0]), "yyyy-MM-dd")  : '-'}</h6>
                </td>
              </tr>
            ):  null}
            {((status == 'Future' || JSON.parse(data[0].payment)?.schedule) && !JSON.parse(data[0].payment)?.schedule?.single) ? (
              <tr>
                <td className="right-row-data">
                  <h5>{t('pages.paymentHistory.endDate')}</h5>
                  <h6>{JSON.parse(data[0].payment).schedule?.daily?.quantity ? getFutureDates(JSON.parse(data[0].payment).schedule.daily.startDate, JSON.parse(data[0].payment).schedule.daily.quantity, 'daily')
                  : JSON.parse(data[0].payment).schedule?.monthly?.quantity ? getFutureDates(JSON.parse(data[0].payment).schedule.monthly.startDate, JSON.parse(data[0].payment).schedule.monthly.quantity, 'monthly')
                  : JSON.parse(data[0].payment).schedule?.weekly?.quantity ? getFutureDates(JSON.parse(data[0].payment).schedule.weekly.startDate, JSON.parse(data[0].payment).schedule.weekly.quantity, 'weekly')
                  : JSON.parse(data[0].payment).schedule?.custom?.dates ? format(new Date(JSON.parse(data[0].payment).schedule.custom.dates[JSON.parse(data[0].payment).schedule.custom.dates.length - 1]), "yyyy-MM-dd") :  '-'}</h6>
                </td>
              </tr>
            ):  null}
            {status == 'Booked' ? (
              <tr>
                <td className="right-row-data">
                  <h5>{t('pages.paymentHistory.paymentExecutedDate')}</h5>
                  <h6>{JSON.parse(data[0].payment)?.date ? format(new Date(JSON.parse(data[0].payment).date), "yyyy-MM-dd") : JSON.parse(data[0].payment).schedule.single.date && format(new Date(JSON.parse(data[0].payment).schedule.single.date), "yyyy-MM-dd")}</h6>
                </td>
              </tr>
            ):  null}
            <tr>
              <td className="right-row-data last-row-data">
                <h5>{t('pages.paymentHistory.submittedDate')}</h5>
                <h6>{format(new Date(data[0].creationDateTime), "yyyy-MM-dd")}</h6>
              </td>
            </tr>
          </table>
          <div>
            {status && status == 'Rejected'
            ? (
              <table>
              <div className="right-subtitle">{t('pages.paymentHistory.rejectionReason')}</div>
                <tr>
                  <td className="last-row">
                    <h5>{t('pages.paymentHistory.detail')}</h5>
                    <h6>{rejectionReason}</h6>
                  </td>
                </tr>
              </table>
            )
            : status == 'Cancelled' ? (
              <table className="obaf-stack-right-container">
                <div className="right-subtitle">{t('pages.paymentHistory.cancellationReason')}</div>
                <tr>
                  <td className="right-row-data-last">
                    <h5>{t('pages.paymentHistory.detail')}</h5>
                    <h6>{cancellationReason}</h6>
                  </td>
                </tr>
              </table>
            ) : null}
          </div>
         </div>
      )}
      <table className="obaf-stack-left-container">
        <div className="left-subtitle">{t('pages.paymentHistory.auditHistory')}</div>
        {data.length && data.map((rowData) => {
          return rowData.trails.map((trail) => {
            if (!trail.paymentId || paymentId == trail.paymentId) {
              return (
                <div className="step">
                    <div className="v-stepper">
                        <div className="circle"/>
                        <div className="line"/>
                    </div>
                    <div className="content">
                      <PaymentStatusChip status={trail.status} statusType={trail.statusType}/>
                      <tr>
                        <td className="left-row-data">
                          <div style={{width: '190px'}}>
                            <h5>{t('pages.paymentHistory.updatedOn')}</h5>
                            <h6>{format(new Date(trail.createdAt), "yyyy-MM-dd' 'HH:mm:ss")}</h6>
                          </div>
                          <div style={{width: '115px'}}/>
                          {
                            trail.statusType == 'PAYMENT' ? (
                              <div className="payment-block">
                                <h5>{t('pages.paymentHistory.paymentId')}</h5>
                                <h6>{rowData.paymentId}</h6>
                              </div>
                            ): trail.statusType == 'CONSENT' ? (
                              <div className="consent-block">
                                <h5>{t('pages.paymentHistory.consentId')}</h5>
                                <h6> {rowData.consentId}</h6>
                              </div>
                            ) : null
                          }
                        </td>
                      </tr>
                    </div>
                </div>
            )
            } else {
              return null
            }
          })
        })}
      </table>
    </div> : <Alert variant="warning">No Data Available!</Alert>
  )
}

export default StackComponent;