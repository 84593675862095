/* eslint-disable dot-notation */
/* eslint-disable max-lines */
import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Loader } from 'components/common'
import Service from 'service'
import moment from 'moment';
import './users.scss';
import { rangeOptions } from '../../range-filter';
import ChartBox from '../../chart-box';
import { DownloadCard } from 'components/business-components';
import { showError } from 'utils';
import { useTranslation } from 'react-i18next';
import useIsMounted from 'components/hooks/use-is-mounted';
import { useFintech } from 'components/context/fintech-context';
import config from 'components/config';
import * as FileSaver from 'file-saver'
import * as XLSX from 'xlsx-js-style'

const Requests = ({ aspspId }) => {
  const isMounted = useIsMounted();
  const { t } = useTranslation();
  const [aispChartData, setAispChartData] = useState([]);
  const [aispChartLabelValues, setAispChartLabelValues] = useState([]);
  const [pispChartData, setPispChartData] = useState([]);
  const [pispChartLabelValues, setPispChartLabelValues] = useState([]);
  const [aispLegendValues, setAispLegendValues] = useState({
    minimum: 0,
    maximum: 0,
    average: 0
  })
  const [pispLegendValues, setPispLegendValues] = useState({
      minimum: 0,
      maximum: 0,
      average: 0
  })

  const [isLoading, setIsLoading] = useState('');
  const [range, setRange] = useState(rangeOptions[0]);
  const { selectedFintech } = useFintech();

  const loadData = useCallback(async () => {
    setIsLoading('loading');

    try {
      const from = moment().add(-1 * range, 'days').format('YYYY-MM-DD');
      const to = moment().format('YYYY-MM-DD');

      const metricsValues = await Service.metrics.getSelectedBank(aspspId, from, to, selectedFintech?.id);
      groupByValuesForChart(metricsValues?.data?.metrics);
    } catch (err) {
      console.log(err);
      showError(err);
    }
  }, [aspspId, range]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  // eslint-disable-next-line max-statements
  const groupByValuesForChart = useCallback((data) => {
    let tempAispData = []
    let tempPispData = []

    for (let i = 0; i < data?.length; i++) {
      data[i].startDateTime = moment.utc(new Date(data[i].startDateTime)).format('YYYY-MM-DD')
      if (data[i].type === 'PISP') {
        tempPispData.push(data[i])
      } else {
        tempAispData.push(data[i])
      }
    }

    const formattedAispData = []
    const formattedPispData = []

    tempAispData?.reduce(function (res, value) {
      if (!res[value.startDateTime]) {
          res[value.startDateTime] = {
            startDateTime: value.startDateTime,
            users: []
          }
          formattedAispData.push(res[value.startDateTime])
      }
      
      if (value.userId) {
          !res[value.startDateTime].users.includes(value.userId) &&
          res[value.startDateTime].users.push(value.userId)
      }
      return res;
    }, {})

    tempPispData?.reduce(function (res, value) {
      if (!res[value.startDateTime]) {
          res[value.startDateTime] = {
            startDateTime: value.startDateTime,
            users: []
          }
          formattedPispData.push(res[value.startDateTime])
      }
      if (value.userId) {
          !res[value.startDateTime].users.includes(value.userId) &&
          res[value.startDateTime].users.push(value.userId)
      }
      return res;
    }, {})

    const aispData = {values: [], labels: []}
    const pispData = {values: [], labels: []}

    for (let i = 0; i < formattedAispData.length; i++) {
        aispData.labels.push(formattedAispData[i].startDateTime);
        aispData.values.push(formattedAispData[i].users.length)
    }
    for (let i = 0; i < formattedPispData.length; i++) {
        pispData.labels.push(formattedPispData[i].startDateTime);
        pispData.values.push(formattedPispData[i].users.length)
    }

    if (isMounted.current) {
      setAispChartData(aispData.values);
      setAispChartLabelValues(aispData.labels);
      setPispChartData(pispData.values);
      setPispChartLabelValues(pispData.labels);
      setAispLegendValues({minimum: Math.min(...aispData.values), maximum: Math.max(...aispData.values), average: aispData.values.reduce((res, val) => res+val, 0)/aispData.values.length})
      setPispLegendValues({minimum: Math.min(...pispData.values), maximum: Math.max(...pispData.values), average: pispData.values.reduce((res, val) => res+val, 0)/pispData.values.length})
      setIsLoading('success');
    }
  }, []);

  const onDownload = async (fromDate, toDate) => {
    const from = moment(fromDate).format('YYYY-MM-DD')
    const to = moment(toDate).format('YYYY-MM-DD')

    const { data } = await Service.metrics.getSelectedBank(aspspId, from, to, selectedFintech?.id)
    const result = FormatDownloadData(data.metrics)

    const fileType =
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
    const fileExtension = '.xlsx'

    const ws = result.length && XLSX.utils.json_to_sheet(result)

    ws['A1'].s = {
        fill: {
          patternType: 'solid',
          fgColor: { rgb: 'FFBDC3C7' }
        },
        font: {
          name: 'Times New Roman',
          sz: 14,
          color: { rgb: '#FF000000' },
          bold: false,
          italic: false,
          underline: false
        },
        alignment: {
            vertical: 'center',
            horizontal: 'left'
        },
        border: {
            right: {style: 'thin', color: 'FF979A9A'}
        }
    };

    ws['B1'].s = {
        fill: {
          patternType: 'solid',
          fgColor: { rgb: 'FFBDC3C7s' }
        },
        font: {
          name: 'Times New Roman',
          sz: 14,
          color: { rgb: '#FF000000' },
          bold: false,
          italic: false,
          underline: false
        },
        alignment: {
            vertical: 'center',
            horizontal: 'left'
        },
        border: {
            right: {style: 'thin', color: 'FF979A9A'}
        }
    };

    ws['C1'].s = {
        fill: {
          patternType: 'solid',
          fgColor: { rgb: 'FFBDC3C7s' }
        },
        font: {
          name: 'Times New Roman',
          sz: 14,
          color: { rgb: '#FF000000' },
          bold: false,
          italic: false,
          underline: false
        },
        alignment: {
            vertical: 'center',
            horizontal: 'left'
        },
        border: {
            right: {style: 'thin', color: 'FF979A9A'}
        }
    };

    var wscols = [
        {wch: 15},
        {wch: 27},
        {wch: 27}
    ];
      
    ws['!cols'] = wscols;
    ws['!cols'][3] = { hidden: true };

    var wsrows =  [
        {hpt: 20}
    ];

    ws['!rows'] = wsrows;

    const wb = { Sheets: { data: ws }, SheetNames: ['data'] }
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array', cellStyles: true})
    const data1 = new Blob([excelBuffer], { type: fileType })

    FileSaver.saveAs(data1, 'Unique Users' + fileExtension)
  }

  const FormatDownloadData = (data) => {
    let tempData = [];
    for (let i = 0; i < data?.length; i++) {
      data[i].Date = new Date(data[i].startDateTime).toLocaleDateString()
      tempData.push(data[i])
    }

    const formattedData = []

    tempData?.reduce(function (res, value) {
        if (!res[value.Date]) {
          res[value.Date] = {
            Date: value.Date,
            AispUsers: [],
            PispUsers: []
          }
          formattedData.push(res[value.Date])
        }

        if (value.type =='PISP') {
          if (value.userId) {
            !res[value.Date].PispUsers.includes(value.userId) &&
            res[value.Date].PispUsers.push(value.userId)
          }
        } else if (value.userId) {
          !res[value.Date].AispUsers.includes(value.userId) &&
          res[value.Date].AispUsers.push(value.userId)
        }
        return res;
    }, {})

    const result = [];

    for (let i = 0; i < formattedData.length; i++) {
        result.push({
          Date: formattedData[i].Date,
          'No Of Unique Users - AISP': formattedData[i].AispUsers.length,
          'No Of Unique Users - PISP': formattedData[i].PispUsers.length
        })
    }
    return result;
}
  const getOptions = useCallback((data, labels, color) => ({
    title: {
      text: ''
    },
    series: [
      {
        type: 'column',
        data: data,
        color: color,
        opacity: 0.4,
        name: t('pages.adminAnalyticsDetail.users')
      }
    ],
    xAxis: {
      categories: labels
    },
    yAxis: {
      title: false,
      gridLineWidth: 0
    },
    plotOptions: {
      column: {
        states: {
          hover: {
            opacity: 1
          }
        }
      },
      series: {
        states: {
          inactive: {
            enabled: false
          }
        }
      }
    },
    legend: {
      enabled: false
    },
    credits: {
      enabled: false
    }
  }), [t]);

  const options1 = useMemo(() =>
    getOptions(aispChartData, aispChartLabelValues, config.primaryColor),
    [getOptions, aispChartData, aispChartLabelValues]
  );

  const options2 = useMemo(() =>
    getOptions(pispChartData, pispChartLabelValues, config.secondaryColor),
    [getOptions, pispChartData, pispChartLabelValues]
  );

  return isLoading === 'loading'
    ? <div className="charts-loader"><Loader /></div>
    : (
      <div className="users">
        <ChartBox
          rangeValue={range}
          onRangerangeValueChange={setRange}
          noData={aispChartData?.length == 0}
          options={options1}
          title={t('pages.adminAnalyticsDetail.aispUniqueApiUsers')}
          noDataText={t('pages.adminAnalyticsDetail.noAispDataFound')}
          subContent={(
            <div className="rangeLine">
              <span>{t('pages.adminAnalyticsDetail.average')}: {aispLegendValues.average.toFixed(1)}</span>
              <span>{t('pages.adminAnalyticsDetail.minimum')}: {aispLegendValues.minimum}</span>
              <span>{t('pages.adminAnalyticsDetail.maximum')}: {aispLegendValues.maximum}</span>
            </div>
          )}
        />

        <ChartBox
          rangeValue={range}
          onRangerangeValueChange={setRange}
          noData={pispChartData?.length == 0}
          options={options2}
          title={t('pages.adminAnalyticsDetail.pispUniqueApiUsers')}
          noDataText={t('pages.adminAnalyticsDetail.noPispDataFound')}
          subContent={(
            <div className="rangeLine">
              <span>{t('pages.adminAnalyticsDetail.average')}: {pispLegendValues.average.toFixed(1)}</span>
              <span>{t('pages.adminAnalyticsDetail.minimum')}: {pispLegendValues.minimum}</span>
              <span>{t('pages.adminAnalyticsDetail.maximum')}: {pispLegendValues.maximum}</span>
            </div>
          )}
        />

        <DownloadCard
          title={t('pages.adminAnalyticsDetail.uniqueApiUsers')}
          onDownload={(fromDate, toDate) => {
            onDownload(fromDate, toDate)
          }}
        />
      </div>
    );
};

export default Requests;