import React, { useState, useCallback } from 'react';
import { Toaster } from 'react-hot-toast';
import 'assets/scss/main.scss';
import Header from 'components/header';
import Menu from 'components/menu';
import { useSession } from '../context/session-context';

const Layout = ({ children }) => {
  const [isMenuVisible, setIsMenuVisible] = useState(false);
  const { user, loggedIn } = useSession();

  const handleToggleMenu = useCallback((e) => {
    setIsMenuVisible(!isMenuVisible);
    if (e.currentTarget) {
      const href = e.currentTarget
        .getAttribute('href');

      if (href && !(/^\//).test(href)) {
        e.preventDefault();
      }
    }
  }, [isMenuVisible]);

  return (
    <div className={`body private ${isMenuVisible ? ' is-menu-visible' : ''}`}>
      <div id="wrapper">
        <Toaster />
        <Header user={user} loggedIn={loggedIn} onToggleMenu={handleToggleMenu} />
        {children}
      </div>

      <Menu user={user} onToggleMenu={handleToggleMenu} />
    </div>
  )
}

export default Layout;