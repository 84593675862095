import React from 'react';
import { useTranslation } from 'react-i18next';
import { Page, Button } from 'components/common';
import AspspClientsTable from './aspsp-clients-table';
import './aspsp-client-management.scss';
import { navigate } from 'gatsby';

const AspspClientManagement = ({ user }) => {
   const { t } = useTranslation();
  return (
  <Page
    title= {t('pages.adminClientManagement.title')}
    subtitle={t('pages.adminClientManagement.subtitle')}
    className="aspsp-client-management"
    fintechSidebar={user?.userType === 'super-admin'}
    actions={(
      <div className="actions">
        <Button onClick={() => navigate('/user/aspsp-client-management/dcr-management')}>
          {t('pages.adminClientManagement.dcrManagement')}
        </Button>
      </div>
    )}
  >
    <AspspClientsTable />
  </Page>
  )
};

export default AspspClientManagement;