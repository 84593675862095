import React, { useState, useCallback, useMemo } from 'react';
import { Input, Button } from 'components/common';
// eslint-disable-next-line import/no-named-as-default
import toast from 'react-hot-toast';
import { updateUser } from 'components/lib/auth';
import { showError } from 'utils';
import { useTranslation } from 'react-i18next';

const Account = ({ user }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState(user || {});

  const onInputChange = useCallback(({ target }) => {
    const { id, value } = target;
    setValues({ ...values, [id]: value });
  }, [values]);

  const isFormValid = useMemo(() => {
    return values.email && values.organization

  }, [values]);

  const onSubmit = useCallback(async() => {
    setLoading(true);

    try {
      await updateUser(values);
      toast.success(t('common.updatedSuccessfully'));
    } catch (err) {
      console.log(err);
      showError(err);
    }

    setLoading(false);
  }, [values]);

  return (
    <div className="form account">
      <Input
        id="email"
        name="email"
        label={t('pages.profile.email')}
        value={values?.email}
        required
        fullWidth
        onChange={onInputChange}
        wrapperClass="control"
      />
      <Input
        id="organization"
        name="organization"
        label={t('pages.profile.organizationName')}
        required
        value={values?.organization}
        onChange={onInputChange}
        wrapperClass="control"
      />
      <Input
        id="contactPerson"
        name="name"
        label={t('pages.profile.contactPerson')}
        value={values?.contactPerson}
        onChange={onInputChange}
        wrapperClass="control"
      />
      <Input
        id="contactNumber"
        name="contactNumber"
        label={t('pages.profile.contactPhone')}
        value={values?.contactNumber}
        onChange={onInputChange}
        wrapperClass="control"
      />

      <Button
        className="register-btn"
        disabled={loading || !isFormValid}
        onClick={onSubmit}
      >
        {loading ? <i className="fa fa-spin fa-circle-o-notch" /> : null}
        {t('common.saveChanges')}
      </Button>
    </div>
  );
};

export default Account;