import React, { useMemo, useCallback, useState } from 'react';

import EditClientModal from 'components/modal/client/edit';
import DeleteClientModal from 'components/modal/client/delete';

import ASPSPInfo from './aspsp';
import { useTranslation } from 'react-i18next';

const AppItem = ({ data }) => {
  const { t } = useTranslation();
  const [editModal, setEditModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);

  const toggleEdit = useCallback(() => setEditModal(!editModal), [editModal]);
  const toggleDelete = useCallback(() => setDeleteModal(!deleteModal), [deleteModal]);

  const onItemDelete = useCallback((e) => {
    e.preventDefault();
    toggleDelete();
  }, [toggleDelete]);

  const onItemEdit = useCallback((e) => {
    e.preventDefault();
    toggleEdit();
  }, [toggleEdit]);

  const settings = useMemo(() => JSON.parse(data.settings || '{}'), [data]);

  return (
    <tr>
      <td><ASPSPInfo aspspId={data.aspspId} /></td>
      <td><div>{settings?.clientId}</div></td>
      <td><div>{settings?.tppRedirectUrl}</div></td>
      <td>
        <div className="item-actions">
          <a
            href="#"
            className="icon remove-item"
            onClick={onItemDelete}>
            <i className="fa fa-trash" />
          </a>
          <a
            href="#"
            className="icon edit-item"
            onClick={onItemEdit}>
            <i className="fa fa-pencil" />
          </a>
        </div>

        {deleteModal ? (
          <DeleteClientModal
            data={data}
            title={t('common.pleaseConfirm')}
            toggle={toggleDelete}
            isOpen={deleteModal} />
        ) : null}

        {editModal ? (
          <EditClientModal
            data={data}
            title={t('pages.apps.editApplicationClient')}
            toggle={toggleEdit}
            isOpen={editModal} />
        ) : null}
      </td>
    </tr>
  );
};

export default AppItem;