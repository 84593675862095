import config from 'components/config';
import request from 'components/lib/request';
import isBrowser from 'components/lib/isbrowser';

const { apiBase } = config;

export const getUrlParams = () =>
  isBrowser &&
  typeof URLSearchParams !== 'undefined' &&
  new URLSearchParams(window.location.search);

export const toQueryString = (obj) =>
  typeof obj === 'object' && Object.keys(obj).length
    ? '?' + Object.keys(obj)
      .map((key) =>
        `${encodeURIComponent(key)}=${encodeURIComponent(obj[key].value)}`
      )
      .join('&')
    : '';

export const aspsps2values = (list) => {
  return (
    list ? list.map(({
      name: label,
      id: value,
      wellKnownEndpoint,
      forms
    }) => ({ label, value, wellKnownEndpoint, forms })) : []
  )
}

export const getClients = (id) =>
  request({
    uri: `${apiBase}/resources/clients?fintechId=${id}`
  });

export const getASPSP = (id) =>
  request({
    uri: `${apiBase}/resources/aspsps/${id}`
  });

export const getASPSPs = () =>
  request({
    uri: `${apiBase}/resources/aspsps`
  });