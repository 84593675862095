import React, { useState, useCallback, useMemo } from 'react';
import './reports.scss';
import { Row, Col } from 'reactstrap';
import { Select, DatePicker, Button, Loader } from 'components/common';
import moment from 'moment';
import { showError } from 'utils';
import { useTranslation } from 'react-i18next';
import useIsMounted from 'components/hooks/use-is-mounted';

const Reports = () => {
  const isMounted = useIsMounted();
  const { t } = useTranslation();
  const [format, setFormat] = useState(null);
  const [type, setType] = useState(null);
  const [dateRange, setDateRange] = useState([null, null]);
  const [isLoading, setIsLoading] = useState('');

  const formats = [
    { value: 'v1', label: t('pages.adminAnalyticsDetail.format') + ' 1' },
    { value: 'v2', label: t('pages.adminAnalyticsDetail.format') + ' 2' },
    { value: 'v3', label: t('pages.adminAnalyticsDetail.format') + ' 3' }
  ];

  const types = [
    { value: 'v1', label: t('pages.adminAnalyticsDetail.type') + ' 1' },
    { value: 'v2', label: t('pages.adminAnalyticsDetail.type') + ' 2' },
    { value: 'v3', label: t('pages.adminAnalyticsDetail.type') + ' 3' }
  ];


  const isValid = useMemo(() => format && type && dateRange[0] && dateRange[1], [format, type, dateRange]);

  const handleSubmit = useCallback(async () => {
    setIsLoading(true);

    try {
      const from = moment(dateRange[0]).format('YYYY-MM-DD');
      const to = moment(dateRange[1]).format('YYYY-MM-DD');

      // TODO call service
    } catch (err) {
      console.log(err);
      showError(err);
    }

    setIsLoading(false);
  }, [format, type, dateRange])

  return (
    <div className="reports">
      <div className="title">{t('pages.adminAnalyticsDetail.downloadReports')}</div>
      <div className="subtitle">{t('pages.adminAnalyticsDetail.pleaseSelectReportTypeAndDateRange')}</div>
      <div className="form">
        <Row>
          <Col xs={12} sm={12} md={4} lg={4}>
            <Select
              fullWidth
              label={t('pages.adminAnalyticsDetail.reportFormat')}
              required
              options={formats}
              value={format}
              onChange={setFormat}
            />
          </Col>
          <Col xs={12} sm={12} md={4} lg={4}>
            <Select
              fullWidth
              label={t('pages.adminAnalyticsDetail.reportType')}
              required
              options={types}
              value={type}
              onChange={setType}
            />
          </Col>
          <Col xs={12} sm={12} md={4} lg={4}>
            <DatePicker
              selectsRange={true}
              fullWidth
              startDate={dateRange[0]}
              endDate={dateRange[1]}
              onChange={setDateRange}
              onClear={() => {
                setDateRange([null, null])
              }}
              label={t('pages.adminAnalyticsDetail.dateRange')}
              placeholderText={t('pages.adminAnalyticsDetail.dateRange')}
              required
            />
          </Col>
        </Row>
      </div>
      <div className="submit">
        <Button disabled={!isValid || isLoading} onClick={handleSubmit}>
          {t('common.download')}
        </Button>
        {isLoading && <Loader />}
      </div>
    </div>
  );
};

export default Reports;