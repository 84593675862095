import React, { Component } from 'react';
import { ModalFooter } from 'reactstrap';

import config from 'components/config';
import request from 'components/lib/request';
import pubsub from 'components/lib/pubsub';

import ErrorMessage from 'components/lib/error';
import Modal from 'components/modal';
import Form from './form';

const updateClient = (clientId, body) =>
  request({
    uri: `${config.apiBase}/resources/clients/${clientId}`,
    method: 'PUT',
    body
  });


const UpdateBtn = ({ loading, onClick }) =>
  <button
    className="button special"
    onClick={onClick}
    disabled={loading}>
    {loading ? (
      <i className="fa fa-spin fa-circle-o-notch" />
    ) : null}
    {' Update'}
  </button>

export default class EditTPPClientModal extends Component {

  submitter;

  constructor(props) {
    super(props);

    this.state = {
      values: {}
    };

    this.onChange = this.onChange.bind(this);
    this.createApp = this.createApp.bind(this);
  }

  onChange(values) {
    this.setState({
      values: {
        settings: values.settings
      },
      error: null
    });
  }

  createApp(e) {
    e.preventDefault();

    this.setState({
      loading: true,
      error: null
    });

    const data = {
      ...this.props.data,
      ...this.state.values
    };

    const { id } = data;

    console.log('* app client update data:', data);

    updateClient(id, data)
      .then(() => {
        this.props.toggle();
        this.setState({ loading: false });
        pubsub.publish('clients:update');
      })
      .catch((e) => {
        this.setState({
          loading: false,
          error: e.message || JSON.stringify(e)
        });
        console.error('* app client udpate error:', e);
      });
  }

  render() {
    var {
      data,
      ...props
    } = this.props;

    const { loading, error } = this.state;

    if (Object.keys(this.state.values).length === 0) {
      const settings = JSON.parse(data.settings || '{}');

      data = { ...data, settings };
    } else {
      const settings = this.state.values.settings;

      data = { ...data, settings };
    }

    return (
      <Modal {...props} footer={
        <ModalFooter>
          <button
            disabled={loading}
            onClick={this.props.toggle}>
            Cancel
          </button>
          <UpdateBtn
            loading={loading}
            onClick={this.createApp} />
        </ModalFooter>
      }>
        <Form
          data={data}
          onChange={this.onChange}
          onSubmit={this.onSubmit} />

        {error ? (
          <p className="error">
            <ErrorMessage error={error} />
          </p>
        ) : null}
      </Modal>
    );
  }
}