/* eslint-disable no-nested-ternary */
import React, { useState, useMemo, useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Page, Table, Chip, Button } from 'components/common'
import Amount from 'components/lib/amount';
import queryparam from 'components/lib/queryparam';
import { showError } from 'utils'
import Service from 'service'
import moment from 'moment';
import { useFintech } from 'components/context/fintech-context'
import useAspsp from 'components/data-hooks/aspsp'
import { navigate } from 'gatsby'
import { useSession } from '../../../../context/session-context';

// import './selected-payment-status'

const SelectedBankDetail = () => {
  const { selectedFintech } = useFintech()
  const { aspspsMap, loading: aspspsMapLoading } = useAspsp()
  const [page, setPage] = useState(0)
  const [pageSize, setPageSize] = useState(10)
  const [totalPage, setTotalPage] = useState(0)
  const {t} = useTranslation();
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)
  const { user } = useSession();

  const columns = useMemo(
    () => [
      {
        header: t('common.date'),
        render: (rowData) => rowData.requestedExecutionDate
        ? moment(rowData.requestedExecutionDate).format('YYYY-MM-DD')
        : rowData.schedule ? moment(rowData.schedule.singleDate).format('YYYY-MM-DD') : ''
      },
      {
        header: `${t('common.bank')}(s)`,
        render: (rowData) => {
          if (aspspsMapLoading) {
            return `${t('common.loading')}...`
          }

          const aspsp = aspspsMap[rowData?.$obaf?.aspspId]
          if (!aspsp) {
            return ''
          }

          return (
            <div className="banks">
              <span>{aspsp?.name}</span>
            </div>
          )
        }
      },
      { header: t('pages.paymentHistory.paymentId'), field: 'paymentId' },
      {
        header: t('pages.paymentHistory.amount'),
        render: (rowData) => {
          // return Amount(rowData?.amount)
          return rowData?.amount.amount
        }
      },
      {
        header: t('common.status'),
        field: 'status',
        render: (rowData) => {
          let variant = 'default';
          if (rowData.status === 'Pending') {
              variant = 'pending';
          }
          if (rowData.status === 'Booked') {
              variant = 'success';
          }
          if (rowData.status === 'Future') {
              variant = 'warning';
          }
          if (rowData.status === 'Rejected') {
              variant = 'error';
          }
          if (rowData.status === 'Cancelled') {
            variant = 'default';
          }

          return <Chip label={rowData.status} variant={variant} />
        }
      },
      {
        header: '',
        render: (rowData) => (
          <div className="table-buttons">
            <Button
              size="small"
              variant="secondary"
              className="edit"
              onClick={() => {
                navigate(
                  `/user/payment-history/details?paymentId=${rowData.paymentId}&aspspId=${rowData?.$obaf?.aspspId}&status=${rowData.status}&cpf=${rowData.details.cpf}&cnpj=${rowData.details.cnpjInitiator}${rowData?.details?.rejectionReason ? `&rejectionReason=${rowData.details.rejectionReason.detail}`: ''}${rowData?.details?.cancellation?.cancelledByCpf ? `&cancellationReason=${rowData.details.cancellation.cancelledByCpf}`: ''}`
                )
              }}
            >
              {t('common.viewDetails')}
            </Button>
          </div>
        )
      }
    ],
    [aspspsMap, aspspsMapLoading]
  )

  const loadPayments = useCallback(async () => {
    if (!selectedFintech?.id) {
      return
    }

    setLoading(true)

    try {
      const { data, total } = await Service.payments.paymentHistory(
        selectedFintech?.id,
        user?.fintechId,
        {range: queryparam('range')},
        page * pageSize,
        pageSize
      )
      setData(data?.payments || [])
      setTotalPage(Math.trunc(total / pageSize) + (total % pageSize ? 1 : 0))
    } catch (err) {
      console.log(err)
      showError(err)
    }
    setLoading(false)
  }, [selectedFintech, page, pageSize])

  useEffect(() => {
    loadPayments()
  }, [loadPayments])

  return (
   <Page
    breadcrumb ={[
      {
        label: t('pages.paymentHistory.paymentDashboard'),
        url: '/user/payment-history'
      },
      {label: t('pages.paymentHistory.details')}
    ]}
   >
  <Table
    columns={columns}
    data={data}
    loading={loading}
    pagination={{
      page,
      onChange: setPage,
      total: totalPage,
      size: pageSize,
      onSizeChange: setPageSize
    }}
    noDataMessage={t('pages.paymentHistory.paymentNoDataMessage')}
  />

   </Page>
  )
}

export default SelectedBankDetail