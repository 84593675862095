import React, { useState, useMemo, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next'
import { Table, Button, Chip, Tooltip } from 'components/common';
import './aspsp-clients-table.scss';
import { showError } from 'utils';
import DynamicModal from '../dynamicModal';
import Service from 'service';
import { navigate } from 'gatsby';
import { useFintech } from 'components/context/fintech-context';
import useIsMounted from 'components/hooks/use-is-mounted';
import toast from 'react-hot-toast';
import { useSession } from '../../../../context/session-context';

const statusVariant = (status) => {
  switch (status) {
    case 'ACTIVE':
      return 'success';
    case 'INACTIVE':
      return 'error';
    default:
      return 'error';
  }
};
const statusLabel = (status, t) => {
  switch (status) {
    case 'ACTIVE':
      return t('common.tableStatus.active')
    case 'INACTIVE':
      return t('common.tableStatus.inActive')
    default:
      return t('common.tableStatus.inActive')
  }
};

const AspspClientsTable = () => {
  const isMounted = useIsMounted();
  const [updatingClient, setUpdatingClient] = useState(null);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const { selectedFintech } = useFintech();
  const { t } = useTranslation();
  const { user } = useSession();

  const getSettings = useCallback((rowData) => {
    try {
      return JSON.parse(rowData?.settings);
    } catch (e) {
      return {};
    }
  })

  const loadClients = useCallback(async () => {
    if (!selectedFintech?.id) {
      return;
    }

    setLoading(true);

    try {
      const { data: clients } = await Service.clients.list(selectedFintech.id);
      const { data: aispAspsps } = await Service.aspsps.listAvailables(selectedFintech.id, user?.fintechId);
      const { data: pispAspsps } = await Service.aspsps.listAvailablePayments(selectedFintech.id, user?.fintechId);
      const updatedValue = await updateAspspName(clients, aispAspsps, pispAspsps);
      // const { data: providers } = await Service.aspsps.listIntegrateds(selectedFintech.id);

      isMounted.current && setData([...updatedValue])
    } catch (err) {
      console.log(err);
      showError(err);
    }

    isMounted.current && setLoading(false);
  }, [selectedFintech?.id]);

  const toggleActiveStatus = useCallback(async (client) => {
    setLoading(true);

    try {
      await Service.clients.update({
        ...client,
        status: client.status === 'ACTIVE' ? 'INACTIVE' : 'ACTIVE'
      });

      toast.success(t('common.updatedSuccessfully'));
      await loadClients();
    } catch (err) {
      console.log(err);
      showError(err);
    }

    isMounted.current && setLoading(false);
  }, [loadClients]);

  const columns = useMemo(() => [
    {
      header: `${t('pages.adminClientManagement.aspsp')} ${t('common.name')}`,
      render: (rowData) => <span className="data">{rowData?.aspspName?.length > 0 ? rowData.aspspName : 'Participant currently unavailable'}</span>
    },
    {
      header: t('pages.apps.clientId'),
      render: (rowData) => {
        const settings = getSettings(rowData);
        return (
          <Tooltip className="data-tooltip" title={settings?.clientId}>
            <div className="element">
              <span className="data">{settings?.clientId}</span>
            </div>
          </Tooltip>
        )
      }
    },
    {
      header: `${t('pages.adminClientManagement.product')}`,
      render: (rowData) => <span className="data">{rowData.product}</span>
    },
    {
      header: t('common.registration'),
      render: (rowData) => {
        const settings = getSettings(rowData);
        if (settings) {
          if (settings.mTLSCertificate) {
            return <span className="data">DCR</span>;
          } else {
            return <span className="data">Manual</span>;
          }
        } else {
          return <span className="data"></span>;
        }
      }
    },
    {
      header: t('common.status'),
      render: (rowData) => (
        <Chip
          className="data"
          variant={statusVariant(rowData.status)}
          label={statusLabel(rowData.status, t)}
        />
      )
    },
    {
      header: '',
      render: (rowData) => (
        <div className="table-buttons">
          <Button
            size="small"
            variant="secondary"
            className="edit"
            onClick={() => toggleActiveStatus(rowData)}
          >
            {rowData.status === 'ACTIVE' ? t('pages.adminClientManagement.deactivate') : t('pages.adminClientManagement.activate')}
          </Button>

          <Button
            size="small"
            variant="secondary"
            className="edit"
            onClick={() => {
              navigate(
                `/user/aspsp-client-management/dcr-management?clientId=${rowData.id}`
              )
            }}
          >
            {t('pages.adminClientManagement.goToDcr')}
          </Button>
        </div>
      )
    }
  ], [t, toggleActiveStatus]);

  

  const updateAspspName = async (clients, aispAspsps, pispAspsps) => {
    const updatedClients = clients.map((client) => {
    let updatedClient={};
    if (client.product == 'AISP') {
      const aspsp = aispAspsps?.find((aspsp) => aspsp.id === client.aspspId);
      updatedClient = {
        ...client,
        aspspName: aspsp?.name
      }
    } else if (client.product == 'PISP') {
      const aspsp = pispAspsps?.find((aspsp) => aspsp.id === client.aspspId);
      updatedClient = {
        ...client,
        aspspName: aspsp?.name
      }
    }
    return updatedClient
    })
    return updatedClients;
  }

  useEffect(() => {
    loadClients();
  }, [loadClients]);

  return (
    <div className="aspsp-clients-table">
      <Table
        columns={columns}
        data={data}
        loading={loading}
        noDataMessage={t('pages.adminClientManagement.noAspspAdded')}
      />
      <DynamicModal
        key={updatingClient?.id || 'empty'}
        initialData={updatingClient}
        isOpen={Boolean(updatingClient)}
        onClose={(updated) => {
          setUpdatingClient(null)
          if (updated) {
            loadClients()
          }
        }}
      />
    </div>
  )
}

export default AspspClientsTable;