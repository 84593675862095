import config from 'components/config';

const { apiBase } = config;

export const onSpecsClick = (e) => {
  const { target } = e;

  if (
    config.enableSwaggerPreview &&
    target.innerHTML &&
    target.innerHTML.match(/swagger/)
  ) {
    return;
  }

  if (
    target.innerHTML &&
    target.innerHTML.match(/\//)
  ) {
    e.preventDefault();
  }
}


export const getAPIKey = ({ credentials }) =>
  credentials && credentials[0].apiKey;

export const getProductSpecs = (serviceId) =>
  `${apiBase}/resources/products/${serviceId}/swagger.json`