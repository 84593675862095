import React from 'react';

const getPathMethodAuthorized = (system, props) => {
  const authorized = system.getState().getIn(['auth', 'authorized']);

  if (system.getState().getIn(['auth', 'authorized'])) {
    const securities = Object.keys(authorized.toJS());

    return securities.find((key) =>
      props.schema.description.match(key)
    );
  }
};

export default {
  wrapComponents: {
    JsonSchema_string: (Original, system) => (props) => {
      if (props.value === null) {
        return <Original {...{ ...props, value: '' }} />
      }

      if (
        props.schema.name === 'Authorization' &&
        getPathMethodAuthorized(system, props)
      ) {
        return (
          <input
            type="text"
            readOnly={true}
            value={props.value} />
        );
      }

      return <Original {...props} />
    }
  }
}