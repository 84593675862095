/* eslint-disable max-statements */
/* eslint-disable no-nested-ternary */
import React, { useState, useMemo, useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Table, Button, Image, Chip, Tooltip } from 'components/common'
import Amount from 'components/lib/amount';
import { showError } from 'utils'
import Service from 'service'
import moment from 'moment';
import { format } from 'date-fns';
import { useFintech } from 'components/context/fintech-context'
import useAspsp from 'components/data-hooks/aspsp'
import { navigate } from 'gatsby'
import '../payment-history.scss';
import { useSession } from '../../../../../context/session-context';

const InstantPaymentHistoryTable = ({ search, banks, filter, setIsDataPresent, page, setPage, pageSize, setPageSize }) => {
  const { selectedFintech } = useFintech()
  const { aspspsMap, loading: aspspsMapLoading } = useAspsp()
  const [data, setData] = useState([])
  const [clientId, setClientId] = useState(null)
  const [loading, setLoading] = useState(false)
  const [totalPage, setTotalPage] = useState(0)
  const { t } = useTranslation()
  const { user } = useSession()

  const columns = useMemo(
    () => [
      {
        header: t('pages.paymentHistory.submittedDate'),
        render: (rowData) => {
          return (
            <span className="data">{format(new Date(rowData.$obaf.timestamp), 'yyyy-MM-dd')}</span>
          )
      }},
      {
        header: t('pages.paymentHistory.debtorBank'),
        render: (rowData) => {
          if (aspspsMapLoading) {
            return `${t('common.loading')}...`
          }

          const aspsp = aspspsMap[rowData?.$obaf?.aspspId]
          if (!aspsp) {
            return ''
          }

          return (
            <div className="banks">
              <span>{aspsp?.name}</span>
            </div>
          )
        }
      },
      { header: t('pages.paymentHistory.consentId'),
        render: (rowData) => {
          return (
            <Tooltip title={rowData.consentId}>
              <div className="element">
                <span>{rowData?.consentId ? rowData?.consentId: 'N/A'}</span>
              </div>
            </Tooltip>
          )
        }
      },
      { header: t('pages.paymentHistory.paymentId'), render: (rowData) => {
        return (
          <Tooltip title={rowData.paymentId}>
            <div className="element">
              <span>{rowData.paymentId}</span>
            </div>
          </Tooltip>
        )
      }},
      { header: t('pages.paymentHistory.transactionIdentification'), render: (rowData) => {
        return (
          rowData?.transactionIdentification ? <Tooltip title={rowData.transactionIdentification}>
          <div className="element">
            <span>{rowData.transactionIdentification || '-'}</span>
          </div>
        </Tooltip> : <div className="element" style={{textAlign: 'center'}}>-</div>
        )
      }},
      { header: 'Debtor CPF/CNPJ',
        render: (rowData) => {
          return (
            <span className="data">{rowData?.details?.cpf || rowData?.details?.cnpj}</span>
          )
        }
      },
      { header: 'Creditor CPF/CNPJ',
        render: (rowData) => {
          return (
            <span className="data">{rowData.details?.creditorCpfCnpj}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
          )
        }
      },
      {
        header: t('pages.paymentHistory.amount'),
        render: (rowData) => {
          return (
            // <span className="data">{Amount(rowData?.amount)}</span>
            <span className="data">{rowData?.amount.amount}</span>
          )
        }
      },
      {
        header: t('common.status'),
        field: 'status',
        render: (rowData) => {
          let variant = 'default';
          let variantDesc='';
          let scheduleType = '';
          let scheduleTypeDesc = '';

          const getToolTipContent = (rowData) => {
            if (rowData?.schedule?.daily) {
              scheduleType = 'D';
              scheduleTypeDesc = `Scheduled daily - Start date: ${rowData?.schedule?.daily?.startDate}`
            } else if (rowData?.schedule?.weekly) {
              scheduleType = 'W';
              scheduleTypeDesc = `Scheduled weekly - Start date: ${rowData?.schedule?.weekly?.startDate}`
            } else if (rowData?.schedule?.monthly) {
              scheduleType = 'M';
              scheduleTypeDesc = `Scheduled monthly - Start date: ${rowData?.schedule?.monthly?.startDate}`
            } else if (rowData?.schedule?.custom) {
              scheduleType = 'C';
              scheduleTypeDesc = `Custom schedule type - Start date: ${rowData?.schedule?.custom?.dates?.[0]}`
            } else if (rowData?.schedule?.singleDate) {
              scheduleType = 'S';
              scheduleTypeDesc = `Scheduled at ${rowData?.schedule?.singleDate}`
            }
          }

          if (rowData.status === 'Pending') {
              rowData.status = t('pages.paymentHistory.filter.status.pending')
              variant = 'pending';
          }
          if (rowData.status === 'Booked') {
              rowData.status = t('pages.paymentHistory.filter.status.booked')
              variant = 'success';
              variantDesc = t('pages.paymentHistory.paymentStatus.booked')
              getToolTipContent(rowData)
          }
          if (rowData.status === 'Future') {
              rowData.status = t('pages.paymentHistory.filter.status.future')
              variant = 'future';
              variantDesc = t('pages.paymentHistory.paymentStatus.future')
              getToolTipContent(rowData)
          }
          if (rowData.status === 'Rejected') {
              rowData.status = t('pages.paymentHistory.filter.status.rejected')
              variant = 'error';
              variantDesc = t('pages.paymentHistory.paymentStatus.rejected')
              getToolTipContent(rowData)
          }
          if (rowData.status === 'Cancelled') {
              rowData.status = t('pages.paymentHistory.filter.status.cancelled')
              variant = 'default';
              variantDesc = t('pages.paymentHistory.paymentStatus.cancelled')
              getToolTipContent(rowData)
          }
          return (
            <div style={{display: 'flex', width: '52%'}}>
              <Tooltip className="tooltip" title={variantDesc? variantDesc: <><div>{t('pages.paymentHistory.paymentStatus.pending1')}</div><div>{t('pages.paymentHistory.paymentStatus.pending2')}</div></>}>
                  <Chip className="chip" label={rowData.status} variant={variant} />
              </Tooltip>
              {(rowData.status === 'Future' || rowData?.schedule) && <Tooltip className="tooltip" title={scheduleTypeDesc}>
                  <Chip variant="default" label={scheduleType}/>
              </Tooltip>}
            </div>
          )
        }
      },
      {
        header: '',
        render: (rowData) => (
          <div className="table-buttons">
            <Button
              size="small"
              variant="secondary"
              className="edit"
              onClick={() => {
                navigate(
                  `/user/payment-history/details?paymentId=${rowData.paymentId}&aspspId=${rowData?.$obaf?.aspspId}&status=${rowData.status}&debtorCpf=${rowData.details.cpf}&creditorCpf=${rowData.details.creditorCpfCnpj}&client=${rowData?.$obaf?.name}${rowData?.details?.rejectionReason ? `&rejectionReason=${rowData.details.rejectionReason.detail}`: ''}${rowData?.details?.cancellation?.cancelledByCpf ? `&cancellationReason=${rowData.details.cancellation.reason}`: ''}${rowData?.remittanceInformation ? `&remittanceInformation=${rowData.remittanceInformation}`: ''}`
                )
              }}
            >
              {t('common.viewDetails')}
            </Button>
          </div>
        )
      }
    ],
    [aspspsMap, aspspsMapLoading, selectedFintech]
  )

  const loadPayments = useCallback(async () => {
    if (!selectedFintech?.id) {
      return
    }
    setLoading(true);

    if (filter.status !== 'FUTURE' || filter.status !== 'CANCELLED') {
      filter.paymentType = 'Instant'
      try {
        const { data, total } = await Service.payments.paymentHistory(
          selectedFintech?.id,
          user?.fintechId,
          filter,
          'desc',
          page * pageSize,
          pageSize
        )
        setData(data.payments || [])
        setTotalPage(Math.trunc(total / pageSize) + (total % pageSize ? 1 : 0))
        total == 0 ? setIsDataPresent(false) : setIsDataPresent(true)
      } catch (err) {
        console.log(err)
        showError(err)
      }
      setLoading(false)
    }
  }, [banks, selectedFintech, page, pageSize, filter])

  useEffect(() => {
    loadPayments()
  }, [loadPayments])


  const handleClientIdChange = (e) => {
    setClientId(e.target.value)
  }

  return (
    <div className="lending-table">
      <Table
        columns={columns}
        data={data}
        loading={loading}
        pagination={{
          page,
          onChange: setPage,
          total: totalPage,
          size: pageSize,
          onSizeChange: setPageSize
        }}
        noDataMessage={t('pages.paymentHistory.paymentNoDataMessage')}
      />
    </div>
  )
}

export default InstantPaymentHistoryTable
