import React from 'react';
import Select from 'react-select';

import config from 'components/config';

const theme = (base) => ({
  ...base,
  borderRadius: 2,
  colors: {
    ...base.colors,
    primary: config.primaryColor || '#CCC',
    primary25: '#D1D1D1',
    primary50: '#D1D1D1',
    primary75: '#D1D1D1',
    neutral5: '#e9ecef'
  }
});

const styles = {
  control: (base) => ({
    ...base,
    '&:hover': {
      borderColor: '#D1D1D1'
    }
  }),
  placeholder: (base) => ({
    ...base,
    color: '#D1D1D1'
  })
}


export default ({
  name,
  options,
  values,
  placeholder,
  disabled,
  onChange,
  className
}) =>
  <Select
    className={className}
    theme={theme}
    styles={styles}
    isDisabled={disabled}
    value={typeof values[name] === 'string'
      ? { value: values[name] }
      : values[name]
    }
    placeholder={placeholder}
    onChange={typeof onChange === 'function'
      ? ({ value, ...rest }) => onChange({
        name,
        value,
        ...rest
      }) : null}
    options={options} />
