import React, { useState, useMemo, useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Page } from 'components/common'
import { dateFormatter } from 'components/common/helpers'
import queryparam from 'components/lib/queryparam';
import { showError } from 'utils'
import Service from 'service'
import { useFintech } from 'components/context/fintech-context'
import useAspsp from 'components/data-hooks/aspsp'
import Stack from '../stack'
import './selected-payment.scss'
import { useSession } from '../../../../context/session-context';

const SelectedPaymentDetails = ({ search, banks }) => {
  const { selectedFintech } = useFintech()
  const { aspspsMap, loading: aspspsMapLoading } = useAspsp()
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)
  const [page, setPage] = useState(0)
  const [pageSize, setPageSize] = useState(10)
  const [totalPage, setTotalPage] = useState(0)
  const { t } = useTranslation()
  const { user } = useSession();
  const columns = useMemo(
    () => [
      { header: t('pages.paymentHistory.consentId'), field: 'consentId' },
      {
        header: t('pages.paymentHistory.createdAt'), render: (rowData) => {
          return dateFormatter(rowData.creationDateTime)
        }
      },
      {
        header: t('common.status'),
        field: 'status',
        render: (rowData) => t('pages.paymentHistory.consentStatus.' + rowData.status)
      },
      {
        header: t('pages.paymentHistory.updatedOn'),
        render: (rowData) => {
          return dateFormatter(rowData.statusUpdateDateTime)
        }
      }
    ],
    [aspspsMap, aspspsMapLoading, t, data]
  )

  const loadPayments = useCallback(async () => {
    if (!selectedFintech?.id) {
      return
    }

    setLoading(true)

    try {
      const { data } = await Service.payments.selectedPaymentDetails(
        selectedFintech?.id,
        queryparam('paymentId'),
        page * pageSize,
        pageSize,
        user?.fintechId
      )
      setData(data?.items)
      setTotalPage(Math.trunc(data.total / pageSize) + (data.total % pageSize ? 1 : 0));
    } catch (err) {
      console.log(err)
      showError(err)
    }

    setLoading(false)
  }, [search, banks, selectedFintech, page, pageSize])

  useEffect(() => {
    loadPayments()
  }, [loadPayments])

  return (
    <Page
      breadcrumb={[
        {
          label: t('pages.paymentHistory.paymentDashboard'),
          url: '/user/payment-history'
        },
        { label: queryparam('paymentId') },
        {label: t('pages.paymentHistory.details')}
      ]}
    >
      <div className="lending-table">
       <div>
          <Stack
              data={data}
              loading={loading}
              paymentId= {queryparam('paymentId')}
              aspspId = {queryparam('aspspId')}
              status = {queryparam('status')}
              debtorCpf={queryparam('debtorCpf')}
              creditorCpf={queryparam('creditorCpf')}
              client={queryparam('client')}
              rejectionReason={queryparam('rejectionReason')}
              cancellationReason={queryparam('cancellationReason')}
              remittanceInformation={queryparam('remittanceInformation')}
            />
       </div>
      </div>
    </Page>
  )
}

export default SelectedPaymentDetails
