import React, { useState, useMemo, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next'
import { Table, Chip, LinkButton } from 'components/common';
import './dcr-jobs-table.scss';
import { useFintech } from 'components/context/fintech-context';
import Service from 'service';
import { showError } from 'utils';
import { useSession } from '../../../../../context/session-context';

const statusVariant = (status) => {
  switch (status) {
    case 'SUCCESS':
      return 'success';
    case 'FAILURE':
      return 'error';
    default:
      return 'error';
  }
};
const statusLabel = (status, t) => {
  switch (status) {
    case 'SUCCESS':
      return t('common.tableStatus.completed')
    case 'FAILURE':
      return t('common.tableStatus.failure')
    default:
      return t('common.tableStatus.failure')
  }
};

const DcrJobsTable = ({ product, tableRef }) => {
  // const [data] = useState([
  //   { jobId: 46211, date: 'Dec 30, 2019', aspsps: 'Citi Bank, Juno, Banco Alfa', status: 0 },
  //   { jobId: 57245, date: 'Dec 07, 2019', aspsps: 'Citi Bank, Juno, Banco Alfa, Banco Daycoval, Banco Something', status: 1 },
  //   { jobId: 52361, date: 'Jan 13, 2020', aspsps: 'Banco Alfa, Bank of America, Credidas, Juno', status: -1 },
  //   { jobId: 99632, date: 'Dec 30, 2019', aspsps: 'Bank of America', status: 1 }
  // ]);

  const { selectedFintech } = useFintech();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [totalPage, setTotalPage] = useState(0);
  const { t } = useTranslation()
  const { user } = useSession();

  const columns = useMemo(
    () => [
      { header: t('pages.adminClientManagement.jobId'), field: 'id' },
      {
        header: t('common.date'),
        render: (rowData) => new Date(rowData.dcr?.[0]?.date).toDateString()
      },
      {
        header: t('pages.adminClientManagement.listOfAspsp'),
        render: (rowData) => rowData.dcr?.map((d) => d.aspspLabel)?.join(', ')
      },
      {
        header: t('common.status'),
        render: (rowData) => (
          <Chip
            variant={statusVariant(rowData.dcr?.[0]?.status, t)}
            label={statusLabel(rowData.dcr?.[0]?.status, t)}
          />
        )
      },
      {
        header: '',
        render: (rowData) => (
          <div className="table-buttons">
            <LinkButton
              size="small"
              variant="secondary"
              className="edit"
              to={`/user/aspsp-client-management/dcr-management/job?jobId=${rowData.id}&product=${product}`}
            >
              {t('common.viewDetails')}
            </LinkButton>
          </div>
        )
      }
    ],
    [product]
  )

  const loadData = useCallback(async () => {
    if (!selectedFintech?.id) {
      return
    }

    setLoading(true);

    try {
      if (product === 'AISP' || !product) {
        const { data: jobs } = await Service.providers.listDcrJobs(
          selectedFintech?.id,
          page,
          pageSize,
          user?.fintechId
        )
        setData(jobs.items)
        setTotalPage(Math.trunc(jobs.total / pageSize))
      } else {
        const { data: paymentsJobs } =
          await Service.providers.listPaymentsDcrJobs(
            selectedFintech?.id,
            page,
            pageSize,
            user?.fintechId
          )
        setData(paymentsJobs.items)
        setTotalPage(Math.trunc(paymentsJobs.total / pageSize))
      }
    } catch (err) {
      console.log(err)
      showError(err);
    }

    setLoading(false)
  }, [selectedFintech?.id, product, page, pageSize])

  useEffect(() => {
    loadData();

    tableRef.current = {
      refreshTable: loadData
    };

  }, [loadData]);

  return (
    <div className="dcr-jobs-table">
      <Table
        columns={columns}
        data={data}
        noDataMessage={t('pages.adminClientManagement.noDataMessageClient')}
        loading={loading}
        pagination={{
          page,
          onChange: setPage,
          total: totalPage,
          size: pageSize,
          onSizeChange: setPageSize
        }}
      />
    </div>
  );
}

export default DcrJobsTable;