import React, { useCallback, useEffect, useMemo, useState } from 'react'

import { getUrlParams } from 'components/content/util'

import AppCard from './card'
import AppClients from './clients'
import Service from 'service';
import useIsMounted from 'components/hooks/use-is-mounted';

const AppPage = () => {
  const isMounted = useIsMounted();
  const [app, setApp] = useState(null);
  const [error, setError] = useState(null);

  const params = useMemo(() => getUrlParams(), []);

  const update = useCallback(() => {
    if (!params) {
      return
    }

    const appId = params.get('clientId')

    if (!appId) {
      return
    }

    Service.apps.get(appId)
      .then(({ data }) => isMounted.current && setApp({ app: data }))
      .catch((error) => isMounted.current && setError({ error }))
  }, [params]);

  useEffect(() => {
    update();
  }, [update]);

  return (
    <div className="app-page">
      {app ? (
        <section className="section normal-section">
          <div className="inner content">
            <AppCard data={app} />
            <AppClients data={app} />
          </div>
        </section>
      ) : (
        <div className="loader-wrap pt-5">
          <div className="loader" />
        </div>
      )}
    </div>
  );
};

export default AppPage;