import React from 'react';

import AppActions from './actions';
import { useTranslation } from 'react-i18next';


export default ({ data }) => {
  const { t } = useTranslation();

  return (
    <div className="box app">
      <header className="d-flex">
        <h4 className="text-uppercase">
          {data.name}
        </h4>
        <AppActions data={data} />
      </header>
      <div className="table-wrapper">
        <table className="alt">
          <tbody>
            <tr>
              <th>{t('pages.apps.fintechId')}</th>
              <td className="text-right">{data.fintechId}</td>
              <td></td>
            </tr>
            <tr>
              <th>{t('pages.apps.clientId')}</th>
              <td className="text-right">{data.clientId}</td>
              <td></td>
            </tr>
            {data.redirectUris && data.redirectUris.length ? (
              <tr>
                <th>{t('pages.apps.obafRedirectUri')}</th>
                <td className="text-right">{(data.redirectUris || []).join(', ')}</td>
                <td></td>
              </tr>
            ) : null}
            <tr>
              <th>{t('pages.apps.product')}</th>
              <td className="text-right">{data.product}</td>
              <td></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};