import React, { useCallback, useState } from 'react'

import CreateClientModal from 'components/modal/client/create'
import { useTranslation } from 'react-i18next';

const AppCreateRow = ({ appData }) => {
  const [modal, setModal] = useState(false);
  const { t } = useTranslation();

  const toggleModal = useCallback((e) => {
    if (e && e.preventDefault) {
      e.preventDefault()
    }

    setModal(!modal);
  }, [modal]);

  return (
    <div className="add-item ml-auto">
      {modal ? (
        <CreateClientModal
          appData={appData}
          title={t('pages.apps.createApplicationClient')}
          toggle={toggleModal}
          isOpen={modal}
        />
      ) : null}

      <button onClick={toggleModal} className="special">
        <span>
          <i className="fa fa-plus" /> {t('common.Create')}
        </span>
      </button>
    </div>
  );
};

export default AppCreateRow;
