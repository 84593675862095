import { fromJS } from 'immutable';
import UpperCaseFst from 'components/lib/uppercase';

const changedPath = (path, method) =>
  ['spec', 'meta', 'paths', path, method, 'parameters'];

const getChangedKey = (
  system,
  path,
  method
) => {
  const changedMap = system.getState()
    .getIn(changedPath(path, method), fromJS([]));

  if (changedMap.size > 0) {
    let authKey = null;

    for (var key of changedMap.keys()) {
      if (key.match(/Authorization/gi)) {
        authKey = key;
      }
    }

    if (authKey) {
      return authKey.replace(/^header\./, '')
    }
  }
}


const tokenHeader = ({
  token_type: tokenType,
  access_token: accessToken
}) => `${UpperCaseFst(tokenType)} ${accessToken}`;


const findSecuredPaths = (system, name) => {
  const paths = system.specSelectors.operations().toJS();

  return paths.filter(({ operation }) =>
    operation.security &&
    operation.security.find((item) =>
      item[name]
    )
  );
};

export default (system, securityName, token) => {
  const operations = findSecuredPaths(
    system,
    securityName
  );

  operations.forEach(({ path, method }) => {
    const changedKey = getChangedKey(system, path, method);

    system.specActions.changeParam(
      [path, method],
      changedKey ? changedKey : 'Authorization',
      'header',
      token ? tokenHeader(token) : '',
      false
    )
  });
}