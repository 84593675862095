/* eslint-disable dot-notation */
/* eslint-disable max-lines */
import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Loader } from 'components/common'
import Service from 'service'
import moment from 'moment';
import './requests.scss';
import { rangeOptions } from '../../range-filter';
import ChartBox from '../../chart-box';
import { DownloadCard } from 'components/business-components';
import { showError } from 'utils';
import { useTranslation } from 'react-i18next';
import useIsMounted from 'components/hooks/use-is-mounted';
import { useFintech } from 'components/context/fintech-context';
import config from 'components/config';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx-js-style';

const Requests = ({ aspspId }) => {
  const isMounted = useIsMounted();
  const { t } = useTranslation();
  const [aispChartData, setAispChartData] = useState([]);
  const [aispChartLabelValues, setAispChartLabelValues] = useState([]);
  const [pispChartData, setPispChartData] = useState([]);
  const [pispChartLabelValues, setPispChartLabelValues] = useState([]);
  const [aispLegendValues, setAispLegendValues] = useState({
    minimum: 0,
    maximum: 0,
    average: 0
  })
  const [pispLegendValues, setPispLegendValues] = useState({
      minimum: 0,
      maximum: 0,
      average: 0
  })

  const [isLoading, setIsLoading] = useState('');
  const [range, setRange] = useState(rangeOptions[0]);
  const { selectedFintech } = useFintech();

  const loadData = useCallback(async () => {
    setIsLoading('loading');

    try {
      const from = moment().add(-1 * range, 'days').format('YYYY-MM-DD');
      const to = moment().format('YYYY-MM-DD');

      const metricsValues = await Service.metrics.getSelectedBank(aspspId, from, to, selectedFintech?.id);
      groupByValuesForChart(metricsValues?.data?.metrics);
    } catch (err) {
      console.log(err);
      showError(err);
    }
  }, [aspspId, range]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  // eslint-disable-next-line max-statements
  const groupByValuesForChart = useCallback((data) => {
    let tempAispData = []
    let tempPispData = []
    for (let i = 0; i < data?.length; i++) {
      data[i].startDateTime = moment.utc(new Date(data[i].startDateTime)).format('YYYY-MM-DD')
      if (data[i].type === 'PISP') {
        tempPispData.push(data[i])
      } else {
        tempAispData.push(data[i])
      }
    }

    const formattedAispData = []
    const formattedPispData = []

    tempAispData?.reduce(function (res, value) {
      if (!res[value.startDateTime]) {
          res[value.startDateTime] = {
            startDateTime: value.startDateTime,
            totalReading: 0
          }
          formattedAispData.push(res[value.startDateTime])
      }
      res[value.startDateTime].totalReading++
      return res;
    }, {})

    tempPispData?.reduce(function (res, value) {
        if (!res[value.startDateTime]) {
            res[value.startDateTime] = {
              startDateTime: value.startDateTime,
              totalReading: 0
            }
            formattedPispData.push(res[value.startDateTime])
        }
        res[value.startDateTime].totalReading++
        return res;
    }, {})

    const aispData = {values: [], labels: []}
    const pispData = {values: [], labels: []}

    for (let i = 0; i < formattedAispData.length; i++) {
        aispData.labels.push(formattedAispData[i].startDateTime);
        aispData.values.push(formattedAispData[i].totalReading)
    }
    for (let i = 0; i < formattedPispData.length; i++) {
        pispData.labels.push(formattedPispData[i].startDateTime);
        pispData.values.push(formattedPispData[i].totalReading)
    }

    if (isMounted.current) {
      setAispChartData(aispData.values);
      setAispChartLabelValues(aispData.labels);
      setPispChartData(pispData.values);
      setPispChartLabelValues(pispData.labels);
      setAispLegendValues({minimum: Math.min(...aispData.values), maximum: Math.max(...aispData.values), average: aispData.values.reduce((res, val) => res+val, 0)/aispData.values.length})
      setPispLegendValues({minimum: Math.min(...pispData.values), maximum: Math.max(...pispData.values), average: pispData.values.reduce((res, val) => res+val, 0)/pispData.values.length})
      setIsLoading('success');
    }
  }, []);

  const onDownload = async (fromDate, toDate) => {
      const from = moment(fromDate).format('YYYY-MM-DD')
      const to = moment(toDate).format('YYYY-MM-DD')

      const { data } = await Service.metrics.getSelectedBank(aspspId, from, to, selectedFintech?.id);
      const result = FormatDownloadData(data.metrics);

      const fileType =
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
      const fileExtension = '.xlsx'

      const ws = result.length && XLSX.utils.json_to_sheet(result)

      ws['A1'].s = {
        fill: {
          patternType: 'solid',
          fgColor: { rgb: 'FFBDC3C7' }
        },
        font: {
          name: 'Times New Roman',
          sz: 14,
          color: { rgb: '#FF000000' },
          bold: false,
          italic: false,
          underline: false
        },
        alignment: {
            vertical: 'center',
            horizontal: 'left'
        },
        border: {
            right: {style: 'thin', color: 'FF979A9A'}
        }
    };

    ws['B1'].s = {
      fill: {
        patternType: 'solid',
        fgColor: { rgb: 'FFBDC3C7s' }
      },
      font: {
        name: 'Times New Roman',
        sz: 14,
        color: { rgb: '#FF000000' },
        bold: false,
        italic: false,
        underline: false
      },
      alignment: {
          vertical: 'center',
          horizontal: 'left'
      },
      border: {
          right: {style: 'thin', color: 'FF979A9A'}
      }
    };

    ws['C1'].s = {
      fill: {
        patternType: 'solid',
        fgColor: { rgb: 'FFBDC3C7' }
      },
      font: {
        name: 'Times New Roman',
        sz: 14,
        color: { rgb: '#FF000000' },
        bold: false,
        italic: false,
        underline: false
      },
      alignment: {
          vertical: 'center',
          horizontal: 'left'
      },
      border: {
          right: {style: 'thin', color: 'FF979A9A'}
      }
    };

    ws['D1'].s = {
      fill: {
        patternType: 'solid',
        fgColor: { rgb: 'FFBDC3C7' }
      },
      font: {
        name: 'Times New Roman',
        sz: 14,
        color: { rgb: '#FF000000' },
        bold: false,
        italic: false,
        underline: false
      },
      alignment: {
          vertical: 'center',
          horizontal: 'left'
      },
      border: {
          right: {style: 'thin', color: 'FF979A9A'}
      }
    };

    var wscols = [
      {wch: 15},
      {wch: 20},
      {wch: 20},
      {wch: 20}
    ];

    ws['!cols'] = wscols;

    var wsrows =  [
        {hpt: 20} // row 1 sets to the height of 20 in points
    ];

    ws['!rows'] = wsrows;

    const wb = { Sheets: { data: ws }, SheetNames: ['data'] }
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array', cellStyles: true})
    const data1 = new Blob([excelBuffer], { type: fileType })

    FileSaver.saveAs(data1, 'Api Requests' + fileExtension)
  }

  const FormatDownloadData = (data) => {
    let tempData = [];
    for (let i = 0; i < data?.length; i++) {
      data[i].Date = new Date(data[i].startDateTime).toLocaleDateString()
      tempData.push(data[i])
    }

    const formattedData = []

    tempData?.reduce(function (res, value) {
        if (!res[value.Date]) {
            res[value.Date] = {
              Date: value.Date,
              Total_Requests: 0,
              Aisp_Requests: 0,
              Pisp_Requests: 0
            }
            formattedData.push(res[value.Date])
        }
        res[value.Date].Total_Requests++

        if (value.type =='PISP') {
            res[value.Date].Pisp_Requests++
        } else {
            res[value.Date].Aisp_Requests++
        }
        return res;
    }, {})

    return formattedData
}

  const getOptions = useCallback((data, labels, color) => ({
    title: {
      text: ''
    },
    series: [
      {
        type: 'column',
        data: data,
        color: color,
        opacity: 0.4,
        name: t('pages.adminAnalyticsDetail.requests')
      }
    ],
    xAxis: {
      categories: labels
    },
    yAxis: {
      title: false,
      gridLineWidth: 0
    },
    credits: {
      enabled: false
    },
    plotOptions: {
      column: {
        states: {
          hover: {
            opacity: 1
          }
        }
      },
      series: {
        states: {
          inactive: {
            enabled: false
          }
        }
      }
    },
    legend: {
      enabled: false
    }
  }), [t]);

  const options1 = useMemo(() =>
    getOptions(aispChartData, aispChartLabelValues, config.primaryColor),
    [getOptions, aispChartData, aispChartLabelValues]
  );

  const options2 = useMemo(() =>
    getOptions(pispChartData, pispChartLabelValues, config.secondaryColor),
    [getOptions, pispChartData, pispChartLabelValues]
  );

  return isLoading === 'loading'
    ? <div className="charts-loader"><Loader /></div>
    : (
      <div className="requests">
        <ChartBox
          rangeValue={range}
          onRangerangeValueChange={setRange}
          noData={aispChartData?.length == 0}
          options={options1}
          title={t('pages.adminAnalyticsDetail.aispNoOfRequests')}
          noDataText={t('pages.adminAnalyticsDetail.noAispDataFound')}
          subContent={(
            <div className="rangeLine">
              <span>{t('pages.adminAnalyticsDetail.average')}: {aispLegendValues.average.toFixed(1)}</span>
              <span>{t('pages.adminAnalyticsDetail.minimum')}: {aispLegendValues.minimum}</span>
              <span>{t('pages.adminAnalyticsDetail.maximum')}: {aispLegendValues.maximum}</span>
            </div>
          )}
        />

        <ChartBox
          rangeValue={range}
          onRangerangeValueChange={setRange}
          noData={pispChartData?.length == 0}
          options={options2}
          title={t('pages.adminAnalyticsDetail.pispNoOfRequests')}
          noDataText={t('pages.adminAnalyticsDetail.noPispDataFound')}
          subContent={(
            <div className="rangeLine">
              <span>{t('pages.adminAnalyticsDetail.average')}: {pispLegendValues.average.toFixed(1)}</span>
              <span>{t('pages.adminAnalyticsDetail.minimum')}: {pispLegendValues.minimum}</span>
              <span>{t('pages.adminAnalyticsDetail.maximum')}: {pispLegendValues.maximum}</span>
            </div>
          )}
        />

        <DownloadCard
          title={t('pages.adminAnalyticsDetail.apiRequests')}
          onDownload={(fromDate, toDate) => {
            onDownload(fromDate, toDate)
          }}
        />
      </div>
    );
};

export default Requests;