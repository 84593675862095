import React, { useCallback, useEffect, useState } from 'react'

import Select from 'components/form/selector/select'

import { getASPSPs, aspsps2values } from 'components/content/util'
import useIsMounted from 'components/hooks/use-is-mounted';

const Selector = ({ name, onChange }) => {
  const [data, setData] = useState([]);
  const [values, setValues] = useState({});
  const [error, setError] = useState(null);
  const isMounted = useIsMounted();

  const update = useCallback(() => {
    getASPSPs()
      .then((response) => isMounted.current && setData(aspsps2values(response.data)))
      .catch((error) => isMounted.current && setError(error))
  }, []);

  useEffect(() => {
    update();
  }, [update]);

  const onValuesChange = useCallback(({ label, value, wellKnownEndpoint, forms }) => {
    setValues({
      ...values,
      [name]: { value, label }
    });

    onChange({
      name,
      value,
      wellKnownEndpoint,
      forms
    });
  }, [name, values, onChange]);

  return (
    <Select
      values={values}
      name={name}
      onChange={onValuesChange}
      placeholder="Select ASPSP"
      options={data || []}
    />
  );
};

export default Selector;