import React, { useEffect, useState } from 'react'
import StatisticsCard from './statistics-card'
import StatisticsList from './statistics-list'
import './analytics.scss'
import { Page, Chip } from '../../../common';
import Service from 'service'
import { navigate } from 'gatsby';
import { useTranslation } from 'react-i18next';
import { useFintech } from 'components/context/fintech-context';
import moment from 'moment';

const AdminAnalytics = () => {
  const {t} = useTranslation();
  const [dataSource, setDataSource] = useState([]);
  const [loadingStatus, setLoadingStatus] = useState('');
  const { selectedFintech } = useFintech();

  useEffect(async () => {
    const from = moment().subtract(7, 'days').format('YYYY-MM-DD');
    const to = moment().format('YYYY-MM-DD');

    setLoadingStatus('loading');
    const metrics = await Service.metrics.get(selectedFintech?.id, from, to);
    groupByValues(metrics?.data?.metrics)
  }, [])

  const groupByValues = (values) => {
    let result = []
    let tempDownTime = {}
    values?.reduce(function (res, value) {
      if (!res[value.aspspId]) {
        res[value.aspspId] = {
          aspspId: value.aspspId,
          startDateTime: value.startDateTime,
          durationInMillis: 0,
          totalReading: 0,
          failureTime: '',
          totalFailure: 0
        }
        result.push(res[value.aspspId])
      }
      if (value?.durationInMillis) {
        res[value.aspspId].durationInMillis += value?.durationInMillis
      }

      res[value.aspspId].totalReading++

      if (!value.success) {
        if (
          new Date(tempDownTime[value?.aspspId]) <
          new Date(value.startDateTime) ||
          !tempDownTime[value?.aspspId]
        ) {
          tempDownTime[value?.aspspId] = value.startDateTime
          res[value.aspspId].failureTime = value?.startDateTime
        }
        res[value.aspspId].totalFailure++
      }
      return res
    }, {})
    setLoadingStatus('success')
    setDataSource(result);
  }

  const handleRowClick = (bank) => bank.name && navigate(`/user/analytics/detail?aspspId=${bank.aspspId}&name=${bank.name}`);

  return (
    <Page
      title={t('pages.adminAnalytics.title')}
      subtitle={t('pages.adminAnalytics.subtitle')}
      wrapperClassName="api-analytics-wrapper"
      loading={loadingStatus === 'loading'}
    >
      {dataSource?.length > 0
      ? <>
          <StatisticsCard dataSource={dataSource} />
          <StatisticsList dataSource={dataSource} handleRowClick={handleRowClick} />
        </>
      : <div className="mt-5">
          <Chip variant="warning" label="No Analytics data were found for the last 7 days"/>
        </div>
      }
    </Page>
  )
}

export default AdminAnalytics
