import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next'
import { Page } from 'components/common';
import isBrowser from 'components/lib/isbrowser';
import Service from 'service';
import { showError } from 'utils';
import ClientsTable from './clients-table';
import './job-page.scss';
import { useFintech } from 'components/context/fintech-context';

const JobPage = () => {
  const { selectedFintech } = useFintech();
  const [job, setJob] = useState({});
  const [jobLoading, setJobLoading] = useState(true);
  const { t } = useTranslation()
  const locationSearch = isBrowser ? window?.location?.search : ''

  const [jobId, product] = useMemo(() => {
    const urlSearchParams = isBrowser ? new URLSearchParams(window.location.search) : '';
    const params = Object.fromEntries(urlSearchParams.entries());

    return [params.jobId, params.product];
  }, [locationSearch]);

  const loadJob = useCallback(async () => {
    if (!selectedFintech?.id) {
      return;
    }

    setJobLoading(true);

    try {
      if (product === 'AISP') {
        const { data: jobData } = await Service.providers.getDcrJob(selectedFintech.id, jobId);
        setJob(jobData?.dcr);
      } else {
        const { data: jobData } = await Service.providers.getPaymentsDcrJob(selectedFintech.id, jobId);
        setJob(jobData?.dcr);
      }
    } catch (err) {
      console.log(err);
      showError(err);
    }

    setJobLoading(false);
  }, [jobId, selectedFintech, product]);

  useEffect(() => {
    loadJob();
  }, [loadJob]);

  // const JobLineData = useCallback(({ label, value }) => (
  //   <div className="line">
  //     <div className="label">{label}</div>
  //     <div className="value">{value}</div>
  //   </div>
  // ));

  return (
    <Page
      title={t('pages.adminClientManagement.dcrManagement')}
      breadcrumb={[
        {
          label: t('pages.adminClientManagement.aspspClientManagement'),
          url: '/user/aspsp-client-management'
        },
        {
          label: t('pages.adminClientManagement.dcrManagement'),
          url: '/user/aspsp-client-management/dcr-management'
        },
        { label: 'Job ID ' + jobId }
      ]}
      wrapperClassName="job-page"
    >
      {/* <Paper> // Disabled as of now
        {jobLoading ? (
          <div className="loaderWrapper">
            <Loader />
          </div>
        ) : (
          <div className="job">
            <JobLineData
              label="MTLS Private Key"
              value={job?.[0]?.registrationForm?.mTLSPrivateKey}
            />
            <JobLineData
              label="MTLS Public Certificate"
              value={job?.[0]?.registrationForm?.mTLSCertificate}
            />
            <JobLineData
              label="Signing Key ID"
              value={job?.[0]?.registrationForm?.keyId}
            />
            <JobLineData
              label="Signing Private Key"
              value={job?.[0]?.registrationForm?.privateKey}
            />
            <JobLineData
              label="Directory Software Statement Assertion"
              value={job?.[0]?.registrationForm?.softwareStatement}
            />
          </div>
        )}
      </Paper> */}
      <div className="table-wrapper">
        {!jobLoading ? (
          <ClientsTable
            jobId={jobId}
            data={job}
            selectedFintech={selectedFintech}
          />
        ) : (
          ''
        )}
      </div>
    </Page>
  )
}

export default JobPage;