import { useCallback, useEffect, useState, useRef } from 'react';
import Service from 'service';
import { showError } from 'utils';
import useIsMounted from 'components/hooks/use-is-mounted';
import { useFintech } from 'components/context/fintech-context';
import config from 'components/config';
import { useSession } from '../context/session-context';

const useAspsp = () => {
  const isMounted = useIsMounted();
  const [aspsps, setAspsps] = useState([]);
  const [aspspsMap, setAspspsMap] = useState([]);
  const [loading, setLoading] = useState(true);
  const loadExecuted = useRef(false);
  const { user } = useSession();

  const { selectedFintech } = useFintech();

  const loadData = useCallback(async () => {
    if (!selectedFintech?.id) {
      return;
    }

    setLoading(true);
    try {
      const data = [];
      if(selectedFintech?.id) {
        if (['ACCOUNT', 'BOTH', '', undefined].includes(config.tenantProductType)) {
          const { data: aspsps } = await Service.aspsps.listAvailables(selectedFintech?.id, user?.fintechId);
          data.push(aspsps)
        }
  
        if (['PAYMENT', 'BOTH', '', undefined].includes(config.tenantProductType)) {
          const { data: aspsps } = await Service.aspsps.listAvailablePayments(selectedFintech?.id, user?.fintechId);
          data.push(aspsps)
        }
      }

      const formattedData = [].concat(...data)

      if (isMounted.current) {
        setAspsps(formattedData);
        setAspspsMap(formattedData.reduce((prev, curr) => ({
          ...prev,
          [curr.id]: curr
        }), {}));
      }
    } catch (err) {
      console.log(err);
      showError(err);
    }

    isMounted.current && setLoading(false);
  }, [selectedFintech?.id]);

  useEffect(() => {
    // if (!loadExecuted.current) {
      loadExecuted.current = true;
      loadData();
    // }
  }, [loadData]);

  return {
    aspsps,
    aspspsMap,
    loading
  };
};

export default useAspsp;