/* eslint-disable no-nested-ternary */
/* eslint-disable max-statements */
/* eslint-disable complexity */
import React, { useState, useEffect, useRef} from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Page, Tooltip } from 'components/common';
import PaymentHistoryFilter from './payment-history-table/all-payments-filter';
import InstantPaymentsFilter from './payment-history-table/instant-payment-filter';
import ScheduledPaymentsFilter from './payment-history-table/scheduled-payment-filter';
import PaymentHistoryTable from './payment-history-table/all-payments';
import InstantPaymentHistoryTable from './payment-history-table/Instant-payment';
import ScheduledPaymentHistoryTable from './payment-history-table/scheduled-payment';
import { useFintech } from 'components/context/fintech-context';
import useAspsp from 'components/data-hooks/aspsp';
import Service from 'service';
import PaymentGraph from './payment-graph';
import { CSVLink } from 'react-csv';
import { format, isValid } from 'date-fns';
import './payments.scss';
import config from 'components/config';
import moment from 'moment';


const PaymentHistory = ({user}) => {
  const { t } = useTranslation();
  const today = moment();
  const { selectedFintech } = useFintech();
  const [page, setPage] = useState(0)
  const [pageSize, setPageSize] = useState(10)
  const { aspspsMap, loading: aspspsMapLoading } = useAspsp()
  const [filter, setFilter] = useState({
   range: [moment().subtract(1, 'week'), today]
  });
  const [isActive, setIsActive] = useState('all');
  const [paymentsData, setPaymentsData] = useState([]);
  const [isDataPresent, setIsDataPresent] = useState();

  const excelRef = useRef();

  const formatString = (text) => {
    return text?.replace(/\w\S*/g, () => {
      return text.charAt(0).toUpperCase() + text.substr(1).toLowerCase();
    })
  }
  const [updatedData, setUpdatedData] = useState([]);


  useEffect(() => {
    if (paymentsData?.length) {
      const newData = [];

      newData.push([`${filter.status ? formatString(filter.status) : ''} Payments ${filter?.range?.[0] ? `[${format(new Date(filter.range[0]._d), 'yyyy-MM-dd')} - ${format(new Date(filter?.range[1]._d), 'yyyy-MM-dd')}]`: ''}`]);

      if (filter.status == 'BOOKED') {
        newData.push(['Submitted Date', 'Payment Executed Date', 'Debtor Bank', 'PIS Client', 'Payment Id', 'Debtor Account Number', 'Debtor CPF/CNPJ', 'Creditor CPF/CNPJ', 'Amount']);

        for (let i=0; i<paymentsData.length; i++) {
          newData.push([
            `${format(new Date(paymentsData[i].$obaf.timestamp), 'yyyy-MM-dd')}`,
             paymentsData[i].requestedExecutionDate ? `${format(new Date(paymentsData[i].requestedExecutionDate), 'yyyy-MM-dd')}` : '-',
             paymentsData[i].$obaf.aspspId ? `${aspspsMap[paymentsData[i].$obaf.aspspId].name}`: '-',
             paymentsData[i].$obaf?.clientName ? `${paymentsData[i].$obaf.clientName}` : '-',
            `${paymentsData[i].paymentId}`,
            `${paymentsData[i].debtor.accountNumber}`,
            `${paymentsData[i].details.cpf || paymentsData[i].details.cnpj}`,
            `${paymentsData[i].details.creditorCpfCnpj}`,
            `${paymentsData[i].amount.amount}`
          ])
        }
      } else if (filter.status == 'REJECTED') {
        newData.push(['Submitted Date', 'Debtor Bank', 'PIS Client', 'Payment Id', 'Debtor Account Number', 'Debtor CPF/CNPJ', 'Creditor CPF/CNPJ', 'Amount', 'Reason for Rejection']);


        for (let i=0; i<paymentsData.length; i++) {
          newData.push([
            `${format(new Date(paymentsData[i].$obaf.timestamp), 'yyyy-MM-dd')}`,
            paymentsData[i].$obaf.aspspId ? `${aspspsMap[paymentsData[i].$obaf.aspspId].name}`: '-',
            paymentsData[i].$obaf?.clientName ? `${paymentsData[i].$obaf.clientName}` : '-',
            `${paymentsData[i].paymentId}`,
            `${paymentsData[i].debtor.accountNumber}`,
            `${paymentsData[i].details.cpf || paymentsData[i].details.cnpj}`,
            `${paymentsData[i].details.creditorCpfCnpj}`,
            `${paymentsData[i].amount.amount}`,
            `${paymentsData[i].details.rejectionReason.detail}`
          ])
        }
      } else if (filter.status == 'CANCELLED') {
        newData.push(['Submitted Date', 'Debtor Bank', 'PIS Client', 'Payment Id', 'Debtor Account Number', 'Debtor CPF/CNPJ', 'Creditor CPF/CNPJ', 'Amount', 'Reason for Cancellation']);

        for (let i=0; i<paymentsData.length; i++) {
          newData.push([
            `${format(new Date(paymentsData[i].$obaf.timestamp), 'yyyy-MM-dd')}`,
            paymentsData[i].$obaf.aspspId ? `${aspspsMap[paymentsData[i].$obaf.aspspId].name}`: '-',
            paymentsData[i].$obaf?.clientName ? `${paymentsData[i].$obaf.clientName}` : '-',
            `${paymentsData[i].paymentId}`,
            `${paymentsData[i].debtor.accountNumber}`,
            `${paymentsData[i].details.cpf || paymentsData[i].details.cnpj}`,
            `${paymentsData[i].details.creditorCpfCnpj}`,
            `${paymentsData[i].amount.amount}`,
            `${paymentsData[i].details.cancellation.reason}`
          ])
        }
      } else if (filter.status == 'PENDING') {
        newData.push(['Submitted Date', 'Debtor Bank', 'PIS Client', 'Payment Id', 'Debtor Account Number', 'Debtor CPF/CNPJ', 'Creditor CPF/CNPJ', 'Amount']);

        for (let i=0; i<paymentsData.length; i++) {
          newData.push([
            `${format(new Date(paymentsData[i].$obaf.timestamp), 'yyyy-MM-dd')}`,
            paymentsData[i].$obaf.aspspId ? `${aspspsMap[paymentsData[i].$obaf.aspspId].name}`: '-',
            paymentsData[i].$obaf?.clientName ? `${paymentsData[i].$obaf.clientName}` : '-',
            `${paymentsData[i].paymentId}`,
            `${paymentsData[i].debtor.accountNumber}`,
            `${paymentsData[i].details.cpf || paymentsData[i].details.cnpj}`,
            `${paymentsData[i].details.creditorCpfCnpj}`,
            `${paymentsData[i].amount.amount}`
          ])
        }
      } else if (filter.status == 'FUTURE') {
        newData.push(['Submitted Date', 'Scheduled Date', 'Debtor Bank', 'PIS Client', 'Payment Id', 'Debtor Account Number', 'Debtor CPF/CNPJ', 'Creditor CPF/CNPJ', 'Amount']);

        for (let i=0; i<paymentsData.length; i++) {
          newData.push([
            `${format(new Date(paymentsData[i].$obaf.timestamp), 'yyyy-MM-dd')}`,
            `${format(new Date(paymentsData[i].schedule.singleDate), 'yyyy-MM-dd')}`,
            paymentsData[i].$obaf.aspspId ? `${aspspsMap[paymentsData[i].$obaf.aspspId].name}`: '-',
            paymentsData[i].$obaf?.clientName ? `${paymentsData[i].$obaf.clientName}` : '-',
            `${paymentsData[i].paymentId}`,
            `${paymentsData[i].debtor.accountNumber}`,
            `${paymentsData[i].details.cpf || paymentsData[i].details.cnpj}`,
            `${paymentsData[i].details.creditorCpfCnpj}`,
            `${paymentsData[i].amount.amount}`
          ])
        }
      } else if (filter.paymentType == 'Scheduled') {
          newData.push(['Submitted Date', 'Payment Executed Date', 'Scheduled Date', 'Debtor Bank', 'PIS Client', 'Payment Id', 'Status', 'Debtor Account Number', 'Debtor CPF/CNPJ', 'Creditor CPF/CNPJ', 'Amount', 'Reason for Rejection', 'Reason for Cancellation']);
          for (let i=0; i<paymentsData.length; i++) {
            newData.push([
              `${format(new Date(paymentsData[i].$obaf.timestamp), 'yyyy-MM-dd')}`,
               paymentsData[i].status == 'Booked' ? (paymentsData[i].requestedExecutionDate ? `${format(new Date(paymentsData[i].requestedExecutionDate), 'yyyy-MM-dd')}` : `${format(new Date(paymentsData[i]?.schedule?.singleDate), 'yyyy-MM-dd')}`) : 'N/A',
               paymentsData[i].status == 'Future' ? `${format(new Date(paymentsData[i]?.schedule?.singleDate), 'yyyy-MM-dd')}` : 'N/A',
               paymentsData[i].$obaf.aspspId ? (aspspsMap[paymentsData[i].$obaf.aspspId] ? `${aspspsMap[paymentsData[i].$obaf.aspspId]?.name}` : '-') : '-',
               paymentsData[i].$obaf?.clientName ? `${paymentsData[i].$obaf.clientName}` : ' ',
              `${paymentsData[i].paymentId}`,
              `${paymentsData[i].status}`,
              `${paymentsData[i].debtor.accountNumber}`,
              `${paymentsData[i]?.details.cpf || paymentsData[i]?.details.cnpj}`,
              `${paymentsData[i]?.details.creditorCpfCnpj}`,
              `${paymentsData[i].amount.amount}`,
              paymentsData[i].status == 'Rejected' ? `${paymentsData[i]?.details?.rejectionReason?.detail}` : 'N/A',
              paymentsData[i].status == 'Cancelled'? `${paymentsData[i]?.details?.cancellation?.reason}` : 'N/A'
            ])
          }
        } else if (filter.paymentType == 'Instant') {
          newData.push(['Submitted Date', 'Payment Executed Date', 'Debtor Bank', 'PIS Client', 'Payment Id', 'Status', 'Debtor Account Number', 'Debtor CPF/CNPJ', 'Creditor CPF/CNPJ', 'Amount', 'Reason for Rejection']);
          for (let i=0; i<paymentsData.length; i++) {
            newData.push([
              `${format(new Date(paymentsData[i].$obaf.timestamp), 'yyyy-MM-dd')}`,
               paymentsData[i].status == 'Booked' ? (paymentsData[i].requestedExecutionDate ? `${format(new Date(paymentsData[i].requestedExecutionDate), 'yyyy-MM-dd')}` : `${format(new Date(paymentsData[i]?.schedule?.singleDate), 'yyyy-MM-dd')}`) : 'N/A',
               paymentsData[i].$obaf.aspspId ? (aspspsMap[paymentsData[i].$obaf.aspspId] ? `${aspspsMap[paymentsData[i].$obaf.aspspId]?.name}` : '-') : '-',
               paymentsData[i].$obaf?.clientName ? `${paymentsData[i].$obaf.clientName}` : ' ',
              `${paymentsData[i].paymentId}`,
              `${paymentsData[i].status}`,
              `${paymentsData[i].debtor.accountNumber}`,
              `${paymentsData[i]?.details.cpf || paymentsData[i]?.details.cnpj}`,
              `${paymentsData[i]?.details.creditorCpfCnpj}`,
              `${paymentsData[i].amount.amount}`,
              paymentsData[i].status == 'Rejected' ? `${paymentsData[i]?.details?.rejectionReason?.detail}` : 'N/A'
            ])
          }
        } else {
          newData.push(['Submitted Date', 'Payment Executed Date', 'Scheduled Date', 'Debtor Bank', 'PIS Client', 'Payment Id', 'Status', 'Debtor Account Number', 'Debtor CPF/CNPJ', 'Creditor CPF/CNPJ', 'Amount', 'Reason for Rejection', 'Reason for Cancellation']);
          for (let i=0; i<paymentsData.length; i++) {
            newData.push([
              `${isValid(paymentsData[i].$obaf.timestamp) ? format(new Date(paymentsData[i].$obaf.timestamp), 'yyyy-MM-dd') : '-'}`,
               paymentsData[i].status == 'Booked' ? (isValid(paymentsData[i].requestedExecutionDate) ? `${format(new Date(paymentsData[i].requestedExecutionDate), 'yyyy-MM-dd')}` : isValid(paymentsData[i]?.schedule?.singleDate) ? `${format(new Date(paymentsData[i]?.schedule?.singleDate), 'yyyy-MM-dd')}` : 'N/A') : 'N/A',
               paymentsData[i].status == 'Future' ? (isValid(paymentsData[i]?.schedule?.singleDate) ? `${format(new Date(paymentsData[i]?.schedule?.singleDate), 'yyyy-MM-dd')}` : 'N/A') : ' ',
               paymentsData[i].$obaf.aspspId ? (aspspsMap[paymentsData[i].$obaf.aspspId] ? `${aspspsMap[paymentsData[i].$obaf.aspspId]?.name}` : '-') : '-',
               paymentsData[i].$obaf?.clientName ? `${paymentsData[i].$obaf.clientName}` : ' ',
              `${paymentsData[i].paymentId}`,
              `${paymentsData[i].status}`,
              `${paymentsData[i].debtor.accountNumber}`,
              `${paymentsData[i]?.details.cpf || paymentsData[i]?.details.cnpj}`,
              `${paymentsData[i]?.details.creditorCpfCnpj}`,
              `${paymentsData[i].amount.amount}`,
              paymentsData[i].status == 'Rejected' ? `${paymentsData[i]?.details?.rejectionReason?.detail}` : 'N/A',
              paymentsData[i].status == 'Cancelled'? `${paymentsData[i]?.details?.cancellation?.reason}` : 'N/A'
            ])
          }
        }
      setUpdatedData(newData)
    }
  }, [paymentsData])

  useEffect(() => {
    if (updatedData.length) {
      excelRef?.current?.link?.click();
    }
  }, [updatedData])

  const getData = async() => {
    if (!selectedFintech?.id) {
      return
    }
    const {data} = await Service.payments.paymentHistory(
      selectedFintech?.id,
      user?.fintechId,
      filter,
      'asc',
      0,
      config.paymentsDownloadLimit || 200
    )
    setPaymentsData(data.payments);
  }

  const onClear = () => {
    setFilter({})
  }

  const onPartialClear = () => {
    const updatedData = {...filter};
    delete updatedData.name;
    delete updatedData.paymentId;
    delete updatedData.debtorCpfCnpj;
    delete updatedData.creditorCpfCnpj;
    delete updatedData.amount;
    delete updatedData.aspspId;
    setFilter(updatedData)
  }

  return (
    <Page
      pageTitle={t('pages.paymentHistory.tabTitle')}
      fintechSidebar={user?.userType === 'super-admin'}
      setPage={setPage}>
      <div style={{display: 'flex', flexDirection: 'column'}}>
        <div style={{flexBasis: '23%'}}>
          <PaymentGraph value={filter} onChange= {setFilter} TabToggle= {setIsActive} tab= {isActive} user={user} setIsDataPresent={setIsDataPresent}/>
        </div>
        <div className="tab-content">
          <a
            className={'item' + (isActive == 'all' ? ' active' : '')}
            onClick={() => {
              setIsActive('all');
              onPartialClear();
              setPage(0);
            }}
          >
            All Payments
          </a>
          <a
            className={'item' + (isActive == 'instant' ? ' active' : '')}
            onClick={() => {
              setIsActive('instant');
              // (filter.status == 'FUTURE' || filter.status == 'CANCELLED') && delete filter.status
              onPartialClear();
              setPage(0);
            }}
          >
            Instant Payments
          </a>
          <a
            className={'item' + (isActive == 'scheduled' ? ' active' : '')}
            onClick={() => {
              setIsActive('scheduled');
              onPartialClear();
              setPage(0);
            }}
          >
            Scheduled Payments
          </a>
        </div>
        <div className="download">
          <Tooltip title="Maximum download limit is 1000 transactions">
            <Button
              variant="small"
              icon="fa fa-download"
              className="button"
              disabled={!isDataPresent}
              onClick={() => {
                getData()
              }}
            >
              {`${t('common.download')}.csv`}
            </Button>
          </Tooltip>
          {updatedData.length ? (
              <CSVLink
                data={updatedData}
                filename={filter.status ? `${formatString(filter.status)} Payments Data ${format(new Date(), 'yyyy-MM-dd h:mm:ss')}`: `Payments_Data ${format(new Date(), 'yyyy-MM-dd h:mm:ss')}`}
                // asyncOnClick={true}
                ref={excelRef}
                target="_blank"
              >
              </CSVLink>
          ): null}
        </div>

          {
            isActive == 'all' ? (
              <div >
                  <PaymentHistoryFilter value={filter} onChange={setFilter} onClear={onClear} user={user} setPage={setPage}/>
                  <PaymentHistoryTable filter={filter} setIsDataPresent={setIsDataPresent} page={page} setPage={setPage} pageSize={pageSize} setPageSize={setPageSize}/>
              </div>
            )
            : isActive == 'instant' ? (
              <div >
                  <InstantPaymentsFilter value={filter} onChange={setFilter} onClear={onClear} user={user} setPage={setPage}/>
                  <InstantPaymentHistoryTable filter={filter} setIsDataPresent={setIsDataPresent} page={page} setPage={setPage} pageSize={pageSize} setPageSize={setPageSize}/>
              </div>
            )
            : isActive == 'scheduled' ? (
              <div>
                <ScheduledPaymentsFilter value={filter} onChange={setFilter} onClear={onClear} user={user} setPage={setPage}/>
                <ScheduledPaymentHistoryTable filter ={filter} setIsDataPresent={setIsDataPresent} page={page} setPage={setPage} pageSize={pageSize} setPageSize={setPageSize}/>
              </div>
            ) : null
          }
      </div>
    </Page>
  )
}

export default PaymentHistory
