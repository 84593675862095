
import { fromJS } from 'immutable';

const changedPath = (path, method) =>
  ['spec', 'meta', 'paths', path, method, 'parameters'];

const getChangedKey = (system, path, method) => {
  const changedMap = system.getState()
    .getIn(changedPath(path, method), fromJS([]));

  if (changedMap.size > 0) {
    let authKey = null;

    for (var key of changedMap.keys()) {
      if (key.match(/fintechId/gi)) {
        authKey = key;
      }
    }

    if (authKey) {
      return authKey.replace(/^header\./, '')
    }
  }
}


const findAppIdsPaths = (system) => {
  const paths = system.specSelectors.operations().toJS();
  return paths.filter(({ operation }) =>
    operation.parameters &&
    operation.parameters.find((item) =>
      item.name === 'fintechId' ||
      (item.$ref && item.$ref.match(/XAppIdParam/))
    )
  );
};

export default (system, fintechId) => {
  const operations = findAppIdsPaths(system);

  operations.forEach(({ path, method }) => {
    const changedKey = getChangedKey(system, path, method);

    system.specActions.changeParam(
      [path, method],
      changedKey ? changedKey : 'fintechId',
      'header',
      fintechId,
      false
    );
  });
}
