import React, { useCallback, useEffect, useState } from 'react'

import pubsub from 'components/lib/pubsub'
import { getClients } from 'components/content/util'

import Loader from './loader'
import AddApplication from './add'
import ApplicationItem from './item'
import useIsMounted from 'components/hooks/use-is-mounted';
import { useTranslation } from 'react-i18next';

const sort = (list, key) =>
  Array.isArray(list)
    ? list.sort((a, b) =>
      (a && typeof a[key] === 'string' ? a[key] : '').localeCompare(
        b && typeof b[key] === 'string' ? b[key] : ''
      )
    )
    : []

const onChangeSort = (handler, key) => (e) => {
  e.preventDefault()
  handler(key)
}

const Header = ({ onSort }) => {
  const { t } = useTranslation();

  return (
    <thead className="sortable-header">
      <tr>
        <th>
          <a href="#" onClick={onChangeSort(onSort, 'aspspId')}>
            ASPSP
          </a>
        </th>
        <th>
          <a href="#" onClick={onChangeSort(onSort, 'clientId')}>
            {t('pages.apps.clientId')}
          </a>
        </th>
        <th>{t('pages.apps.fintechRedirectUri')}</th>
        <th />
      </tr>
    </thead>
  );
}

const ApplicationsList = ({ data }) => {
  const { t } = useTranslation();
  const isMounted = useIsMounted();
  const [sortBy, setSortBy] = useState('aspspId');
  const [innerData, setInnerData] = useState(null);
  const [error, setError] = useState(null);

  const onSort = useCallback((srt) => {
    setSortBy(srt);
    setInnerData(sort(innerData, srt));
  }, [innerData]);

  const update = useCallback(() => {
    const { fintechId } = data

    getClients(fintechId)
      .then((res) => isMounted.current && setInnerData(sort(res && res.data, sortBy)))
      .catch((e) => {
        console.error('* load data error', e)

        isMounted.current && setError('data load error')
      });
  }, [data, sortBy]);

  useEffect(() => {
    update();
    pubsub.subscribe('clients:update', update)
    return () => pubsub.unsubscribe('clients:update', update)
  }, [update]);

  return (
    <div className="box apps">
      <header className="d-flex">
        <h4>{t('pages.apps.aspspClients')}</h4>

        {innerData && !error ? (
          <AddApplication appData={data} />
        ) : null}
      </header>
      <div className="table-wrapper">
        <table className="alt">
          <Header onSort={onSort} />

          <tbody>
            {innerData ? (
              innerData.map((item) => (
                <ApplicationItem key={item.id} data={item} />
              ))
            ) : (
              <Loader error={error} />
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};


export default ApplicationsList;