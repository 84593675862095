/* eslint-disable no-undefined */
import React, { useState, useRef, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { Page, Button, Input, Select, IconButton } from 'components/common';
import { RoleSelect } from 'components/business-components';
import { Row, Col } from 'reactstrap';
import UsersTable from './users-table';
import FormModal from './formModal';
import './user-management.scss';
import debounce from 'lodash.debounce';


const UserManagement = ({ user }) => {
  const usersTableRef = useRef();
  const { t } = useTranslation();

  const statusOptions = [
    { label: t('common.tableStatus.active'), value: true },
    { label: t('common.tableStatus.inActive'), value: false }
  ]

  const [page, setPage] = useState(0)
  const [pageSize, setPageSize] = useState(10)
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [searchQueryInner, setSearchQueryInner] = useState('');
  const [filterState, setFilterState] = useState({
    searchQuery: '',
    status: '',
    role: ''
  });

  const handleSearchChange = useCallback(debounce((e) => {
    setFilterState({
      ...filterState,
      searchQuery: e.target.value
    });
    setPage(0)
  }, 200), [filterState]);

  const handleStatusChange = useCallback((value) => {
    setFilterState({
      ...filterState,
      status: value
    })
  }, [filterState]);

  const handleRoleChange = useCallback((value) => {
    setFilterState({
      ...filterState,
      role: value
    })
  }, [filterState]);

  const handleFilterClear = useCallback(() => {
    setFilterState({ searchQuery: '', status: '', role: '' });
    setSearchQueryInner('');
  }, []);

  return (
    <Page
      title={t('pages.userManagement.title')}
      subtitle={t('pages.userManagement.subtitle')}
      wrapperClassName="user-management"
      actions={
        <Button icon="fa fa-plus" onClick={() => setCreateModalOpen(true)}>
          {t('pages.userManagement.addNewUser')}
        </Button>
      }
      fintechSidebar={user?.userType === 'super-admin'}
      setPage={setPage}
    >
      <div className="filter-bar">
        <Row noGutters>
          <Col xs={12} sm={12} md={3} className="left-col">
            <Input
              fullWidth
              placeholder={t('pages.userManagement.searchText')}
              value={searchQueryInner}
              onChange={(e) => {
                setSearchQueryInner(e.target.value);
                handleSearchChange(e);
              }}
              endAdornment={
                filterState?.searchQuery && <IconButton
                    icon="fa fa-close"
                    onClick={() => {
                      setSearchQueryInner('');
                      setFilterState({
                        ...filterState,
                        searchQuery: ''
                      })
                    }}
                />
              }
            />
          </Col>
          <Col xs={0} sm={0} md={1} className="col" />
          <Col xs={12} sm={12} md={3} className="col">
            <RoleSelect
              value={filterState?.role}
              fullWidth
              onChange={handleRoleChange}
            />
          </Col>
          <Col xs={12} sm={12} md={3} className="col">
            <Select
              placeholder={t('common.status')}
              fullWidth
              options={statusOptions}
              value={filterState?.status}
              onChange={handleStatusChange}
              isClearable
            />
          </Col>
          <Col xs={12} sm={12} md={2} className="right-col">
            <Button variant="secondary" fullWidth onClick={handleFilterClear}>
              {t('common.clear')}
            </Button>
          </Col>
        </Row>
      </div>
      <UsersTable filterState={filterState} tableRef={usersTableRef} page={page} setPage={setPage} pageSize={pageSize} setPageSize={setPageSize} />
      <FormModal
        isOpen={createModalOpen}
        onClose={(updated) => {
          setCreateModalOpen(false)
          if (updated) {
            usersTableRef.current?.refreshUsers()
          }
        }}
      />
    </Page>
  )
}

export default UserManagement;