/* eslint-disable dot-notation */
/* eslint-disable max-lines */
import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Loader } from 'components/common'
import Service from 'service'
import { showError } from 'utils';
import moment from 'moment';
import './uptime.scss';
import { rangeOptions } from '../../range-filter';
import ChartBox from '../../chart-box';
import { DownloadCard } from 'components/business-components';
import { useTranslation } from 'react-i18next';
import useIsMounted from 'components/hooks/use-is-mounted';
import { useFintech } from 'components/context/fintech-context';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx-js-style';

const Uptime = ({ aspspId }) => {
  const isMounted = useIsMounted();
  const { t } = useTranslation();
  const [aispChartData, setAispChartData] = useState([]);
  const [pispChartData, setPispChartData] = useState([]);
  const [aispAverage, setAispAverage] = useState();
  const [pispAverage, setPispAverage] = useState();
  const [isLoading, setIsLoading] = useState('');
  const [range, setRange] = useState(rangeOptions[0]);
  const { selectedFintech } = useFintech();

  const calculatePercentage = (number1, number2) => {
    return Math.round((number1 / number2) * 100)
  }

  const loadData = useCallback(async () => {
    setIsLoading('loading');

    try {
      const from = moment().add(-1 * range, 'days').format('YYYY-MM-DD');
      const to = moment().format('YYYY-MM-DD');

      const metricsValues = await Service.metrics.getSelectedBank(aspspId, from, to, selectedFintech?.id);
      groupByValuesForChart(metricsValues?.data?.metrics);
    } catch (err) {
      console.log(err);
      showError(err);
    }
  }, [aspspId, range]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const groupByValuesForChart = useCallback((data) => {
    let tempAispData = []
    let tempPispData = []
    for (let i = 0; i < data?.length; i++) {
      data[i].startDateTime = moment.utc(new Date(data[i].startDateTime)).format('YYYY-MM-DD')
      if (data[i].type === 'PISP') {
        tempPispData.push(data[i])
      } else {
        tempAispData.push(data[i])
      }
    }

    const aispData = [];
    const pispData = [];

    tempAispData?.reduce(function (res, value) {
      if (!res[value.startDateTime]) {
        res[value.startDateTime] = {
          aspspId: value.aspspId,
          startDateTime: value.startDateTime,
          totalReading: 0,
          totalFailure: 0
        }
        aispData.push(res[value.startDateTime])
      }

      res[value.startDateTime].totalReading++

      if (!value.success) {
        res[value.startDateTime].totalFailure++
      }
      return res
    }, {})

    tempPispData?.reduce(function (res, value) {
      if (!res[value.startDateTime]) {
        res[value.startDateTime] = {
          aspspId: value.aspspId,
          startDateTime: value.startDateTime,
          totalReading: 0,
          totalFailure: 0
        }
        pispData.push(res[value.startDateTime])
      }

      res[value.startDateTime].totalReading++

      if (!value.success) {
        res[value.startDateTime].totalFailure++
      }
      return res
    }, {})

    let aispDataValues = [];
    let pispDataValues = [];

    for (let i = 0; i < aispData.length; i++) {
      aispDataValues.push(calculatePercentage(aispData[i]?.totalReading - aispData[i]?.totalFailure, aispData[i]?.totalReading))
    }
    for (let i = 0; i < pispData.length; i++) {
      pispDataValues.push(calculatePercentage(pispData[i]?.totalReading - pispData[i]?.totalFailure, pispData[i]?.totalReading))
    }

    if (isMounted.current) {
      setAispChartData(aispDataValues);
      setPispChartData(pispDataValues);
      setAispAverage(aispDataValues.reduce((res, val) => res+val, 0)/aispDataValues.length)
      setPispAverage(pispDataValues.reduce((res, val) => res+val, 0)/pispDataValues.length)
      setIsLoading('success');
    }
  }, []);

  const onDownload = async (fromDate, toDate) => {
      const from = moment(fromDate).format('YYYY-MM-DD')
      const to = moment(toDate).format('YYYY-MM-DD')

      const { data } = await Service.metrics.getSelectedBank(aspspId, from, to, selectedFintech?.id);
      const result = FormatDownloadData(data.metrics);

      const fileType =
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
      const fileExtension = '.xlsx'

      const ws = result.length && XLSX.utils.json_to_sheet(result)

      ws['A1'].s = {
        fill: {
          patternType: 'solid',
          fgColor: { rgb: 'FFBDC3C7' }
        },
        font: {
          name: 'Times New Roman',
          sz: 14,
          color: { rgb: '#FF000000' },
          bold: false,
          italic: false,
          underline: false
        },
        alignment: {
            vertical: 'center',
            horizontal: 'left'
        },
        border: {
            right: {style: 'thin', color: 'FF979A9A'}
        }
      };

      ws['B1'].s = {
        fill: {
          patternType: 'solid',
          fgColor: { rgb: 'FFBDC3C7s' }
        },
        font: {
          name: 'Times New Roman',
          sz: 14,
          color: { rgb: '#FF000000' },
          bold: false,
          italic: false,
          underline: false
        },
        alignment: {
            vertical: 'center',
            horizontal: 'left'
        },
        border: {
            right: {style: 'thin', color: 'FF979A9A'}
        }
      };

    ws['C1'].s = {
      fill: {
        patternType: 'solid',
        fgColor: { rgb: 'FFBDC3C7' }
      },
      font: {
        name: 'Times New Roman',
        sz: 14,
        color: { rgb: '#FF000000' },
        bold: false,
        italic: false,
        underline: false
      },
      alignment: {
          vertical: 'center',
          horizontal: 'left'
      },
      border: {
          right: {style: 'thin', color: 'FF979A9A'}
      }
    };

    var wscols = [
      {wch: 15},
      {wch: 20},
      {wch: 20}
    ];
    
    ws['!cols'] = wscols;

    ws['!cols'][3] = { hidden: true };
    ws['!cols'][4] = { hidden: true };

    var wsrows =  [
        {hpt: 20}
    ];

    ws['!rows'] = wsrows;

    const wb = { Sheets: { data: ws }, SheetNames: ['data'] }
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array', cellStyles: true})
    const data1 = new Blob([excelBuffer], { type: fileType })

    FileSaver.saveAs(data1, 'Api Uptime' + fileExtension)
  }

  const FormatDownloadData = (data) => {
    let tempData = [];
    for (let i = 0; i < data?.length; i++) {
      data[i].Date = new Date(data[i].startDateTime).toLocaleDateString()
      tempData.push(data[i])
    }

    const formattedData = []

    tempData?.reduce(function (res, value) {
      if (!res[value.Date]) {
        res[value.Date] = {
          aspspId: value.aspspId,
          Date: value.Date,
          Aisp_reading: 0,
          Aisp_failure: 0,
          Pisp_reading: 0,
          Pisp_failure: 0
        }
        formattedData.push(res[value.Date])
      }

      if (value.type =='PISP') {
        res[value.Date].Pisp_reading++;
        if (!value.success) {
          res[value.Date].Pisp_failure++
        }
      } else {
        res[value.Date].Aisp_reading++;
        if (!value.success) {
          res[value.Date].Aisp_failure++
        }
      }
      return res;
    }, {})

    const result = []

    for (let i = 0; i < formattedData.length; i++) {
      result.push({
        Date: formattedData[i].Date,
        'Aisp Uptime (%)': calculatePercentage(formattedData[i]?.Aisp_reading - formattedData[i]?.Aisp_failure, formattedData[i]?.Aisp_reading),
        'Pisp Uptime (%)': calculatePercentage(formattedData[i]?.Pisp_reading - formattedData[i]?.Pisp_failure, formattedData[i]?.Pisp_reading)
      })
    }

    return result;
  }

  const getOptions = useCallback((data) => ({
    title: {
      text: ''
    },
    series: [
      {
        type: 'column',
        data: data.map((v) => 100 - v),
        color: '#D4EFEC',
        stack: 'aisp',
        className: 'emptyBar'
      },
      {
        type: 'column',
        data: data,
        color: '#F2545B',
        stack: 'aisp',
        className: 'dataBar'
      }
    ],
    legend: {
      enabled: false
    },
    xAxis: {
      visible: false
    },
    yAxis: {
      title: false,
      visible: false
    },
    credits: {
      enabled: false
    },
    plotOptions: {
      column: {
        stacking: 'normal',
        states: {
          hover: {
            enabled: false
          }
        }
      },
      series: {
        states: {
          inactive: {
            opacity: 1
          }
        },
        groupPadding: 0
      }
    },
    tooltip: {
      enabled: false
    },
    chart: {
      height: 200,
      marginTop: -48,
      spacing: [0, 0, 0, 0]
    }
  }), [aispChartData]);

  const options1 = useMemo(() => getOptions(aispChartData), [aispChartData, getOptions]);
  const options2 = useMemo(() => getOptions(pispChartData), [pispChartData, getOptions]);

  return isLoading === 'loading'
    ? <div className="charts-loader"><Loader /></div>
    : (
      <div className="uptime">
        <ChartBox
          rangeValue={range}
          onRangerangeValueChange={setRange}
          noData={aispChartData?.length == 0}
          options={options1}
          title={t('pages.adminAnalyticsDetail.aispAvgResponseTime')}
          noDataText={t('pages.adminAnalyticsDetail.noAispDataFound')}
          subContent={(
            <div className="rangeLine">
              <span>{range} {t('pages.adminAnalyticsDetail.daysAgo')}</span>
              <span>{t('pages.adminAnalyticsDetail.average')}: {aispAverage?.toFixed(1)}%</span>
              <span>{t('pages.adminAnalyticsDetail.today')}</span>
            </div>
          )}
        />

        <ChartBox
          rangeValue={range}
          onRangerangeValueChange={setRange}
          noData={pispChartData?.length == 0}
          options={options2}
          title={t('pages.adminAnalyticsDetail.pispAvgResponseTime')}
          noDataText={t('pages.adminAnalyticsDetail.noPispDataFound')}
          subContent={(
            <div className="rangeLine">
              <span>{range} {t('pages.adminAnalyticsDetail.daysAgo')}</span>
              <span>{t('pages.adminAnalyticsDetail.average')}: {pispAverage?.toFixed(1)}%</span>
              <span>{t('pages.adminAnalyticsDetail.today')}</span>
            </div>
          )}
        />

        <DownloadCard
          title={t('pages.adminAnalyticsDetail.uptime')}
          onDownload={(fromDate, toDate) => {
            onDownload(fromDate, toDate)
          }}
        />
      </div>
    );
};

export default Uptime;